@charset "UTF-8";
/*$headings-font-family: "Playfair Display", serif;
$font-family-base: Roboto, sans-serif;*/
/*
$sidebar-brand-bg: $white;
$sidebar-brand-padding: 2rem 2rem 1.2rem 2rem;

$sidebar-bg-image: none;
$sidebar-bg: $generic-bg-color;
$sidebar-box-shadow: 4px 0 6px 2px rgba(0, 0, 0, 0.125);
*/
/*@import "~bedrock/scss/main";*/
/*@import "~@progress/kendo-theme-bootstrap/dist/all.scss";*/
/**
 * Bootstrap Variables
 */
/**
 * Project variables
 */
:root {
  --app-aside-width: 300px;
  --app-aside-collapsed-width: 100px;
}

.border-dashed {
  border-style: dashed !important;
}

.container-400px {
  width: 400px;
  max-width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}

.container-450px {
  width: 450px;
  max-width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}

.container-500px {
  width: 500px;
  max-width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}

.container-600px {
  width: 600px;
  max-width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}

.container-700px {
  width: 700px;
  max-width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}

.square-0 {
  width: 0;
  height: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 0;
}

.square-1 {
  width: 0.25rem;
  height: 0.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 0.1111111111rem;
}

.square-2 {
  width: 0.5rem;
  height: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 0.2222222222rem;
}

.square-3 {
  width: 0.75rem;
  height: 0.75rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 0.3333333333rem;
}

.square-4 {
  width: 1rem;
  height: 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 0.4444444444rem;
}

.square-5 {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 0.5555555556rem;
}

.square-6 {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 0.6666666667rem;
}

.square-7 {
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 0.8888888889rem;
}

.square-8 {
  width: 2.5rem;
  height: 2.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 1.1111111111rem;
}

.square-9 {
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 1.3333333333rem;
}

.square-10 {
  width: 4rem;
  height: 4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 1.7777777778rem;
}

.square-11 {
  width: 5rem;
  height: 5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 2.2222222222rem;
}

.square-12 {
  width: 6rem;
  height: 6rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 2.6666666667rem;
}

.square-13 {
  width: 8rem;
  height: 8rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 3.5555555556rem;
}

.square-14 {
  width: 10rem;
  height: 10rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 4.4444444444rem;
}

.thumbnail-1 {
  position: relative;
  aspect-ratio: 1;
  width: 4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.375rem;
  border: 1px solid #EAECF0;
  background-color: #FFF;
  padding: 0.25rem;
  overflow: hidden;
}
.thumbnail-1 img,
.thumbnail-1 svg {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 0.25rem;
}
.thumbnail-1 > i {
  color: #D0D5DD;
  font-size: 1.3333333333rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #EAECF0;
  background-color: #F2F4F7;
}

.thumbnail-2 {
  position: relative;
  aspect-ratio: 1;
  width: 5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.375rem;
  border: 1px solid #EAECF0;
  background-color: #FFF;
  padding: 0.25rem;
  overflow: hidden;
}
.thumbnail-2 img,
.thumbnail-2 svg {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 0.25rem;
}
.thumbnail-2 > i {
  color: #D0D5DD;
  font-size: 1.6666666667rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #EAECF0;
  background-color: #F2F4F7;
}

.thumbnail-3 {
  position: relative;
  aspect-ratio: 1;
  width: 6rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.375rem;
  border: 1px solid #EAECF0;
  background-color: #FFF;
  padding: 0.25rem;
  overflow: hidden;
}
.thumbnail-3 img,
.thumbnail-3 svg {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 0.25rem;
}
.thumbnail-3 > i {
  color: #D0D5DD;
  font-size: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #EAECF0;
  background-color: #F2F4F7;
}

.thumbnail-4 {
  position: relative;
  aspect-ratio: 1;
  width: 8rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.375rem;
  border: 1px solid #EAECF0;
  background-color: #FFF;
  padding: 0.25rem;
  overflow: hidden;
}
.thumbnail-4 img,
.thumbnail-4 svg {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 0.25rem;
}
.thumbnail-4 > i {
  color: #D0D5DD;
  font-size: 2.6666666667rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #EAECF0;
  background-color: #F2F4F7;
}

.thumbnail-5 {
  position: relative;
  aspect-ratio: 1;
  width: 10rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.375rem;
  border: 1px solid #EAECF0;
  background-color: #FFF;
  padding: 0.25rem;
  overflow: hidden;
}
.thumbnail-5 img,
.thumbnail-5 svg {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 0.25rem;
}
.thumbnail-5 > i {
  color: #D0D5DD;
  font-size: 3.3333333333rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #EAECF0;
  background-color: #F2F4F7;
}

.thumbnail-6 {
  position: relative;
  aspect-ratio: 1;
  width: 12rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.375rem;
  border: 1px solid #EAECF0;
  background-color: #FFF;
  padding: 0.25rem;
  overflow: hidden;
}
.thumbnail-6 img,
.thumbnail-6 svg {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 0.25rem;
}
.thumbnail-6 > i {
  color: #D0D5DD;
  font-size: 4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #EAECF0;
  background-color: #F2F4F7;
}

.btn-reset {
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;
}

.zindex-dropdown {
  z-index: 1000;
}

.zindex-sticky {
  z-index: 1020;
}

.zindex-fixed {
  z-index: 1030;
}

.zindex-offcanvas-backdrop {
  z-index: 1040;
}

.zindex-offcanvas {
  z-index: 1045;
}

.zindex-modal-backdrop {
  z-index: 1050;
}

.zindex-modal {
  z-index: 1055;
}

.zindex-popover {
  z-index: 1070;
}

.zindex-tooltip {
  z-index: 1080;
}

.zindex-toast {
  z-index: 1090;
}

.custom-scroll::-webkit-scrollbar {
  border-radius: 4px;
  height: 8px;
  width: 8px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: rgba(16, 24, 40, 0.4);
  border-radius: 4px;
}
.custom-scroll::-webkit-scrollbar-track {
  border-radius: 4px;
  background: rgba(16, 24, 40, 0.05);
}

.collapse-toggle:before {
  display: block;
  content: "";
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="minus"><path fill="%23000000" d="M19,11H5a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z"></path></svg>');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.collapse-toggle.collapsed:before {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="plus"><path fill="%23000000" d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"></path></svg>');
}

/*!
 * Bootstrap  v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-pink: #EC4899;
  --bs-fuchsia: #D946EF;
  --bs-purple: #7F56D9;
  --bs-indigo: #6366F1;
  --bs-blue: #3B82F6;
  --bs-cyan: #06B6D4;
  --bs-teal: #14B8A6;
  --bs-green: #3CBF33;
  --bs-lime: #84CC16;
  --bs-yellow: #FACC15;
  --bs-orange: #F97316;
  --bs-red: #dc3545;
  --bs-black: #000000;
  --bs-white: #FFF;
  --bs-gray: #475467;
  --bs-gray-dark: #1D2939;
  --bs-slate: #3E5782;
  --bs-apple: #CBE007;
  --bs-amber: #FFB626;
  --bs-ruby: #D4304D;
  --bs-coral: #FF6B5B;
  --bs-brown: #B45309;
  --bs-coffee: #7C2D12;
  --bs-gray-25: #FCFCFD;
  --bs-gray-50: #F9FAFB;
  --bs-gray-100: #F2F4F7;
  --bs-gray-200: #EAECF0;
  --bs-gray-300: #D0D5DD;
  --bs-gray-400: #98A2B3;
  --bs-gray-500: #667085;
  --bs-gray-600: #475467;
  --bs-gray-700: #344054;
  --bs-gray-800: #1D2939;
  --bs-gray-900: #101828;
  --bs-primary: #3b99f0;
  --bs-secondary: #667085;
  --bs-success: #3CBF33;
  --bs-info: #3B82F6;
  --bs-warning: #F97316;
  --bs-danger: #dc3545;
  --bs-light: #F2F4F7;
  --bs-dark: #101828;
  --bs-primary-rgb: 59, 153, 240;
  --bs-secondary-rgb: 102, 112, 133;
  --bs-success-rgb: 60, 191, 51;
  --bs-info-rgb: 59, 130, 246;
  --bs-warning-rgb: 249, 115, 22;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 242, 244, 247;
  --bs-dark-rgb: 16, 24, 40;
  --bs-primary-text-emphasis: #2f7ac0;
  --bs-secondary-text-emphasis: #525a6a;
  --bs-success-text-emphasis: #309929;
  --bs-info-text-emphasis: #2f68c5;
  --bs-warning-text-emphasis: #c75c12;
  --bs-danger-text-emphasis: #b02a37;
  --bs-light-text-emphasis: #344054;
  --bs-dark-text-emphasis: #344054;
  --bs-primary-bg-subtle: #e2f0fd;
  --bs-secondary-bg-subtle: #e0e2e7;
  --bs-success-bg-subtle: #e2f5e0;
  --bs-info-bg-subtle: #e2ecfe;
  --bs-warning-bg-subtle: #feeadc;
  --bs-danger-bg-subtle: #fae1e3;
  --bs-light-bg-subtle: #f9fafb;
  --bs-dark-bg-subtle: #98A2B3;
  --bs-primary-border-subtle: #b1d6f9;
  --bs-secondary-border-subtle: #c2c6ce;
  --bs-success-border-subtle: #b1e5ad;
  --bs-info-border-subtle: #b1cdfb;
  --bs-warning-border-subtle: #fdc7a2;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #EAECF0;
  --bs-dark-border-subtle: #667085;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Inter, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.42;
  --bs-body-color: #344054;
  --bs-body-color-rgb: 52, 64, 84;
  --bs-body-bg: #F9FAFB;
  --bs-body-bg-rgb: 249, 250, 251;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(52, 64, 84, 0.75);
  --bs-secondary-color-rgb: 52, 64, 84;
  --bs-secondary-bg: #EAECF0;
  --bs-secondary-bg-rgb: 234, 236, 240;
  --bs-tertiary-color: rgba(52, 64, 84, 0.5);
  --bs-tertiary-color-rgb: 52, 64, 84;
  --bs-tertiary-bg: #F2F4F7;
  --bs-tertiary-bg-rgb: 242, 244, 247;
  --bs-heading-color: #101828;
  --bs-link-color: #3b99f0;
  --bs-link-color-rgb: 59, 153, 240;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #2f7ac0;
  --bs-link-hover-color-rgb: 47, 122, 192;
  --bs-code-color: #EC4899;
  --bs-highlight-color: #344054;
  --bs-highlight-bg: #fef5d0;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #EAECF0;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  --bs-box-shadow-sm: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --bs-box-shadow-lg: 0px 0px 2px rgba(104, 112, 131, 0.15), 0px 6px 16px rgba(104, 112, 131, 0.08), 0px 4px 8px -4px rgba(104, 112, 131, 0.03);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(59, 153, 240, 0.25);
  --bs-form-valid-color: #3CBF33;
  --bs-form-valid-border-color: #3CBF33;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 2rem 0;
  color: #EAECF0;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 1;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1em;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: 1.25rem;
}

h2, .h2 {
  font-size: 1.125rem;
}

h3, .h3 {
  font-size: 1rem;
}

h4, .h4 {
  font-size: 0.875rem;
}

h5, .h5 {
  font-size: 0.75rem;
}

h6, .h6 {
  font-size: 0.6875rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 600;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  font-weight: 500;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.125rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 4.5rem;
  }
}

.display-2 {
  font-size: calc(1.5rem + 3vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 3.75rem;
  }
}

.display-3 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 3rem;
  }
}

.display-4 {
  font-size: calc(1.3625rem + 1.35vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 2.375rem;
  }
}

.display-5 {
  font-size: calc(1.3125rem + 0.75vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 1.875rem;
  }
}

.display-6 {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 1.5rem;
  }
}

.display-7 {
  font-size: calc(1.2625rem + 0.15vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-7 {
    font-size: 1.375rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #475467;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-sm);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.grid {
  display: grid;
  grid-template-rows: repeat(var(--bs-rows, 1), 1fr);
  grid-template-columns: repeat(var(--bs-columns, 12), 1fr);
  gap: var(--bs-gap, 1.5rem);
}
.grid .g-col-1 {
  grid-column: auto/span 1;
}
.grid .g-col-2 {
  grid-column: auto/span 2;
}
.grid .g-col-3 {
  grid-column: auto/span 3;
}
.grid .g-col-4 {
  grid-column: auto/span 4;
}
.grid .g-col-5 {
  grid-column: auto/span 5;
}
.grid .g-col-6 {
  grid-column: auto/span 6;
}
.grid .g-col-7 {
  grid-column: auto/span 7;
}
.grid .g-col-8 {
  grid-column: auto/span 8;
}
.grid .g-col-9 {
  grid-column: auto/span 9;
}
.grid .g-col-10 {
  grid-column: auto/span 10;
}
.grid .g-col-11 {
  grid-column: auto/span 11;
}
.grid .g-col-12 {
  grid-column: auto/span 12;
}
.grid .g-start-1 {
  grid-column-start: 1;
}
.grid .g-start-2 {
  grid-column-start: 2;
}
.grid .g-start-3 {
  grid-column-start: 3;
}
.grid .g-start-4 {
  grid-column-start: 4;
}
.grid .g-start-5 {
  grid-column-start: 5;
}
.grid .g-start-6 {
  grid-column-start: 6;
}
.grid .g-start-7 {
  grid-column-start: 7;
}
.grid .g-start-8 {
  grid-column-start: 8;
}
.grid .g-start-9 {
  grid-column-start: 9;
}
.grid .g-start-10 {
  grid-column-start: 10;
}
.grid .g-start-11 {
  grid-column-start: 11;
}
@media (min-width: 576px) {
  .grid .g-col-sm-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-sm-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-sm-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-sm-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-sm-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-sm-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-sm-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-sm-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-sm-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-sm-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-sm-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-sm-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-sm-1 {
    grid-column-start: 1;
  }
  .grid .g-start-sm-2 {
    grid-column-start: 2;
  }
  .grid .g-start-sm-3 {
    grid-column-start: 3;
  }
  .grid .g-start-sm-4 {
    grid-column-start: 4;
  }
  .grid .g-start-sm-5 {
    grid-column-start: 5;
  }
  .grid .g-start-sm-6 {
    grid-column-start: 6;
  }
  .grid .g-start-sm-7 {
    grid-column-start: 7;
  }
  .grid .g-start-sm-8 {
    grid-column-start: 8;
  }
  .grid .g-start-sm-9 {
    grid-column-start: 9;
  }
  .grid .g-start-sm-10 {
    grid-column-start: 10;
  }
  .grid .g-start-sm-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 768px) {
  .grid .g-col-md-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-md-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-md-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-md-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-md-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-md-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-md-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-md-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-md-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-md-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-md-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-md-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-md-1 {
    grid-column-start: 1;
  }
  .grid .g-start-md-2 {
    grid-column-start: 2;
  }
  .grid .g-start-md-3 {
    grid-column-start: 3;
  }
  .grid .g-start-md-4 {
    grid-column-start: 4;
  }
  .grid .g-start-md-5 {
    grid-column-start: 5;
  }
  .grid .g-start-md-6 {
    grid-column-start: 6;
  }
  .grid .g-start-md-7 {
    grid-column-start: 7;
  }
  .grid .g-start-md-8 {
    grid-column-start: 8;
  }
  .grid .g-start-md-9 {
    grid-column-start: 9;
  }
  .grid .g-start-md-10 {
    grid-column-start: 10;
  }
  .grid .g-start-md-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 992px) {
  .grid .g-col-lg-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-lg-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-lg-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-lg-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-lg-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-lg-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-lg-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-lg-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-lg-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-lg-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-lg-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-lg-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-lg-1 {
    grid-column-start: 1;
  }
  .grid .g-start-lg-2 {
    grid-column-start: 2;
  }
  .grid .g-start-lg-3 {
    grid-column-start: 3;
  }
  .grid .g-start-lg-4 {
    grid-column-start: 4;
  }
  .grid .g-start-lg-5 {
    grid-column-start: 5;
  }
  .grid .g-start-lg-6 {
    grid-column-start: 6;
  }
  .grid .g-start-lg-7 {
    grid-column-start: 7;
  }
  .grid .g-start-lg-8 {
    grid-column-start: 8;
  }
  .grid .g-start-lg-9 {
    grid-column-start: 9;
  }
  .grid .g-start-lg-10 {
    grid-column-start: 10;
  }
  .grid .g-start-lg-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 1200px) {
  .grid .g-col-xl-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-xl-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-xl-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-xl-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-xl-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-xl-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-xl-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-xl-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-xl-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-xl-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-xl-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-xl-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-xl-1 {
    grid-column-start: 1;
  }
  .grid .g-start-xl-2 {
    grid-column-start: 2;
  }
  .grid .g-start-xl-3 {
    grid-column-start: 3;
  }
  .grid .g-start-xl-4 {
    grid-column-start: 4;
  }
  .grid .g-start-xl-5 {
    grid-column-start: 5;
  }
  .grid .g-start-xl-6 {
    grid-column-start: 6;
  }
  .grid .g-start-xl-7 {
    grid-column-start: 7;
  }
  .grid .g-start-xl-8 {
    grid-column-start: 8;
  }
  .grid .g-start-xl-9 {
    grid-column-start: 9;
  }
  .grid .g-start-xl-10 {
    grid-column-start: 10;
  }
  .grid .g-start-xl-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 1400px) {
  .grid .g-col-xxl-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-xxl-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-xxl-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-xxl-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-xxl-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-xxl-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-xxl-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-xxl-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-xxl-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-xxl-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-xxl-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-xxl-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-xxl-1 {
    grid-column-start: 1;
  }
  .grid .g-start-xxl-2 {
    grid-column-start: 2;
  }
  .grid .g-start-xxl-3 {
    grid-column-start: 3;
  }
  .grid .g-start-xxl-4 {
    grid-column-start: 4;
  }
  .grid .g-start-xxl-5 {
    grid-column-start: 5;
  }
  .grid .g-start-xxl-6 {
    grid-column-start: 6;
  }
  .grid .g-start-xxl-7 {
    grid-column-start: 7;
  }
  .grid .g-start-xxl-8 {
    grid-column-start: 8;
  }
  .grid .g-start-xxl-9 {
    grid-column-start: 9;
  }
  .grid .g-start-xxl-10 {
    grid-column-start: 10;
  }
  .grid .g-start-xxl-11 {
    grid-column-start: 11;
  }
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.75rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.75rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.25rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.25rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 2rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 2rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 3rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 3rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 4rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 4rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 5rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 5rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 6rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 6rem;
}

.g-13,
.gx-13 {
  --bs-gutter-x: 8rem;
}

.g-13,
.gy-13 {
  --bs-gutter-y: 8rem;
}

.g-14,
.gx-14 {
  --bs-gutter-x: 10rem;
}

.g-14,
.gy-14 {
  --bs-gutter-y: 10rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 2.5rem;
  }

  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 4rem;
  }

  .g-sm-11,
.gx-sm-11 {
    --bs-gutter-x: 5rem;
  }

  .g-sm-11,
.gy-sm-11 {
    --bs-gutter-y: 5rem;
  }

  .g-sm-12,
.gx-sm-12 {
    --bs-gutter-x: 6rem;
  }

  .g-sm-12,
.gy-sm-12 {
    --bs-gutter-y: 6rem;
  }

  .g-sm-13,
.gx-sm-13 {
    --bs-gutter-x: 8rem;
  }

  .g-sm-13,
.gy-sm-13 {
    --bs-gutter-y: 8rem;
  }

  .g-sm-14,
.gx-sm-14 {
    --bs-gutter-x: 10rem;
  }

  .g-sm-14,
.gy-sm-14 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 2rem;
  }

  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 2rem;
  }

  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 2.5rem;
  }

  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 3rem;
  }

  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 3rem;
  }

  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 4rem;
  }

  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 4rem;
  }

  .g-md-11,
.gx-md-11 {
    --bs-gutter-x: 5rem;
  }

  .g-md-11,
.gy-md-11 {
    --bs-gutter-y: 5rem;
  }

  .g-md-12,
.gx-md-12 {
    --bs-gutter-x: 6rem;
  }

  .g-md-12,
.gy-md-12 {
    --bs-gutter-y: 6rem;
  }

  .g-md-13,
.gx-md-13 {
    --bs-gutter-x: 8rem;
  }

  .g-md-13,
.gy-md-13 {
    --bs-gutter-y: 8rem;
  }

  .g-md-14,
.gx-md-14 {
    --bs-gutter-x: 10rem;
  }

  .g-md-14,
.gy-md-14 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 2.5rem;
  }

  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 4rem;
  }

  .g-lg-11,
.gx-lg-11 {
    --bs-gutter-x: 5rem;
  }

  .g-lg-11,
.gy-lg-11 {
    --bs-gutter-y: 5rem;
  }

  .g-lg-12,
.gx-lg-12 {
    --bs-gutter-x: 6rem;
  }

  .g-lg-12,
.gy-lg-12 {
    --bs-gutter-y: 6rem;
  }

  .g-lg-13,
.gx-lg-13 {
    --bs-gutter-x: 8rem;
  }

  .g-lg-13,
.gy-lg-13 {
    --bs-gutter-y: 8rem;
  }

  .g-lg-14,
.gx-lg-14 {
    --bs-gutter-x: 10rem;
  }

  .g-lg-14,
.gy-lg-14 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 4rem;
  }

  .g-xl-11,
.gx-xl-11 {
    --bs-gutter-x: 5rem;
  }

  .g-xl-11,
.gy-xl-11 {
    --bs-gutter-y: 5rem;
  }

  .g-xl-12,
.gx-xl-12 {
    --bs-gutter-x: 6rem;
  }

  .g-xl-12,
.gy-xl-12 {
    --bs-gutter-y: 6rem;
  }

  .g-xl-13,
.gx-xl-13 {
    --bs-gutter-x: 8rem;
  }

  .g-xl-13,
.gy-xl-13 {
    --bs-gutter-y: 8rem;
  }

  .g-xl-14,
.gx-xl-14 {
    --bs-gutter-x: 10rem;
  }

  .g-xl-14,
.gy-xl-14 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 4rem;
  }

  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 4rem;
  }

  .g-xxl-11,
.gx-xxl-11 {
    --bs-gutter-x: 5rem;
  }

  .g-xxl-11,
.gy-xxl-11 {
    --bs-gutter-y: 5rem;
  }

  .g-xxl-12,
.gx-xxl-12 {
    --bs-gutter-x: 6rem;
  }

  .g-xxl-12,
.gy-xxl-12 {
    --bs-gutter-y: 6rem;
  }

  .g-xxl-13,
.gx-xxl-13 {
    --bs-gutter-x: 8rem;
  }

  .g-xxl-13,
.gy-xxl-13 {
    --bs-gutter-y: 8rem;
  }

  .g-xxl-14,
.gx-xxl-14 {
    --bs-gutter-x: 10rem;
  }

  .g-xxl-14,
.gy-xxl-14 {
    --bs-gutter-y: 10rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: #F9FAFB;
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(16, 24, 40, 0.025);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.75rem 1rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000000;
  --bs-table-bg: #d8ebfc;
  --bs-table-border-color: #adbcca;
  --bs-table-striped-bg: #cddfef;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #c2d4e3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #d3e5f6;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000000;
  --bs-table-bg: #e0e2e7;
  --bs-table-border-color: #b3b5b9;
  --bs-table-striped-bg: #d5d7db;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #cacbd0;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #dadce1;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000000;
  --bs-table-bg: #d8f2d6;
  --bs-table-border-color: #adc2ab;
  --bs-table-striped-bg: #cde6cb;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #c2dac1;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #d3ecd1;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000000;
  --bs-table-bg: #d8e6fd;
  --bs-table-border-color: #adb8ca;
  --bs-table-striped-bg: #cddbf0;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #c2cfe4;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #d3e0f7;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000000;
  --bs-table-bg: #fee3d0;
  --bs-table-border-color: #cbb6a6;
  --bs-table-striped-bg: #f1d8c6;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e5ccbb;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #f8ddcb;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #f2d2d5;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000000;
  --bs-table-bg: #F2F4F7;
  --bs-table-border-color: #c2c3c6;
  --bs-table-striped-bg: #e6e8eb;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #dadcde;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #eceef1;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #FFF;
  --bs-table-bg: #101828;
  --bs-table-border-color: #404653;
  --bs-table-striped-bg: #1c2433;
  --bs-table-striped-color: #FFF;
  --bs-table-active-bg: #282f3e;
  --bs-table-active-color: #FFF;
  --bs-table-hover-bg: #161e2d;
  --bs-table-hover-color: #FFF;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.375rem;
  font-weight: 500;
}

.col-form-label {
  padding-top: calc(0.5625rem + 1px);
  padding-bottom: calc(0.5625rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.42;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
}

.col-form-label-sm {
  padding-top: calc(0.35rem + 1px);
  padding-bottom: calc(0.35rem + 1px);
  font-size: 0.765625rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5625rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42;
  color: var(--bs-body-color);
  appearance: none;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid #D0D5DD;
  border-radius: var(--bs-border-radius);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: #FFF;
  border-color: #9dccf8;
  outline: 0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 0.25rem rgba(59, 153, 240, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.42em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.5625rem 0.875rem;
  margin: -0.5625rem -0.875rem;
  margin-inline-end: 0.875rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5625rem 0;
  margin-bottom: 0;
  line-height: 1.42;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.42em + 0.7rem + calc(1px * 2));
  padding: 0.35rem 0.6rem;
  font-size: 0.765625rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.35rem 0.6rem;
  margin: -0.35rem -0.6rem;
  margin-inline-end: 0.6rem;
}

.form-control-lg {
  min-height: calc(1.42em + 1rem + calc(1px * 2));
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.42em + 1.125rem + calc(1px * 2));
}
textarea.form-control-sm {
  min-height: calc(1.42em + 0.7rem + calc(1px * 2));
}
textarea.form-control-lg {
  min-height: calc(1.42em + 1rem + calc(1px * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.42em + 1.125rem + calc(1px * 2));
  padding: 0.5625rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.42em + 0.7rem + calc(1px * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.42em + 1rem + calc(1px * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%231D2939' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.5625rem 2.625rem 0.5625rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42;
  color: var(--bs-body-color);
  appearance: none;
  background-color: #FFF;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.875rem center;
  background-size: 16px 12px;
  border: 1px solid #D0D5DD;
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #9dccf8;
  outline: 0;
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.2rem rgba(59, 153, 240, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.875rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  padding-left: 0.6rem;
  font-size: 0.765625rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}

.form-check {
  display: block;
  min-height: 1.2425rem;
  padding-left: 1.55em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.55em;
}

.form-check-reverse {
  padding-right: 1.55em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.55em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: #FFF;
  flex-shrink: 0;
  width: 1.05em;
  height: 1.05em;
  margin-top: 0.185em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #D0D5DD;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #9dccf8;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(59, 153, 240, 0.25);
}
.form-check-input:checked {
  background-color: #3b99f0;
  border-color: #3b99f0;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFF'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #3b99f0;
  border-color: #3b99f0;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%239dccf8'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #F9FAFB, 0 0 0 0.25rem rgba(59, 153, 240, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #F9FAFB, 0 0 0 0.25rem rgba(59, 153, 240, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #3b99f0;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #c4e0fb;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--bs-box-shadow-inset);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #3b99f0;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #c4e0fb;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--bs-box-shadow-inset);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(1px * 2));
  min-height: calc(3.5rem + calc(1px * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.875rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.875rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.4375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #FFF;
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #475467;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5625rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.42;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: #FFF;
  border: 1px solid #D0D5DD;
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.35rem 0.6rem;
  font-size: 0.765625rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3.5rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback):not(.field-validation-error):not(.warning-tooltip):not(.warning-feedback) {
  margin-left: calc(1px * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.42em + 1.125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233CBF33' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.355em + 0.28125rem) center;
  background-size: calc(0.71em + 0.5625rem) calc(0.71em + 0.5625rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 0.2rem rgba(var(--bs-success-rgb), 0.2);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.42em + 1.125rem);
  background-position: top calc(0.355em + 0.28125rem) right calc(0.355em + 0.28125rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233CBF33' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.8125rem;
  background-position: right 0.875rem center, center right 2.625rem;
  background-size: 16px 12px, calc(0.71em + 0.5625rem) calc(0.71em + 0.5625rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.2rem rgba(var(--bs-success-rgb), 0.2);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.42em + 1.125rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--bs-success-rgb), 0.2);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback, .field-validation-error {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .field-validation-error,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .field-validation-error,
.input-validation-error ~ .invalid-feedback,
.input-validation-error ~ .field-validation-error,
.is-invalid ~ .invalid-tooltip,
.input-validation-error ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .form-control.input-validation-error {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.42em + 1.125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.355em + 0.28125rem) center;
  background-size: calc(0.71em + 0.5625rem) calc(0.71em + 0.5625rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .form-control.input-validation-error:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 0.2rem rgba(var(--bs-danger-rgb), 0.2);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid, textarea.form-control.input-validation-error {
  padding-right: calc(1.42em + 1.125rem);
  background-position: top calc(0.355em + 0.28125rem) right calc(0.355em + 0.28125rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid, .form-select.input-validation-error {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.input-validation-error:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], .form-select.input-validation-error:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.8125rem;
  background-position: right 0.875rem center, center right 2.625rem;
  background-size: 16px 12px, calc(0.71em + 0.5625rem) calc(0.71em + 0.5625rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus, .form-select.input-validation-error:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.2rem rgba(var(--bs-danger-rgb), 0.2);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid, .form-control-color.input-validation-error {
  width: calc(3rem + calc(1.42em + 1.125rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid, .form-check-input.input-validation-error {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked, .form-check-input.input-validation-error:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus, .form-check-input.input-validation-error:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--bs-danger-rgb), 0.2);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, .form-check-input.input-validation-error ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback, .form-check-inline .form-check-input ~ .field-validation-error {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .input-group > .form-control.input-validation-error:not(:focus),
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.input-group > .form-select.input-validation-error:not(:focus),
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid,
.input-group > .form-floating.input-validation-error:not(:focus-within) {
  z-index: 4;
}

.warning-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #F97316;
}

.warning-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  color: #fff;
  background-color: var(--bs-warning);
  border-radius: var(--bs-border-radius);
}

.is-warning ~ .warning-feedback,
.is-warning ~ .warning-tooltip {
  display: block;
}

.form-control.is-warning {
  border-color: #F97316;
  padding-right: calc(1.42em + 1.125rem);
  background-image: "";
  background-repeat: no-repeat;
  background-position: right calc(0.355em + 0.28125rem) center;
  background-size: calc(0.71em + 0.5625rem) calc(0.71em + 0.5625rem);
}
.form-control.is-warning:focus {
  border-color: #F97316;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 0.2rem rgba(var(--bs-warning-rgb), 0.2);
}

textarea.form-control.is-warning {
  padding-right: calc(1.42em + 1.125rem);
  background-position: top calc(0.355em + 0.28125rem) right calc(0.355em + 0.28125rem);
}

.form-select.is-warning {
  border-color: #F97316;
}
.form-select.is-warning:not([multiple]):not([size]), .form-select.is-warning:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: ;
  padding-right: 4.8125rem;
  background-position: right 0.875rem center, center right 2.625rem;
  background-size: 16px 12px, calc(0.71em + 0.5625rem) calc(0.71em + 0.5625rem);
}
.form-select.is-warning:focus {
  border-color: #F97316;
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.2rem rgba(var(--bs-warning-rgb), 0.2);
}

.form-control-color.is-warning {
  width: calc(3rem + calc(1.42em + 1.125rem));
}

.form-check-input.is-warning {
  border-color: #F97316;
}
.form-check-input.is-warning:checked {
  background-color: #F97316;
}
.form-check-input.is-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--bs-warning-rgb), 0.2);
}
.form-check-input.is-warning ~ .form-check-label {
  color: #F97316;
}

.form-check-inline .form-check-input ~ .warning-feedback {
  margin-left: 0.5em;
}

.btn {
  --bs-btn-padding-x: 0.9375rem;
  --bs-btn-padding-y: 0.5625rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 600;
  --bs-btn-line-height: 1.42;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.2rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
  box-shadow: var(--bs-btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --bs-btn-color: #FFF;
  --bs-btn-bg: #3b99f0;
  --bs-btn-border-color: #3b99f0;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #3282cc;
  --bs-btn-hover-border-color: #2f7ac0;
  --bs-btn-focus-shadow-rgb: 88, 168, 242;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #2f7ac0;
  --bs-btn-active-border-color: #2c73b4;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #FFF;
  --bs-btn-disabled-bg: #3b99f0;
  --bs-btn-disabled-border-color: #3b99f0;
}

.btn-secondary {
  --bs-btn-color: #FFF;
  --bs-btn-bg: #667085;
  --bs-btn-border-color: #667085;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #575f71;
  --bs-btn-hover-border-color: #525a6a;
  --bs-btn-focus-shadow-rgb: 125, 133, 151;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #525a6a;
  --bs-btn-active-border-color: #4d5464;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #FFF;
  --bs-btn-disabled-bg: #667085;
  --bs-btn-disabled-border-color: #667085;
}

.btn-success {
  --bs-btn-color: #FFF;
  --bs-btn-bg: #3CBF33;
  --bs-btn-border-color: #3CBF33;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #33a22b;
  --bs-btn-hover-border-color: #309929;
  --bs-btn-focus-shadow-rgb: 89, 201, 82;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #309929;
  --bs-btn-active-border-color: #2d8f26;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #FFF;
  --bs-btn-disabled-bg: #3CBF33;
  --bs-btn-disabled-border-color: #3CBF33;
}

.btn-info {
  --bs-btn-color: #FFF;
  --bs-btn-bg: #3B82F6;
  --bs-btn-border-color: #3B82F6;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #326fd1;
  --bs-btn-hover-border-color: #2f68c5;
  --bs-btn-focus-shadow-rgb: 88, 149, 247;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #2f68c5;
  --bs-btn-active-border-color: #2c62b9;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #FFF;
  --bs-btn-disabled-bg: #3B82F6;
  --bs-btn-disabled-border-color: #3B82F6;
}

.btn-warning {
  --bs-btn-color: #FFF;
  --bs-btn-bg: #F97316;
  --bs-btn-border-color: #F97316;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #d46213;
  --bs-btn-hover-border-color: #c75c12;
  --bs-btn-focus-shadow-rgb: 250, 136, 57;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #c75c12;
  --bs-btn-active-border-color: #bb5611;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #FFF;
  --bs-btn-disabled-bg: #F97316;
  --bs-btn-disabled-border-color: #F97316;
}

.btn-danger {
  --bs-btn-color: #FFF;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #FFF;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #F2F4F7;
  --bs-btn-border-color: #F2F4F7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #cecfd2;
  --bs-btn-hover-border-color: #c2c3c6;
  --bs-btn-focus-shadow-rgb: 206, 207, 210;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #c2c3c6;
  --bs-btn-active-border-color: #b6b7b9;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #F2F4F7;
  --bs-btn-disabled-border-color: #F2F4F7;
}

.btn-dark {
  --bs-btn-color: #FFF;
  --bs-btn-bg: #101828;
  --bs-btn-border-color: #101828;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #343b48;
  --bs-btn-hover-border-color: #282f3e;
  --bs-btn-focus-shadow-rgb: 52, 59, 72;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #404653;
  --bs-btn-active-border-color: #282f3e;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #FFF;
  --bs-btn-disabled-bg: #101828;
  --bs-btn-disabled-border-color: #101828;
}

.btn-outline-primary {
  --bs-btn-color: #3b99f0;
  --bs-btn-border-color: #3b99f0;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #3b99f0;
  --bs-btn-hover-border-color: #3b99f0;
  --bs-btn-focus-shadow-rgb: 59, 153, 240;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #3b99f0;
  --bs-btn-active-border-color: #3b99f0;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #3b99f0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3b99f0;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #667085;
  --bs-btn-border-color: #667085;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #667085;
  --bs-btn-hover-border-color: #667085;
  --bs-btn-focus-shadow-rgb: 102, 112, 133;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #667085;
  --bs-btn-active-border-color: #667085;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #667085;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #667085;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #3CBF33;
  --bs-btn-border-color: #3CBF33;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #3CBF33;
  --bs-btn-hover-border-color: #3CBF33;
  --bs-btn-focus-shadow-rgb: 60, 191, 51;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #3CBF33;
  --bs-btn-active-border-color: #3CBF33;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #3CBF33;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3CBF33;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #3B82F6;
  --bs-btn-border-color: #3B82F6;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #3B82F6;
  --bs-btn-hover-border-color: #3B82F6;
  --bs-btn-focus-shadow-rgb: 59, 130, 246;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #3B82F6;
  --bs-btn-active-border-color: #3B82F6;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #3B82F6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3B82F6;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #F97316;
  --bs-btn-border-color: #F97316;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #F97316;
  --bs-btn-hover-border-color: #F97316;
  --bs-btn-focus-shadow-rgb: 249, 115, 22;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #F97316;
  --bs-btn-active-border-color: #F97316;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #F97316;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F97316;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #F2F4F7;
  --bs-btn-border-color: #F2F4F7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #F2F4F7;
  --bs-btn-hover-border-color: #F2F4F7;
  --bs-btn-focus-shadow-rgb: 242, 244, 247;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #F2F4F7;
  --bs-btn-active-border-color: #F2F4F7;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #F2F4F7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F2F4F7;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #101828;
  --bs-btn-border-color: #101828;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #101828;
  --bs-btn-hover-border-color: #101828;
  --bs-btn-focus-shadow-rgb: 16, 24, 40;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #101828;
  --bs-btn-active-border-color: #101828;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #101828;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #101828;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #475467;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 88, 168, 242;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.09375rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.35rem;
  --bs-btn-padding-x: 0.6rem;
  --bs-btn-font-size: 0.765625rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 15rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: #FFF;
  --bs-dropdown-border-color: #EAECF0;
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: #EAECF0;
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0px 0px 2px rgba(104, 112, 131, 0.15), 0px 6px 16px rgba(104, 112, 131, 0.08), 0px 4px 8px -4px rgba(104, 112, 131, 0.03);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #FFF;
  --bs-dropdown-link-active-bg: #3b99f0;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.5rem;
  --bs-dropdown-header-color: #475467;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  box-shadow: var(--bs-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #D0D5DD;
  --bs-dropdown-bg: #1D2939;
  --bs-dropdown-border-color: #EAECF0;
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #D0D5DD;
  --bs-dropdown-link-hover-color: #FFF;
  --bs-dropdown-divider-bg: #EAECF0;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #FFF;
  --bs-dropdown-link-active-bg: #3b99f0;
  --bs-dropdown-link-disabled-color: #667085;
  --bs-dropdown-header-color: #667085;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(1px * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.703125rem;
  padding-left: 0.703125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.45rem;
  padding-left: 0.45rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(1px * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-size: 0.875rem;
  --bs-nav-link-font-weight: 600;
  --bs-nav-link-color: #667085;
  --bs-nav-link-hover-color: #3b99f0;
  --bs-nav-link-disabled-color: #98A2B3;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(59, 153, 240, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #EAECF0;
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: transparent transparent #3b99f0;
  --bs-nav-tabs-link-active-color: #3b99f0;
  --bs-nav-tabs-link-active-bg: transparent;
  --bs-nav-tabs-link-active-border-color: transparent transparent #3b99f0;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #3b99f0;
  --bs-nav-pills-link-active-bg: #ebf5fe;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 600;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3446875rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.09375rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.09375rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2852, 64, 84, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.2rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-size: 0.875rem;
  --bs-nav-link-font-weight: 600;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #FFF;
  --bs-navbar-brand-color: #FFF;
  --bs-navbar-brand-hover-color: #FFF;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1.5rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: 0;
  --bs-card-border-color: #EAECF0;
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - 0);
  --bs-card-cap-padding-y: 1rem;
  --bs-card-cap-padding-x: 1.5rem;
  --bs-card-cap-bg: transparent;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #FFF;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  box-shadow: var(--bs-card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: #FFF;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23344054' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%232f7ac0' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(59, 153, 240, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: inherit;
  --bs-accordion-active-bg: inherit;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.875rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 0;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #667085;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #667085;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-color: #344054;
  --bs-pagination-bg: #FFF;
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: #D0D5DD;
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #F2F4F7;
  --bs-pagination-hover-border-color: #D0D5DD;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(59, 153, 240, 0.25);
  --bs-pagination-active-color: #FFF;
  --bs-pagination-active-bg: #3b99f0;
  --bs-pagination-active-border-color: #3b99f0;
  --bs-pagination-disabled-color: #667085;
  --bs-pagination-disabled-bg: #F2F4F7;
  --bs-pagination-disabled-border-color: #D0D5DD;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.09375rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.765625rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.8em;
  --bs-badge-padding-y: 0.4em;
  --bs-badge-font-size: 0.8em;
  --bs-badge-font-weight: 500;
  --bs-badge-color: #FFF;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1.5rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.5rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 0.5rem;
  --bs-progress-font-size: 0.65625rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: 0 0 0;
  --bs-progress-bar-color: #FFF;
  --bs-progress-bar-bg: #3b99f0;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  box-shadow: var(--bs-progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: transparent;
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.75rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: transparent;
  --bs-list-group-active-color: #FFF;
  --bs-list-group-active-bg: #3b99f0;
  --bs-list-group-active-border-color: #3b99f0;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #101828;
  --bs-btn-close-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23101828'%3E%3Cpath d='m9.878 7.999 5.728-5.716A1.338 1.338 0 1 0 13.714.391L8 6.12 2.286.392A1.338 1.338 0 0 0 .394 2.283l5.728 5.715-5.728 5.716a1.338 1.338 0 1 0 1.892 1.89L8 9.875l5.715 5.729a1.338 1.338 0 1 0 1.891-1.89Z'/%3E%3C/svg%3E");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(59, 153, 240, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 0.875em;
  height: 0.875em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/0.875em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 3rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1.5rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #FFF;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 0;
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - 0);
  --bs-modal-header-padding-x: 1.5rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1.5rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.42;
  --bs-modal-footer-gap: 0.75rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  box-shadow: var(--bs-modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #344054;
  --bs-backdrop-opacity: 0.7;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 400px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.765625rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: Inter, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.765625rem;
  --bs-popover-bg: #FFF;
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.875rem;
  --bs-popover-header-color: #101828;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: Inter, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  box-shadow: var(--bs-popover-box-shadow);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #FFF;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #FFF;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFF;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #FFF;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
.carousel-dark .carousel-caption {
  color: #000000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1.5rem;
  --bs-offcanvas-padding-y: 1.5rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: #FFF;
  --bs-offcanvas-border-width: 0;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.42;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--bs-offcanvas-box-shadow);
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #344054;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.7;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y)) calc(-0.5 * var(--bs-offcanvas-padding-x)) calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000000 55%, rgba(0, 0, 0, 0.8) 75%, #000000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #FFF !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #FFF !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #FFF !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #FFF !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #FFF !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #FFF !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #FFF !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(47, 122, 192, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(47, 122, 192, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(82, 90, 106, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(82, 90, 106, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(48, 153, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(48, 153, 41, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(47, 104, 197, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(47, 104, 197, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(199, 92, 18, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(199, 92, 18, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(245, 246, 249, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(245, 246, 249, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(13, 19, 32, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(13, 19, 32, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-7 {
  margin: 2rem !important;
}

.m-8 {
  margin: 2.5rem !important;
}

.m-9 {
  margin: 3rem !important;
}

.m-10 {
  margin: 4rem !important;
}

.m-11 {
  margin: 5rem !important;
}

.m-12 {
  margin: 6rem !important;
}

.m-13 {
  margin: 8rem !important;
}

.m-14 {
  margin: 10rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-5 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-6 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-7 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-8 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-9 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-10 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-11 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-12 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-13 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-14 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-7 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-8 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-9 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-10 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-11 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-12 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-13 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-14 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-7 {
  margin-top: 2rem !important;
}

.mt-8 {
  margin-top: 2.5rem !important;
}

.mt-9 {
  margin-top: 3rem !important;
}

.mt-10 {
  margin-top: 4rem !important;
}

.mt-11 {
  margin-top: 5rem !important;
}

.mt-12 {
  margin-top: 6rem !important;
}

.mt-13 {
  margin-top: 8rem !important;
}

.mt-14 {
  margin-top: 10rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.25rem !important;
}

.me-6 {
  margin-right: 1.5rem !important;
}

.me-7 {
  margin-right: 2rem !important;
}

.me-8 {
  margin-right: 2.5rem !important;
}

.me-9 {
  margin-right: 3rem !important;
}

.me-10 {
  margin-right: 4rem !important;
}

.me-11 {
  margin-right: 5rem !important;
}

.me-12 {
  margin-right: 6rem !important;
}

.me-13 {
  margin-right: 8rem !important;
}

.me-14 {
  margin-right: 10rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 2rem !important;
}

.mb-8 {
  margin-bottom: 2.5rem !important;
}

.mb-9 {
  margin-bottom: 3rem !important;
}

.mb-10 {
  margin-bottom: 4rem !important;
}

.mb-11 {
  margin-bottom: 5rem !important;
}

.mb-12 {
  margin-bottom: 6rem !important;
}

.mb-13 {
  margin-bottom: 8rem !important;
}

.mb-14 {
  margin-bottom: 10rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 0.75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-5 {
  margin-left: 1.25rem !important;
}

.ms-6 {
  margin-left: 1.5rem !important;
}

.ms-7 {
  margin-left: 2rem !important;
}

.ms-8 {
  margin-left: 2.5rem !important;
}

.ms-9 {
  margin-left: 3rem !important;
}

.ms-10 {
  margin-left: 4rem !important;
}

.ms-11 {
  margin-left: 5rem !important;
}

.ms-12 {
  margin-left: 6rem !important;
}

.ms-13 {
  margin-left: 8rem !important;
}

.ms-14 {
  margin-left: 10rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.m-n5 {
  margin: -1.25rem !important;
}

.m-n6 {
  margin: -1.5rem !important;
}

.m-n7 {
  margin: -2rem !important;
}

.m-n8 {
  margin: -2.5rem !important;
}

.m-n9 {
  margin: -3rem !important;
}

.m-n10 {
  margin: -4rem !important;
}

.m-n11 {
  margin: -5rem !important;
}

.m-n12 {
  margin: -6rem !important;
}

.m-n13 {
  margin: -8rem !important;
}

.m-n14 {
  margin: -10rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n4 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n5 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n6 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n7 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n8 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n9 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n10 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n11 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n12 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n13 {
  margin-right: -8rem !important;
  margin-left: -8rem !important;
}

.mx-n14 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n7 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n8 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n9 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n10 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n11 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n12 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n13 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.my-n14 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -0.75rem !important;
}

.mt-n4 {
  margin-top: -1rem !important;
}

.mt-n5 {
  margin-top: -1.25rem !important;
}

.mt-n6 {
  margin-top: -1.5rem !important;
}

.mt-n7 {
  margin-top: -2rem !important;
}

.mt-n8 {
  margin-top: -2.5rem !important;
}

.mt-n9 {
  margin-top: -3rem !important;
}

.mt-n10 {
  margin-top: -4rem !important;
}

.mt-n11 {
  margin-top: -5rem !important;
}

.mt-n12 {
  margin-top: -6rem !important;
}

.mt-n13 {
  margin-top: -8rem !important;
}

.mt-n14 {
  margin-top: -10rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -0.75rem !important;
}

.me-n4 {
  margin-right: -1rem !important;
}

.me-n5 {
  margin-right: -1.25rem !important;
}

.me-n6 {
  margin-right: -1.5rem !important;
}

.me-n7 {
  margin-right: -2rem !important;
}

.me-n8 {
  margin-right: -2.5rem !important;
}

.me-n9 {
  margin-right: -3rem !important;
}

.me-n10 {
  margin-right: -4rem !important;
}

.me-n11 {
  margin-right: -5rem !important;
}

.me-n12 {
  margin-right: -6rem !important;
}

.me-n13 {
  margin-right: -8rem !important;
}

.me-n14 {
  margin-right: -10rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -0.75rem !important;
}

.mb-n4 {
  margin-bottom: -1rem !important;
}

.mb-n5 {
  margin-bottom: -1.25rem !important;
}

.mb-n6 {
  margin-bottom: -1.5rem !important;
}

.mb-n7 {
  margin-bottom: -2rem !important;
}

.mb-n8 {
  margin-bottom: -2.5rem !important;
}

.mb-n9 {
  margin-bottom: -3rem !important;
}

.mb-n10 {
  margin-bottom: -4rem !important;
}

.mb-n11 {
  margin-bottom: -5rem !important;
}

.mb-n12 {
  margin-bottom: -6rem !important;
}

.mb-n13 {
  margin-bottom: -8rem !important;
}

.mb-n14 {
  margin-bottom: -10rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -0.75rem !important;
}

.ms-n4 {
  margin-left: -1rem !important;
}

.ms-n5 {
  margin-left: -1.25rem !important;
}

.ms-n6 {
  margin-left: -1.5rem !important;
}

.ms-n7 {
  margin-left: -2rem !important;
}

.ms-n8 {
  margin-left: -2.5rem !important;
}

.ms-n9 {
  margin-left: -3rem !important;
}

.ms-n10 {
  margin-left: -4rem !important;
}

.ms-n11 {
  margin-left: -5rem !important;
}

.ms-n12 {
  margin-left: -6rem !important;
}

.ms-n13 {
  margin-left: -8rem !important;
}

.ms-n14 {
  margin-left: -10rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 2rem !important;
}

.p-8 {
  padding: 2.5rem !important;
}

.p-9 {
  padding: 3rem !important;
}

.p-10 {
  padding: 4rem !important;
}

.p-11 {
  padding: 5rem !important;
}

.p-12 {
  padding: 6rem !important;
}

.p-13 {
  padding: 8rem !important;
}

.p-14 {
  padding: 10rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-5 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-7 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-8 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-9 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-10 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-11 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-12 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-13 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-14 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-8 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-9 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-10 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-11 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-12 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-13 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-14 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-7 {
  padding-top: 2rem !important;
}

.pt-8 {
  padding-top: 2.5rem !important;
}

.pt-9 {
  padding-top: 3rem !important;
}

.pt-10 {
  padding-top: 4rem !important;
}

.pt-11 {
  padding-top: 5rem !important;
}

.pt-12 {
  padding-top: 6rem !important;
}

.pt-13 {
  padding-top: 8rem !important;
}

.pt-14 {
  padding-top: 10rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 0.75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.25rem !important;
}

.pe-6 {
  padding-right: 1.5rem !important;
}

.pe-7 {
  padding-right: 2rem !important;
}

.pe-8 {
  padding-right: 2.5rem !important;
}

.pe-9 {
  padding-right: 3rem !important;
}

.pe-10 {
  padding-right: 4rem !important;
}

.pe-11 {
  padding-right: 5rem !important;
}

.pe-12 {
  padding-right: 6rem !important;
}

.pe-13 {
  padding-right: 8rem !important;
}

.pe-14 {
  padding-right: 10rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 2rem !important;
}

.pb-8 {
  padding-bottom: 2.5rem !important;
}

.pb-9 {
  padding-bottom: 3rem !important;
}

.pb-10 {
  padding-bottom: 4rem !important;
}

.pb-11 {
  padding-bottom: 5rem !important;
}

.pb-12 {
  padding-bottom: 6rem !important;
}

.pb-13 {
  padding-bottom: 8rem !important;
}

.pb-14 {
  padding-bottom: 10rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 0.75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.25rem !important;
}

.ps-6 {
  padding-left: 1.5rem !important;
}

.ps-7 {
  padding-left: 2rem !important;
}

.ps-8 {
  padding-left: 2.5rem !important;
}

.ps-9 {
  padding-left: 3rem !important;
}

.ps-10 {
  padding-left: 4rem !important;
}

.ps-11 {
  padding-left: 5rem !important;
}

.ps-12 {
  padding-left: 6rem !important;
}

.ps-13 {
  padding-left: 8rem !important;
}

.ps-14 {
  padding-left: 10rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 2rem !important;
}

.gap-8 {
  gap: 2.5rem !important;
}

.gap-9 {
  gap: 3rem !important;
}

.gap-10 {
  gap: 4rem !important;
}

.gap-11 {
  gap: 5rem !important;
}

.gap-12 {
  gap: 6rem !important;
}

.gap-13 {
  gap: 8rem !important;
}

.gap-14 {
  gap: 10rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 0.75rem !important;
}

.row-gap-4 {
  row-gap: 1rem !important;
}

.row-gap-5 {
  row-gap: 1.25rem !important;
}

.row-gap-6 {
  row-gap: 1.5rem !important;
}

.row-gap-7 {
  row-gap: 2rem !important;
}

.row-gap-8 {
  row-gap: 2.5rem !important;
}

.row-gap-9 {
  row-gap: 3rem !important;
}

.row-gap-10 {
  row-gap: 4rem !important;
}

.row-gap-11 {
  row-gap: 5rem !important;
}

.row-gap-12 {
  row-gap: 6rem !important;
}

.row-gap-13 {
  row-gap: 8rem !important;
}

.row-gap-14 {
  row-gap: 10rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 0.75rem !important;
}

.column-gap-4 {
  column-gap: 1rem !important;
}

.column-gap-5 {
  column-gap: 1.25rem !important;
}

.column-gap-6 {
  column-gap: 1.5rem !important;
}

.column-gap-7 {
  column-gap: 2rem !important;
}

.column-gap-8 {
  column-gap: 2.5rem !important;
}

.column-gap-9 {
  column-gap: 3rem !important;
}

.column-gap-10 {
  column-gap: 4rem !important;
}

.column-gap-11 {
  column-gap: 5rem !important;
}

.column-gap-12 {
  column-gap: 6rem !important;
}

.column-gap-13 {
  column-gap: 8rem !important;
}

.column-gap-14 {
  column-gap: 10rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: 1.25rem !important;
}

.fs-2 {
  font-size: 1.125rem !important;
}

.fs-3 {
  font-size: 1rem !important;
}

.fs-4 {
  font-size: 0.875rem !important;
}

.fs-5 {
  font-size: 0.75rem !important;
}

.fs-6 {
  font-size: 0.6875rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 600 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.42 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-grab {
  cursor: grab !important;
}

.wpx-25 {
  width: 25px !important;
}

.wpx-50 {
  width: 50px !important;
}

.wpx-75 {
  width: 75px !important;
}

.wpx-100 {
  width: 100px !important;
}

.wpx-125 {
  width: 125px !important;
}

.wpx-150 {
  width: 150px !important;
}

.wpx-175 {
  width: 175px !important;
}

.wpx-200 {
  width: 200px !important;
}

.wpx-225 {
  width: 225px !important;
}

.wpx-250 {
  width: 250px !important;
}

.wpx-275 {
  width: 275px !important;
}

.wpx-300 {
  width: 300px !important;
}

.wpx-325 {
  width: 325px !important;
}

.wpx-350 {
  width: 350px !important;
}

.wpx-375 {
  width: 375px !important;
}

.wpx-400 {
  width: 400px !important;
}

.wpx-425 {
  width: 425px !important;
}

.wpx-450 {
  width: 450px !important;
}

.wpx-475 {
  width: 475px !important;
}

.wpx-500 {
  width: 500px !important;
}

.wpx-525 {
  width: 525px !important;
}

.wpx-550 {
  width: 550px !important;
}

.wpx-575 {
  width: 575px !important;
}

.wpx-600 {
  width: 600px !important;
}

.wpx-625 {
  width: 625px !important;
}

.wpx-650 {
  width: 650px !important;
}

.wpx-675 {
  width: 675px !important;
}

.wpx-700 {
  width: 700px !important;
}

.wpx-725 {
  width: 725px !important;
}

.wpx-750 {
  width: 750px !important;
}

.wpx-775 {
  width: 775px !important;
}

.wpx-800 {
  width: 800px !important;
}

.wpx-825 {
  width: 825px !important;
}

.wpx-850 {
  width: 850px !important;
}

.wpx-875 {
  width: 875px !important;
}

.wpx-900 {
  width: 900px !important;
}

.wpx-925 {
  width: 925px !important;
}

.wpx-950 {
  width: 950px !important;
}

.wpx-975 {
  width: 975px !important;
}

.hpx-25 {
  height: 25px !important;
}

.hpx-50 {
  height: 50px !important;
}

.hpx-75 {
  height: 75px !important;
}

.hpx-100 {
  height: 100px !important;
}

.hpx-125 {
  height: 125px !important;
}

.hpx-150 {
  height: 150px !important;
}

.hpx-175 {
  height: 175px !important;
}

.hpx-200 {
  height: 200px !important;
}

.hpx-225 {
  height: 225px !important;
}

.hpx-250 {
  height: 250px !important;
}

.hpx-275 {
  height: 275px !important;
}

.hpx-300 {
  height: 300px !important;
}

.hpx-325 {
  height: 325px !important;
}

.hpx-350 {
  height: 350px !important;
}

.hpx-375 {
  height: 375px !important;
}

.hpx-400 {
  height: 400px !important;
}

.hpx-425 {
  height: 425px !important;
}

.hpx-450 {
  height: 450px !important;
}

.hpx-475 {
  height: 475px !important;
}

.hpx-500 {
  height: 500px !important;
}

.hpx-525 {
  height: 525px !important;
}

.hpx-550 {
  height: 550px !important;
}

.hpx-575 {
  height: 575px !important;
}

.hpx-600 {
  height: 600px !important;
}

.hpx-625 {
  height: 625px !important;
}

.hpx-650 {
  height: 650px !important;
}

.hpx-675 {
  height: 675px !important;
}

.hpx-700 {
  height: 700px !important;
}

.hpx-725 {
  height: 725px !important;
}

.hpx-750 {
  height: 750px !important;
}

.hpx-775 {
  height: 775px !important;
}

.hpx-800 {
  height: 800px !important;
}

.hpx-825 {
  height: 825px !important;
}

.hpx-850 {
  height: 850px !important;
}

.hpx-875 {
  height: 875px !important;
}

.hpx-900 {
  height: 900px !important;
}

.hpx-925 {
  height: 925px !important;
}

.hpx-950 {
  height: 950px !important;
}

.hpx-975 {
  height: 975px !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 0.75rem !important;
  }

  .m-sm-4 {
    margin: 1rem !important;
  }

  .m-sm-5 {
    margin: 1.25rem !important;
  }

  .m-sm-6 {
    margin: 1.5rem !important;
  }

  .m-sm-7 {
    margin: 2rem !important;
  }

  .m-sm-8 {
    margin: 2.5rem !important;
  }

  .m-sm-9 {
    margin: 3rem !important;
  }

  .m-sm-10 {
    margin: 4rem !important;
  }

  .m-sm-11 {
    margin: 5rem !important;
  }

  .m-sm-12 {
    margin: 6rem !important;
  }

  .m-sm-13 {
    margin: 8rem !important;
  }

  .m-sm-14 {
    margin: 10rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-sm-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-sm-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-9 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-10 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-12 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-sm-13 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-sm-14 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-sm-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-7 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-9 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-10 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-11 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-12 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-13 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-sm-14 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }

  .mt-sm-4 {
    margin-top: 1rem !important;
  }

  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }

  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 2rem !important;
  }

  .mt-sm-8 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-9 {
    margin-top: 3rem !important;
  }

  .mt-sm-10 {
    margin-top: 4rem !important;
  }

  .mt-sm-11 {
    margin-top: 5rem !important;
  }

  .mt-sm-12 {
    margin-top: 6rem !important;
  }

  .mt-sm-13 {
    margin-top: 8rem !important;
  }

  .mt-sm-14 {
    margin-top: 10rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 0.75rem !important;
  }

  .me-sm-4 {
    margin-right: 1rem !important;
  }

  .me-sm-5 {
    margin-right: 1.25rem !important;
  }

  .me-sm-6 {
    margin-right: 1.5rem !important;
  }

  .me-sm-7 {
    margin-right: 2rem !important;
  }

  .me-sm-8 {
    margin-right: 2.5rem !important;
  }

  .me-sm-9 {
    margin-right: 3rem !important;
  }

  .me-sm-10 {
    margin-right: 4rem !important;
  }

  .me-sm-11 {
    margin-right: 5rem !important;
  }

  .me-sm-12 {
    margin-right: 6rem !important;
  }

  .me-sm-13 {
    margin-right: 8rem !important;
  }

  .me-sm-14 {
    margin-right: 10rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 8rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 10rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }

  .ms-sm-4 {
    margin-left: 1rem !important;
  }

  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }

  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 2rem !important;
  }

  .ms-sm-8 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-9 {
    margin-left: 3rem !important;
  }

  .ms-sm-10 {
    margin-left: 4rem !important;
  }

  .ms-sm-11 {
    margin-left: 5rem !important;
  }

  .ms-sm-12 {
    margin-left: 6rem !important;
  }

  .ms-sm-13 {
    margin-left: 8rem !important;
  }

  .ms-sm-14 {
    margin-left: 10rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -0.75rem !important;
  }

  .m-sm-n4 {
    margin: -1rem !important;
  }

  .m-sm-n5 {
    margin: -1.25rem !important;
  }

  .m-sm-n6 {
    margin: -1.5rem !important;
  }

  .m-sm-n7 {
    margin: -2rem !important;
  }

  .m-sm-n8 {
    margin: -2.5rem !important;
  }

  .m-sm-n9 {
    margin: -3rem !important;
  }

  .m-sm-n10 {
    margin: -4rem !important;
  }

  .m-sm-n11 {
    margin: -5rem !important;
  }

  .m-sm-n12 {
    margin: -6rem !important;
  }

  .m-sm-n13 {
    margin: -8rem !important;
  }

  .m-sm-n14 {
    margin: -10rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-sm-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n7 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-sm-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-sm-n9 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n10 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-sm-n11 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-sm-n12 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-sm-n13 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-sm-n14 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-sm-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-sm-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n9 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n10 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n11 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-sm-n12 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-sm-n13 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-sm-n14 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1rem !important;
  }

  .mt-sm-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-sm-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -2rem !important;
  }

  .mt-sm-n8 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n9 {
    margin-top: -3rem !important;
  }

  .mt-sm-n10 {
    margin-top: -4rem !important;
  }

  .mt-sm-n11 {
    margin-top: -5rem !important;
  }

  .mt-sm-n12 {
    margin-top: -6rem !important;
  }

  .mt-sm-n13 {
    margin-top: -8rem !important;
  }

  .mt-sm-n14 {
    margin-top: -10rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -0.75rem !important;
  }

  .me-sm-n4 {
    margin-right: -1rem !important;
  }

  .me-sm-n5 {
    margin-right: -1.25rem !important;
  }

  .me-sm-n6 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -2rem !important;
  }

  .me-sm-n8 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n9 {
    margin-right: -3rem !important;
  }

  .me-sm-n10 {
    margin-right: -4rem !important;
  }

  .me-sm-n11 {
    margin-right: -5rem !important;
  }

  .me-sm-n12 {
    margin-right: -6rem !important;
  }

  .me-sm-n13 {
    margin-right: -8rem !important;
  }

  .me-sm-n14 {
    margin-right: -10rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -5rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -6rem !important;
  }

  .mb-sm-n13 {
    margin-bottom: -8rem !important;
  }

  .mb-sm-n14 {
    margin-bottom: -10rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1rem !important;
  }

  .ms-sm-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-sm-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -2rem !important;
  }

  .ms-sm-n8 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n9 {
    margin-left: -3rem !important;
  }

  .ms-sm-n10 {
    margin-left: -4rem !important;
  }

  .ms-sm-n11 {
    margin-left: -5rem !important;
  }

  .ms-sm-n12 {
    margin-left: -6rem !important;
  }

  .ms-sm-n13 {
    margin-left: -8rem !important;
  }

  .ms-sm-n14 {
    margin-left: -10rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 0.75rem !important;
  }

  .p-sm-4 {
    padding: 1rem !important;
  }

  .p-sm-5 {
    padding: 1.25rem !important;
  }

  .p-sm-6 {
    padding: 1.5rem !important;
  }

  .p-sm-7 {
    padding: 2rem !important;
  }

  .p-sm-8 {
    padding: 2.5rem !important;
  }

  .p-sm-9 {
    padding: 3rem !important;
  }

  .p-sm-10 {
    padding: 4rem !important;
  }

  .p-sm-11 {
    padding: 5rem !important;
  }

  .p-sm-12 {
    padding: 6rem !important;
  }

  .p-sm-13 {
    padding: 8rem !important;
  }

  .p-sm-14 {
    padding: 10rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-sm-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-sm-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-7 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-9 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-10 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-11 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-sm-12 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-sm-13 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-sm-14 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-sm-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-7 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-9 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-10 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-sm-12 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-sm-13 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-sm-14 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }

  .pt-sm-4 {
    padding-top: 1rem !important;
  }

  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }

  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 2rem !important;
  }

  .pt-sm-8 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-9 {
    padding-top: 3rem !important;
  }

  .pt-sm-10 {
    padding-top: 4rem !important;
  }

  .pt-sm-11 {
    padding-top: 5rem !important;
  }

  .pt-sm-12 {
    padding-top: 6rem !important;
  }

  .pt-sm-13 {
    padding-top: 8rem !important;
  }

  .pt-sm-14 {
    padding-top: 10rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }

  .pe-sm-4 {
    padding-right: 1rem !important;
  }

  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }

  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 2rem !important;
  }

  .pe-sm-8 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-9 {
    padding-right: 3rem !important;
  }

  .pe-sm-10 {
    padding-right: 4rem !important;
  }

  .pe-sm-11 {
    padding-right: 5rem !important;
  }

  .pe-sm-12 {
    padding-right: 6rem !important;
  }

  .pe-sm-13 {
    padding-right: 8rem !important;
  }

  .pe-sm-14 {
    padding-right: 10rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 5rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 6rem !important;
  }

  .pb-sm-13 {
    padding-bottom: 8rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 10rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }

  .ps-sm-4 {
    padding-left: 1rem !important;
  }

  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }

  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 2rem !important;
  }

  .ps-sm-8 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-9 {
    padding-left: 3rem !important;
  }

  .ps-sm-10 {
    padding-left: 4rem !important;
  }

  .ps-sm-11 {
    padding-left: 5rem !important;
  }

  .ps-sm-12 {
    padding-left: 6rem !important;
  }

  .ps-sm-13 {
    padding-left: 8rem !important;
  }

  .ps-sm-14 {
    padding-left: 10rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 0.75rem !important;
  }

  .gap-sm-4 {
    gap: 1rem !important;
  }

  .gap-sm-5 {
    gap: 1.25rem !important;
  }

  .gap-sm-6 {
    gap: 1.5rem !important;
  }

  .gap-sm-7 {
    gap: 2rem !important;
  }

  .gap-sm-8 {
    gap: 2.5rem !important;
  }

  .gap-sm-9 {
    gap: 3rem !important;
  }

  .gap-sm-10 {
    gap: 4rem !important;
  }

  .gap-sm-11 {
    gap: 5rem !important;
  }

  .gap-sm-12 {
    gap: 6rem !important;
  }

  .gap-sm-13 {
    gap: 8rem !important;
  }

  .gap-sm-14 {
    gap: 10rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-sm-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-7 {
    row-gap: 2rem !important;
  }

  .row-gap-sm-8 {
    row-gap: 2.5rem !important;
  }

  .row-gap-sm-9 {
    row-gap: 3rem !important;
  }

  .row-gap-sm-10 {
    row-gap: 4rem !important;
  }

  .row-gap-sm-11 {
    row-gap: 5rem !important;
  }

  .row-gap-sm-12 {
    row-gap: 6rem !important;
  }

  .row-gap-sm-13 {
    row-gap: 8rem !important;
  }

  .row-gap-sm-14 {
    row-gap: 10rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-sm-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-7 {
    column-gap: 2rem !important;
  }

  .column-gap-sm-8 {
    column-gap: 2.5rem !important;
  }

  .column-gap-sm-9 {
    column-gap: 3rem !important;
  }

  .column-gap-sm-10 {
    column-gap: 4rem !important;
  }

  .column-gap-sm-11 {
    column-gap: 5rem !important;
  }

  .column-gap-sm-12 {
    column-gap: 6rem !important;
  }

  .column-gap-sm-13 {
    column-gap: 8rem !important;
  }

  .column-gap-sm-14 {
    column-gap: 10rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .wpx-sm-25 {
    width: 25px !important;
  }

  .wpx-sm-50 {
    width: 50px !important;
  }

  .wpx-sm-75 {
    width: 75px !important;
  }

  .wpx-sm-100 {
    width: 100px !important;
  }

  .wpx-sm-125 {
    width: 125px !important;
  }

  .wpx-sm-150 {
    width: 150px !important;
  }

  .wpx-sm-175 {
    width: 175px !important;
  }

  .wpx-sm-200 {
    width: 200px !important;
  }

  .wpx-sm-225 {
    width: 225px !important;
  }

  .wpx-sm-250 {
    width: 250px !important;
  }

  .wpx-sm-275 {
    width: 275px !important;
  }

  .wpx-sm-300 {
    width: 300px !important;
  }

  .wpx-sm-325 {
    width: 325px !important;
  }

  .wpx-sm-350 {
    width: 350px !important;
  }

  .wpx-sm-375 {
    width: 375px !important;
  }

  .wpx-sm-400 {
    width: 400px !important;
  }

  .wpx-sm-425 {
    width: 425px !important;
  }

  .wpx-sm-450 {
    width: 450px !important;
  }

  .wpx-sm-475 {
    width: 475px !important;
  }

  .wpx-sm-500 {
    width: 500px !important;
  }

  .wpx-sm-525 {
    width: 525px !important;
  }

  .wpx-sm-550 {
    width: 550px !important;
  }

  .wpx-sm-575 {
    width: 575px !important;
  }

  .wpx-sm-600 {
    width: 600px !important;
  }

  .wpx-sm-625 {
    width: 625px !important;
  }

  .wpx-sm-650 {
    width: 650px !important;
  }

  .wpx-sm-675 {
    width: 675px !important;
  }

  .wpx-sm-700 {
    width: 700px !important;
  }

  .wpx-sm-725 {
    width: 725px !important;
  }

  .wpx-sm-750 {
    width: 750px !important;
  }

  .wpx-sm-775 {
    width: 775px !important;
  }

  .wpx-sm-800 {
    width: 800px !important;
  }

  .wpx-sm-825 {
    width: 825px !important;
  }

  .wpx-sm-850 {
    width: 850px !important;
  }

  .wpx-sm-875 {
    width: 875px !important;
  }

  .wpx-sm-900 {
    width: 900px !important;
  }

  .wpx-sm-925 {
    width: 925px !important;
  }

  .wpx-sm-950 {
    width: 950px !important;
  }

  .wpx-sm-975 {
    width: 975px !important;
  }

  .hpx-sm-25 {
    height: 25px !important;
  }

  .hpx-sm-50 {
    height: 50px !important;
  }

  .hpx-sm-75 {
    height: 75px !important;
  }

  .hpx-sm-100 {
    height: 100px !important;
  }

  .hpx-sm-125 {
    height: 125px !important;
  }

  .hpx-sm-150 {
    height: 150px !important;
  }

  .hpx-sm-175 {
    height: 175px !important;
  }

  .hpx-sm-200 {
    height: 200px !important;
  }

  .hpx-sm-225 {
    height: 225px !important;
  }

  .hpx-sm-250 {
    height: 250px !important;
  }

  .hpx-sm-275 {
    height: 275px !important;
  }

  .hpx-sm-300 {
    height: 300px !important;
  }

  .hpx-sm-325 {
    height: 325px !important;
  }

  .hpx-sm-350 {
    height: 350px !important;
  }

  .hpx-sm-375 {
    height: 375px !important;
  }

  .hpx-sm-400 {
    height: 400px !important;
  }

  .hpx-sm-425 {
    height: 425px !important;
  }

  .hpx-sm-450 {
    height: 450px !important;
  }

  .hpx-sm-475 {
    height: 475px !important;
  }

  .hpx-sm-500 {
    height: 500px !important;
  }

  .hpx-sm-525 {
    height: 525px !important;
  }

  .hpx-sm-550 {
    height: 550px !important;
  }

  .hpx-sm-575 {
    height: 575px !important;
  }

  .hpx-sm-600 {
    height: 600px !important;
  }

  .hpx-sm-625 {
    height: 625px !important;
  }

  .hpx-sm-650 {
    height: 650px !important;
  }

  .hpx-sm-675 {
    height: 675px !important;
  }

  .hpx-sm-700 {
    height: 700px !important;
  }

  .hpx-sm-725 {
    height: 725px !important;
  }

  .hpx-sm-750 {
    height: 750px !important;
  }

  .hpx-sm-775 {
    height: 775px !important;
  }

  .hpx-sm-800 {
    height: 800px !important;
  }

  .hpx-sm-825 {
    height: 825px !important;
  }

  .hpx-sm-850 {
    height: 850px !important;
  }

  .hpx-sm-875 {
    height: 875px !important;
  }

  .hpx-sm-900 {
    height: 900px !important;
  }

  .hpx-sm-925 {
    height: 925px !important;
  }

  .hpx-sm-950 {
    height: 950px !important;
  }

  .hpx-sm-975 {
    height: 975px !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 0.75rem !important;
  }

  .m-md-4 {
    margin: 1rem !important;
  }

  .m-md-5 {
    margin: 1.25rem !important;
  }

  .m-md-6 {
    margin: 1.5rem !important;
  }

  .m-md-7 {
    margin: 2rem !important;
  }

  .m-md-8 {
    margin: 2.5rem !important;
  }

  .m-md-9 {
    margin: 3rem !important;
  }

  .m-md-10 {
    margin: 4rem !important;
  }

  .m-md-11 {
    margin: 5rem !important;
  }

  .m-md-12 {
    margin: 6rem !important;
  }

  .m-md-13 {
    margin: 8rem !important;
  }

  .m-md-14 {
    margin: 10rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-md-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-md-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-7 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-9 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-10 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-12 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-md-13 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-md-14 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-md-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-7 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-9 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-10 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-11 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-12 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-13 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-md-14 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 0.75rem !important;
  }

  .mt-md-4 {
    margin-top: 1rem !important;
  }

  .mt-md-5 {
    margin-top: 1.25rem !important;
  }

  .mt-md-6 {
    margin-top: 1.5rem !important;
  }

  .mt-md-7 {
    margin-top: 2rem !important;
  }

  .mt-md-8 {
    margin-top: 2.5rem !important;
  }

  .mt-md-9 {
    margin-top: 3rem !important;
  }

  .mt-md-10 {
    margin-top: 4rem !important;
  }

  .mt-md-11 {
    margin-top: 5rem !important;
  }

  .mt-md-12 {
    margin-top: 6rem !important;
  }

  .mt-md-13 {
    margin-top: 8rem !important;
  }

  .mt-md-14 {
    margin-top: 10rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 0.75rem !important;
  }

  .me-md-4 {
    margin-right: 1rem !important;
  }

  .me-md-5 {
    margin-right: 1.25rem !important;
  }

  .me-md-6 {
    margin-right: 1.5rem !important;
  }

  .me-md-7 {
    margin-right: 2rem !important;
  }

  .me-md-8 {
    margin-right: 2.5rem !important;
  }

  .me-md-9 {
    margin-right: 3rem !important;
  }

  .me-md-10 {
    margin-right: 4rem !important;
  }

  .me-md-11 {
    margin-right: 5rem !important;
  }

  .me-md-12 {
    margin-right: 6rem !important;
  }

  .me-md-13 {
    margin-right: 8rem !important;
  }

  .me-md-14 {
    margin-right: 10rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1rem !important;
  }

  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 2rem !important;
  }

  .mb-md-8 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-9 {
    margin-bottom: 3rem !important;
  }

  .mb-md-10 {
    margin-bottom: 4rem !important;
  }

  .mb-md-11 {
    margin-bottom: 5rem !important;
  }

  .mb-md-12 {
    margin-bottom: 6rem !important;
  }

  .mb-md-13 {
    margin-bottom: 8rem !important;
  }

  .mb-md-14 {
    margin-bottom: 10rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 0.75rem !important;
  }

  .ms-md-4 {
    margin-left: 1rem !important;
  }

  .ms-md-5 {
    margin-left: 1.25rem !important;
  }

  .ms-md-6 {
    margin-left: 1.5rem !important;
  }

  .ms-md-7 {
    margin-left: 2rem !important;
  }

  .ms-md-8 {
    margin-left: 2.5rem !important;
  }

  .ms-md-9 {
    margin-left: 3rem !important;
  }

  .ms-md-10 {
    margin-left: 4rem !important;
  }

  .ms-md-11 {
    margin-left: 5rem !important;
  }

  .ms-md-12 {
    margin-left: 6rem !important;
  }

  .ms-md-13 {
    margin-left: 8rem !important;
  }

  .ms-md-14 {
    margin-left: 10rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -0.75rem !important;
  }

  .m-md-n4 {
    margin: -1rem !important;
  }

  .m-md-n5 {
    margin: -1.25rem !important;
  }

  .m-md-n6 {
    margin: -1.5rem !important;
  }

  .m-md-n7 {
    margin: -2rem !important;
  }

  .m-md-n8 {
    margin: -2.5rem !important;
  }

  .m-md-n9 {
    margin: -3rem !important;
  }

  .m-md-n10 {
    margin: -4rem !important;
  }

  .m-md-n11 {
    margin: -5rem !important;
  }

  .m-md-n12 {
    margin: -6rem !important;
  }

  .m-md-n13 {
    margin: -8rem !important;
  }

  .m-md-n14 {
    margin: -10rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-md-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-md-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n7 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-md-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-md-n9 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n10 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-md-n11 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-md-n12 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-md-n13 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-md-n14 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-md-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-md-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n7 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n9 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n10 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n11 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-md-n12 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-md-n13 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-md-n14 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-md-n4 {
    margin-top: -1rem !important;
  }

  .mt-md-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-md-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -2rem !important;
  }

  .mt-md-n8 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n9 {
    margin-top: -3rem !important;
  }

  .mt-md-n10 {
    margin-top: -4rem !important;
  }

  .mt-md-n11 {
    margin-top: -5rem !important;
  }

  .mt-md-n12 {
    margin-top: -6rem !important;
  }

  .mt-md-n13 {
    margin-top: -8rem !important;
  }

  .mt-md-n14 {
    margin-top: -10rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -0.75rem !important;
  }

  .me-md-n4 {
    margin-right: -1rem !important;
  }

  .me-md-n5 {
    margin-right: -1.25rem !important;
  }

  .me-md-n6 {
    margin-right: -1.5rem !important;
  }

  .me-md-n7 {
    margin-right: -2rem !important;
  }

  .me-md-n8 {
    margin-right: -2.5rem !important;
  }

  .me-md-n9 {
    margin-right: -3rem !important;
  }

  .me-md-n10 {
    margin-right: -4rem !important;
  }

  .me-md-n11 {
    margin-right: -5rem !important;
  }

  .me-md-n12 {
    margin-right: -6rem !important;
  }

  .me-md-n13 {
    margin-right: -8rem !important;
  }

  .me-md-n14 {
    margin-right: -10rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -5rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -6rem !important;
  }

  .mb-md-n13 {
    margin-bottom: -8rem !important;
  }

  .mb-md-n14 {
    margin-bottom: -10rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-md-n4 {
    margin-left: -1rem !important;
  }

  .ms-md-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-md-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -2rem !important;
  }

  .ms-md-n8 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n9 {
    margin-left: -3rem !important;
  }

  .ms-md-n10 {
    margin-left: -4rem !important;
  }

  .ms-md-n11 {
    margin-left: -5rem !important;
  }

  .ms-md-n12 {
    margin-left: -6rem !important;
  }

  .ms-md-n13 {
    margin-left: -8rem !important;
  }

  .ms-md-n14 {
    margin-left: -10rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 0.75rem !important;
  }

  .p-md-4 {
    padding: 1rem !important;
  }

  .p-md-5 {
    padding: 1.25rem !important;
  }

  .p-md-6 {
    padding: 1.5rem !important;
  }

  .p-md-7 {
    padding: 2rem !important;
  }

  .p-md-8 {
    padding: 2.5rem !important;
  }

  .p-md-9 {
    padding: 3rem !important;
  }

  .p-md-10 {
    padding: 4rem !important;
  }

  .p-md-11 {
    padding: 5rem !important;
  }

  .p-md-12 {
    padding: 6rem !important;
  }

  .p-md-13 {
    padding: 8rem !important;
  }

  .p-md-14 {
    padding: 10rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-md-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-7 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-9 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-10 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-11 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-md-12 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-md-13 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-md-14 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-md-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-7 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-9 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-10 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-md-12 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-md-13 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-md-14 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 0.75rem !important;
  }

  .pt-md-4 {
    padding-top: 1rem !important;
  }

  .pt-md-5 {
    padding-top: 1.25rem !important;
  }

  .pt-md-6 {
    padding-top: 1.5rem !important;
  }

  .pt-md-7 {
    padding-top: 2rem !important;
  }

  .pt-md-8 {
    padding-top: 2.5rem !important;
  }

  .pt-md-9 {
    padding-top: 3rem !important;
  }

  .pt-md-10 {
    padding-top: 4rem !important;
  }

  .pt-md-11 {
    padding-top: 5rem !important;
  }

  .pt-md-12 {
    padding-top: 6rem !important;
  }

  .pt-md-13 {
    padding-top: 8rem !important;
  }

  .pt-md-14 {
    padding-top: 10rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 0.75rem !important;
  }

  .pe-md-4 {
    padding-right: 1rem !important;
  }

  .pe-md-5 {
    padding-right: 1.25rem !important;
  }

  .pe-md-6 {
    padding-right: 1.5rem !important;
  }

  .pe-md-7 {
    padding-right: 2rem !important;
  }

  .pe-md-8 {
    padding-right: 2.5rem !important;
  }

  .pe-md-9 {
    padding-right: 3rem !important;
  }

  .pe-md-10 {
    padding-right: 4rem !important;
  }

  .pe-md-11 {
    padding-right: 5rem !important;
  }

  .pe-md-12 {
    padding-right: 6rem !important;
  }

  .pe-md-13 {
    padding-right: 8rem !important;
  }

  .pe-md-14 {
    padding-right: 10rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1rem !important;
  }

  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 2rem !important;
  }

  .pb-md-8 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-9 {
    padding-bottom: 3rem !important;
  }

  .pb-md-10 {
    padding-bottom: 4rem !important;
  }

  .pb-md-11 {
    padding-bottom: 5rem !important;
  }

  .pb-md-12 {
    padding-bottom: 6rem !important;
  }

  .pb-md-13 {
    padding-bottom: 8rem !important;
  }

  .pb-md-14 {
    padding-bottom: 10rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 0.75rem !important;
  }

  .ps-md-4 {
    padding-left: 1rem !important;
  }

  .ps-md-5 {
    padding-left: 1.25rem !important;
  }

  .ps-md-6 {
    padding-left: 1.5rem !important;
  }

  .ps-md-7 {
    padding-left: 2rem !important;
  }

  .ps-md-8 {
    padding-left: 2.5rem !important;
  }

  .ps-md-9 {
    padding-left: 3rem !important;
  }

  .ps-md-10 {
    padding-left: 4rem !important;
  }

  .ps-md-11 {
    padding-left: 5rem !important;
  }

  .ps-md-12 {
    padding-left: 6rem !important;
  }

  .ps-md-13 {
    padding-left: 8rem !important;
  }

  .ps-md-14 {
    padding-left: 10rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 0.75rem !important;
  }

  .gap-md-4 {
    gap: 1rem !important;
  }

  .gap-md-5 {
    gap: 1.25rem !important;
  }

  .gap-md-6 {
    gap: 1.5rem !important;
  }

  .gap-md-7 {
    gap: 2rem !important;
  }

  .gap-md-8 {
    gap: 2.5rem !important;
  }

  .gap-md-9 {
    gap: 3rem !important;
  }

  .gap-md-10 {
    gap: 4rem !important;
  }

  .gap-md-11 {
    gap: 5rem !important;
  }

  .gap-md-12 {
    gap: 6rem !important;
  }

  .gap-md-13 {
    gap: 8rem !important;
  }

  .gap-md-14 {
    gap: 10rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1rem !important;
  }

  .row-gap-md-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-md-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-7 {
    row-gap: 2rem !important;
  }

  .row-gap-md-8 {
    row-gap: 2.5rem !important;
  }

  .row-gap-md-9 {
    row-gap: 3rem !important;
  }

  .row-gap-md-10 {
    row-gap: 4rem !important;
  }

  .row-gap-md-11 {
    row-gap: 5rem !important;
  }

  .row-gap-md-12 {
    row-gap: 6rem !important;
  }

  .row-gap-md-13 {
    row-gap: 8rem !important;
  }

  .row-gap-md-14 {
    row-gap: 10rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1rem !important;
  }

  .column-gap-md-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-md-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-7 {
    column-gap: 2rem !important;
  }

  .column-gap-md-8 {
    column-gap: 2.5rem !important;
  }

  .column-gap-md-9 {
    column-gap: 3rem !important;
  }

  .column-gap-md-10 {
    column-gap: 4rem !important;
  }

  .column-gap-md-11 {
    column-gap: 5rem !important;
  }

  .column-gap-md-12 {
    column-gap: 6rem !important;
  }

  .column-gap-md-13 {
    column-gap: 8rem !important;
  }

  .column-gap-md-14 {
    column-gap: 10rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .wpx-md-25 {
    width: 25px !important;
  }

  .wpx-md-50 {
    width: 50px !important;
  }

  .wpx-md-75 {
    width: 75px !important;
  }

  .wpx-md-100 {
    width: 100px !important;
  }

  .wpx-md-125 {
    width: 125px !important;
  }

  .wpx-md-150 {
    width: 150px !important;
  }

  .wpx-md-175 {
    width: 175px !important;
  }

  .wpx-md-200 {
    width: 200px !important;
  }

  .wpx-md-225 {
    width: 225px !important;
  }

  .wpx-md-250 {
    width: 250px !important;
  }

  .wpx-md-275 {
    width: 275px !important;
  }

  .wpx-md-300 {
    width: 300px !important;
  }

  .wpx-md-325 {
    width: 325px !important;
  }

  .wpx-md-350 {
    width: 350px !important;
  }

  .wpx-md-375 {
    width: 375px !important;
  }

  .wpx-md-400 {
    width: 400px !important;
  }

  .wpx-md-425 {
    width: 425px !important;
  }

  .wpx-md-450 {
    width: 450px !important;
  }

  .wpx-md-475 {
    width: 475px !important;
  }

  .wpx-md-500 {
    width: 500px !important;
  }

  .wpx-md-525 {
    width: 525px !important;
  }

  .wpx-md-550 {
    width: 550px !important;
  }

  .wpx-md-575 {
    width: 575px !important;
  }

  .wpx-md-600 {
    width: 600px !important;
  }

  .wpx-md-625 {
    width: 625px !important;
  }

  .wpx-md-650 {
    width: 650px !important;
  }

  .wpx-md-675 {
    width: 675px !important;
  }

  .wpx-md-700 {
    width: 700px !important;
  }

  .wpx-md-725 {
    width: 725px !important;
  }

  .wpx-md-750 {
    width: 750px !important;
  }

  .wpx-md-775 {
    width: 775px !important;
  }

  .wpx-md-800 {
    width: 800px !important;
  }

  .wpx-md-825 {
    width: 825px !important;
  }

  .wpx-md-850 {
    width: 850px !important;
  }

  .wpx-md-875 {
    width: 875px !important;
  }

  .wpx-md-900 {
    width: 900px !important;
  }

  .wpx-md-925 {
    width: 925px !important;
  }

  .wpx-md-950 {
    width: 950px !important;
  }

  .wpx-md-975 {
    width: 975px !important;
  }

  .hpx-md-25 {
    height: 25px !important;
  }

  .hpx-md-50 {
    height: 50px !important;
  }

  .hpx-md-75 {
    height: 75px !important;
  }

  .hpx-md-100 {
    height: 100px !important;
  }

  .hpx-md-125 {
    height: 125px !important;
  }

  .hpx-md-150 {
    height: 150px !important;
  }

  .hpx-md-175 {
    height: 175px !important;
  }

  .hpx-md-200 {
    height: 200px !important;
  }

  .hpx-md-225 {
    height: 225px !important;
  }

  .hpx-md-250 {
    height: 250px !important;
  }

  .hpx-md-275 {
    height: 275px !important;
  }

  .hpx-md-300 {
    height: 300px !important;
  }

  .hpx-md-325 {
    height: 325px !important;
  }

  .hpx-md-350 {
    height: 350px !important;
  }

  .hpx-md-375 {
    height: 375px !important;
  }

  .hpx-md-400 {
    height: 400px !important;
  }

  .hpx-md-425 {
    height: 425px !important;
  }

  .hpx-md-450 {
    height: 450px !important;
  }

  .hpx-md-475 {
    height: 475px !important;
  }

  .hpx-md-500 {
    height: 500px !important;
  }

  .hpx-md-525 {
    height: 525px !important;
  }

  .hpx-md-550 {
    height: 550px !important;
  }

  .hpx-md-575 {
    height: 575px !important;
  }

  .hpx-md-600 {
    height: 600px !important;
  }

  .hpx-md-625 {
    height: 625px !important;
  }

  .hpx-md-650 {
    height: 650px !important;
  }

  .hpx-md-675 {
    height: 675px !important;
  }

  .hpx-md-700 {
    height: 700px !important;
  }

  .hpx-md-725 {
    height: 725px !important;
  }

  .hpx-md-750 {
    height: 750px !important;
  }

  .hpx-md-775 {
    height: 775px !important;
  }

  .hpx-md-800 {
    height: 800px !important;
  }

  .hpx-md-825 {
    height: 825px !important;
  }

  .hpx-md-850 {
    height: 850px !important;
  }

  .hpx-md-875 {
    height: 875px !important;
  }

  .hpx-md-900 {
    height: 900px !important;
  }

  .hpx-md-925 {
    height: 925px !important;
  }

  .hpx-md-950 {
    height: 950px !important;
  }

  .hpx-md-975 {
    height: 975px !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 0.75rem !important;
  }

  .m-lg-4 {
    margin: 1rem !important;
  }

  .m-lg-5 {
    margin: 1.25rem !important;
  }

  .m-lg-6 {
    margin: 1.5rem !important;
  }

  .m-lg-7 {
    margin: 2rem !important;
  }

  .m-lg-8 {
    margin: 2.5rem !important;
  }

  .m-lg-9 {
    margin: 3rem !important;
  }

  .m-lg-10 {
    margin: 4rem !important;
  }

  .m-lg-11 {
    margin: 5rem !important;
  }

  .m-lg-12 {
    margin: 6rem !important;
  }

  .m-lg-13 {
    margin: 8rem !important;
  }

  .m-lg-14 {
    margin: 10rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-lg-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-lg-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-9 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-10 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-12 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-lg-13 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-lg-14 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-lg-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-7 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-9 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-10 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-11 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-12 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-13 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-lg-14 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }

  .mt-lg-4 {
    margin-top: 1rem !important;
  }

  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }

  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 2rem !important;
  }

  .mt-lg-8 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-9 {
    margin-top: 3rem !important;
  }

  .mt-lg-10 {
    margin-top: 4rem !important;
  }

  .mt-lg-11 {
    margin-top: 5rem !important;
  }

  .mt-lg-12 {
    margin-top: 6rem !important;
  }

  .mt-lg-13 {
    margin-top: 8rem !important;
  }

  .mt-lg-14 {
    margin-top: 10rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 0.75rem !important;
  }

  .me-lg-4 {
    margin-right: 1rem !important;
  }

  .me-lg-5 {
    margin-right: 1.25rem !important;
  }

  .me-lg-6 {
    margin-right: 1.5rem !important;
  }

  .me-lg-7 {
    margin-right: 2rem !important;
  }

  .me-lg-8 {
    margin-right: 2.5rem !important;
  }

  .me-lg-9 {
    margin-right: 3rem !important;
  }

  .me-lg-10 {
    margin-right: 4rem !important;
  }

  .me-lg-11 {
    margin-right: 5rem !important;
  }

  .me-lg-12 {
    margin-right: 6rem !important;
  }

  .me-lg-13 {
    margin-right: 8rem !important;
  }

  .me-lg-14 {
    margin-right: 10rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 8rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 10rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }

  .ms-lg-4 {
    margin-left: 1rem !important;
  }

  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }

  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 2rem !important;
  }

  .ms-lg-8 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-9 {
    margin-left: 3rem !important;
  }

  .ms-lg-10 {
    margin-left: 4rem !important;
  }

  .ms-lg-11 {
    margin-left: 5rem !important;
  }

  .ms-lg-12 {
    margin-left: 6rem !important;
  }

  .ms-lg-13 {
    margin-left: 8rem !important;
  }

  .ms-lg-14 {
    margin-left: 10rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -0.75rem !important;
  }

  .m-lg-n4 {
    margin: -1rem !important;
  }

  .m-lg-n5 {
    margin: -1.25rem !important;
  }

  .m-lg-n6 {
    margin: -1.5rem !important;
  }

  .m-lg-n7 {
    margin: -2rem !important;
  }

  .m-lg-n8 {
    margin: -2.5rem !important;
  }

  .m-lg-n9 {
    margin: -3rem !important;
  }

  .m-lg-n10 {
    margin: -4rem !important;
  }

  .m-lg-n11 {
    margin: -5rem !important;
  }

  .m-lg-n12 {
    margin: -6rem !important;
  }

  .m-lg-n13 {
    margin: -8rem !important;
  }

  .m-lg-n14 {
    margin: -10rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-lg-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n7 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-lg-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-lg-n9 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n10 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-lg-n11 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-lg-n12 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-lg-n13 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-lg-n14 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-lg-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-lg-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n9 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n10 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n11 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-lg-n12 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-lg-n13 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-lg-n14 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1rem !important;
  }

  .mt-lg-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-lg-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -2rem !important;
  }

  .mt-lg-n8 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n9 {
    margin-top: -3rem !important;
  }

  .mt-lg-n10 {
    margin-top: -4rem !important;
  }

  .mt-lg-n11 {
    margin-top: -5rem !important;
  }

  .mt-lg-n12 {
    margin-top: -6rem !important;
  }

  .mt-lg-n13 {
    margin-top: -8rem !important;
  }

  .mt-lg-n14 {
    margin-top: -10rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -0.75rem !important;
  }

  .me-lg-n4 {
    margin-right: -1rem !important;
  }

  .me-lg-n5 {
    margin-right: -1.25rem !important;
  }

  .me-lg-n6 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -2rem !important;
  }

  .me-lg-n8 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n9 {
    margin-right: -3rem !important;
  }

  .me-lg-n10 {
    margin-right: -4rem !important;
  }

  .me-lg-n11 {
    margin-right: -5rem !important;
  }

  .me-lg-n12 {
    margin-right: -6rem !important;
  }

  .me-lg-n13 {
    margin-right: -8rem !important;
  }

  .me-lg-n14 {
    margin-right: -10rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -5rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -6rem !important;
  }

  .mb-lg-n13 {
    margin-bottom: -8rem !important;
  }

  .mb-lg-n14 {
    margin-bottom: -10rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1rem !important;
  }

  .ms-lg-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-lg-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -2rem !important;
  }

  .ms-lg-n8 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n9 {
    margin-left: -3rem !important;
  }

  .ms-lg-n10 {
    margin-left: -4rem !important;
  }

  .ms-lg-n11 {
    margin-left: -5rem !important;
  }

  .ms-lg-n12 {
    margin-left: -6rem !important;
  }

  .ms-lg-n13 {
    margin-left: -8rem !important;
  }

  .ms-lg-n14 {
    margin-left: -10rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 0.75rem !important;
  }

  .p-lg-4 {
    padding: 1rem !important;
  }

  .p-lg-5 {
    padding: 1.25rem !important;
  }

  .p-lg-6 {
    padding: 1.5rem !important;
  }

  .p-lg-7 {
    padding: 2rem !important;
  }

  .p-lg-8 {
    padding: 2.5rem !important;
  }

  .p-lg-9 {
    padding: 3rem !important;
  }

  .p-lg-10 {
    padding: 4rem !important;
  }

  .p-lg-11 {
    padding: 5rem !important;
  }

  .p-lg-12 {
    padding: 6rem !important;
  }

  .p-lg-13 {
    padding: 8rem !important;
  }

  .p-lg-14 {
    padding: 10rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-lg-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-lg-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-7 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-9 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-10 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-11 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-lg-12 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-lg-13 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-lg-14 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-lg-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-7 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-9 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-10 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-lg-12 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-lg-13 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-lg-14 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }

  .pt-lg-4 {
    padding-top: 1rem !important;
  }

  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }

  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 2rem !important;
  }

  .pt-lg-8 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-9 {
    padding-top: 3rem !important;
  }

  .pt-lg-10 {
    padding-top: 4rem !important;
  }

  .pt-lg-11 {
    padding-top: 5rem !important;
  }

  .pt-lg-12 {
    padding-top: 6rem !important;
  }

  .pt-lg-13 {
    padding-top: 8rem !important;
  }

  .pt-lg-14 {
    padding-top: 10rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }

  .pe-lg-4 {
    padding-right: 1rem !important;
  }

  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }

  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 2rem !important;
  }

  .pe-lg-8 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-9 {
    padding-right: 3rem !important;
  }

  .pe-lg-10 {
    padding-right: 4rem !important;
  }

  .pe-lg-11 {
    padding-right: 5rem !important;
  }

  .pe-lg-12 {
    padding-right: 6rem !important;
  }

  .pe-lg-13 {
    padding-right: 8rem !important;
  }

  .pe-lg-14 {
    padding-right: 10rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 5rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 6rem !important;
  }

  .pb-lg-13 {
    padding-bottom: 8rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 10rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }

  .ps-lg-4 {
    padding-left: 1rem !important;
  }

  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }

  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 2rem !important;
  }

  .ps-lg-8 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-9 {
    padding-left: 3rem !important;
  }

  .ps-lg-10 {
    padding-left: 4rem !important;
  }

  .ps-lg-11 {
    padding-left: 5rem !important;
  }

  .ps-lg-12 {
    padding-left: 6rem !important;
  }

  .ps-lg-13 {
    padding-left: 8rem !important;
  }

  .ps-lg-14 {
    padding-left: 10rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 0.75rem !important;
  }

  .gap-lg-4 {
    gap: 1rem !important;
  }

  .gap-lg-5 {
    gap: 1.25rem !important;
  }

  .gap-lg-6 {
    gap: 1.5rem !important;
  }

  .gap-lg-7 {
    gap: 2rem !important;
  }

  .gap-lg-8 {
    gap: 2.5rem !important;
  }

  .gap-lg-9 {
    gap: 3rem !important;
  }

  .gap-lg-10 {
    gap: 4rem !important;
  }

  .gap-lg-11 {
    gap: 5rem !important;
  }

  .gap-lg-12 {
    gap: 6rem !important;
  }

  .gap-lg-13 {
    gap: 8rem !important;
  }

  .gap-lg-14 {
    gap: 10rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-lg-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-7 {
    row-gap: 2rem !important;
  }

  .row-gap-lg-8 {
    row-gap: 2.5rem !important;
  }

  .row-gap-lg-9 {
    row-gap: 3rem !important;
  }

  .row-gap-lg-10 {
    row-gap: 4rem !important;
  }

  .row-gap-lg-11 {
    row-gap: 5rem !important;
  }

  .row-gap-lg-12 {
    row-gap: 6rem !important;
  }

  .row-gap-lg-13 {
    row-gap: 8rem !important;
  }

  .row-gap-lg-14 {
    row-gap: 10rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-lg-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-7 {
    column-gap: 2rem !important;
  }

  .column-gap-lg-8 {
    column-gap: 2.5rem !important;
  }

  .column-gap-lg-9 {
    column-gap: 3rem !important;
  }

  .column-gap-lg-10 {
    column-gap: 4rem !important;
  }

  .column-gap-lg-11 {
    column-gap: 5rem !important;
  }

  .column-gap-lg-12 {
    column-gap: 6rem !important;
  }

  .column-gap-lg-13 {
    column-gap: 8rem !important;
  }

  .column-gap-lg-14 {
    column-gap: 10rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .wpx-lg-25 {
    width: 25px !important;
  }

  .wpx-lg-50 {
    width: 50px !important;
  }

  .wpx-lg-75 {
    width: 75px !important;
  }

  .wpx-lg-100 {
    width: 100px !important;
  }

  .wpx-lg-125 {
    width: 125px !important;
  }

  .wpx-lg-150 {
    width: 150px !important;
  }

  .wpx-lg-175 {
    width: 175px !important;
  }

  .wpx-lg-200 {
    width: 200px !important;
  }

  .wpx-lg-225 {
    width: 225px !important;
  }

  .wpx-lg-250 {
    width: 250px !important;
  }

  .wpx-lg-275 {
    width: 275px !important;
  }

  .wpx-lg-300 {
    width: 300px !important;
  }

  .wpx-lg-325 {
    width: 325px !important;
  }

  .wpx-lg-350 {
    width: 350px !important;
  }

  .wpx-lg-375 {
    width: 375px !important;
  }

  .wpx-lg-400 {
    width: 400px !important;
  }

  .wpx-lg-425 {
    width: 425px !important;
  }

  .wpx-lg-450 {
    width: 450px !important;
  }

  .wpx-lg-475 {
    width: 475px !important;
  }

  .wpx-lg-500 {
    width: 500px !important;
  }

  .wpx-lg-525 {
    width: 525px !important;
  }

  .wpx-lg-550 {
    width: 550px !important;
  }

  .wpx-lg-575 {
    width: 575px !important;
  }

  .wpx-lg-600 {
    width: 600px !important;
  }

  .wpx-lg-625 {
    width: 625px !important;
  }

  .wpx-lg-650 {
    width: 650px !important;
  }

  .wpx-lg-675 {
    width: 675px !important;
  }

  .wpx-lg-700 {
    width: 700px !important;
  }

  .wpx-lg-725 {
    width: 725px !important;
  }

  .wpx-lg-750 {
    width: 750px !important;
  }

  .wpx-lg-775 {
    width: 775px !important;
  }

  .wpx-lg-800 {
    width: 800px !important;
  }

  .wpx-lg-825 {
    width: 825px !important;
  }

  .wpx-lg-850 {
    width: 850px !important;
  }

  .wpx-lg-875 {
    width: 875px !important;
  }

  .wpx-lg-900 {
    width: 900px !important;
  }

  .wpx-lg-925 {
    width: 925px !important;
  }

  .wpx-lg-950 {
    width: 950px !important;
  }

  .wpx-lg-975 {
    width: 975px !important;
  }

  .hpx-lg-25 {
    height: 25px !important;
  }

  .hpx-lg-50 {
    height: 50px !important;
  }

  .hpx-lg-75 {
    height: 75px !important;
  }

  .hpx-lg-100 {
    height: 100px !important;
  }

  .hpx-lg-125 {
    height: 125px !important;
  }

  .hpx-lg-150 {
    height: 150px !important;
  }

  .hpx-lg-175 {
    height: 175px !important;
  }

  .hpx-lg-200 {
    height: 200px !important;
  }

  .hpx-lg-225 {
    height: 225px !important;
  }

  .hpx-lg-250 {
    height: 250px !important;
  }

  .hpx-lg-275 {
    height: 275px !important;
  }

  .hpx-lg-300 {
    height: 300px !important;
  }

  .hpx-lg-325 {
    height: 325px !important;
  }

  .hpx-lg-350 {
    height: 350px !important;
  }

  .hpx-lg-375 {
    height: 375px !important;
  }

  .hpx-lg-400 {
    height: 400px !important;
  }

  .hpx-lg-425 {
    height: 425px !important;
  }

  .hpx-lg-450 {
    height: 450px !important;
  }

  .hpx-lg-475 {
    height: 475px !important;
  }

  .hpx-lg-500 {
    height: 500px !important;
  }

  .hpx-lg-525 {
    height: 525px !important;
  }

  .hpx-lg-550 {
    height: 550px !important;
  }

  .hpx-lg-575 {
    height: 575px !important;
  }

  .hpx-lg-600 {
    height: 600px !important;
  }

  .hpx-lg-625 {
    height: 625px !important;
  }

  .hpx-lg-650 {
    height: 650px !important;
  }

  .hpx-lg-675 {
    height: 675px !important;
  }

  .hpx-lg-700 {
    height: 700px !important;
  }

  .hpx-lg-725 {
    height: 725px !important;
  }

  .hpx-lg-750 {
    height: 750px !important;
  }

  .hpx-lg-775 {
    height: 775px !important;
  }

  .hpx-lg-800 {
    height: 800px !important;
  }

  .hpx-lg-825 {
    height: 825px !important;
  }

  .hpx-lg-850 {
    height: 850px !important;
  }

  .hpx-lg-875 {
    height: 875px !important;
  }

  .hpx-lg-900 {
    height: 900px !important;
  }

  .hpx-lg-925 {
    height: 925px !important;
  }

  .hpx-lg-950 {
    height: 950px !important;
  }

  .hpx-lg-975 {
    height: 975px !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 0.75rem !important;
  }

  .m-xl-4 {
    margin: 1rem !important;
  }

  .m-xl-5 {
    margin: 1.25rem !important;
  }

  .m-xl-6 {
    margin: 1.5rem !important;
  }

  .m-xl-7 {
    margin: 2rem !important;
  }

  .m-xl-8 {
    margin: 2.5rem !important;
  }

  .m-xl-9 {
    margin: 3rem !important;
  }

  .m-xl-10 {
    margin: 4rem !important;
  }

  .m-xl-11 {
    margin: 5rem !important;
  }

  .m-xl-12 {
    margin: 6rem !important;
  }

  .m-xl-13 {
    margin: 8rem !important;
  }

  .m-xl-14 {
    margin: 10rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-9 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-10 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-12 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xl-13 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xl-14 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-7 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-9 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-10 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-11 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-12 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-13 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xl-14 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xl-4 {
    margin-top: 1rem !important;
  }

  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 2rem !important;
  }

  .mt-xl-8 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-9 {
    margin-top: 3rem !important;
  }

  .mt-xl-10 {
    margin-top: 4rem !important;
  }

  .mt-xl-11 {
    margin-top: 5rem !important;
  }

  .mt-xl-12 {
    margin-top: 6rem !important;
  }

  .mt-xl-13 {
    margin-top: 8rem !important;
  }

  .mt-xl-14 {
    margin-top: 10rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xl-4 {
    margin-right: 1rem !important;
  }

  .me-xl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xl-7 {
    margin-right: 2rem !important;
  }

  .me-xl-8 {
    margin-right: 2.5rem !important;
  }

  .me-xl-9 {
    margin-right: 3rem !important;
  }

  .me-xl-10 {
    margin-right: 4rem !important;
  }

  .me-xl-11 {
    margin-right: 5rem !important;
  }

  .me-xl-12 {
    margin-right: 6rem !important;
  }

  .me-xl-13 {
    margin-right: 8rem !important;
  }

  .me-xl-14 {
    margin-right: 10rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 8rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 10rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xl-4 {
    margin-left: 1rem !important;
  }

  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 2rem !important;
  }

  .ms-xl-8 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-9 {
    margin-left: 3rem !important;
  }

  .ms-xl-10 {
    margin-left: 4rem !important;
  }

  .ms-xl-11 {
    margin-left: 5rem !important;
  }

  .ms-xl-12 {
    margin-left: 6rem !important;
  }

  .ms-xl-13 {
    margin-left: 8rem !important;
  }

  .ms-xl-14 {
    margin-left: 10rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -0.75rem !important;
  }

  .m-xl-n4 {
    margin: -1rem !important;
  }

  .m-xl-n5 {
    margin: -1.25rem !important;
  }

  .m-xl-n6 {
    margin: -1.5rem !important;
  }

  .m-xl-n7 {
    margin: -2rem !important;
  }

  .m-xl-n8 {
    margin: -2.5rem !important;
  }

  .m-xl-n9 {
    margin: -3rem !important;
  }

  .m-xl-n10 {
    margin: -4rem !important;
  }

  .m-xl-n11 {
    margin: -5rem !important;
  }

  .m-xl-n12 {
    margin: -6rem !important;
  }

  .m-xl-n13 {
    margin: -8rem !important;
  }

  .m-xl-n14 {
    margin: -10rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n7 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xl-n9 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n10 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xl-n11 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-xl-n12 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xl-n13 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-xl-n14 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n9 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n10 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n11 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xl-n12 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xl-n13 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xl-n14 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -2rem !important;
  }

  .mt-xl-n8 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n9 {
    margin-top: -3rem !important;
  }

  .mt-xl-n10 {
    margin-top: -4rem !important;
  }

  .mt-xl-n11 {
    margin-top: -5rem !important;
  }

  .mt-xl-n12 {
    margin-top: -6rem !important;
  }

  .mt-xl-n13 {
    margin-top: -8rem !important;
  }

  .mt-xl-n14 {
    margin-top: -10rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xl-n4 {
    margin-right: -1rem !important;
  }

  .me-xl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -2rem !important;
  }

  .me-xl-n8 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n9 {
    margin-right: -3rem !important;
  }

  .me-xl-n10 {
    margin-right: -4rem !important;
  }

  .me-xl-n11 {
    margin-right: -5rem !important;
  }

  .me-xl-n12 {
    margin-right: -6rem !important;
  }

  .me-xl-n13 {
    margin-right: -8rem !important;
  }

  .me-xl-n14 {
    margin-right: -10rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -5rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -6rem !important;
  }

  .mb-xl-n13 {
    margin-bottom: -8rem !important;
  }

  .mb-xl-n14 {
    margin-bottom: -10rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -2rem !important;
  }

  .ms-xl-n8 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n9 {
    margin-left: -3rem !important;
  }

  .ms-xl-n10 {
    margin-left: -4rem !important;
  }

  .ms-xl-n11 {
    margin-left: -5rem !important;
  }

  .ms-xl-n12 {
    margin-left: -6rem !important;
  }

  .ms-xl-n13 {
    margin-left: -8rem !important;
  }

  .ms-xl-n14 {
    margin-left: -10rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 0.75rem !important;
  }

  .p-xl-4 {
    padding: 1rem !important;
  }

  .p-xl-5 {
    padding: 1.25rem !important;
  }

  .p-xl-6 {
    padding: 1.5rem !important;
  }

  .p-xl-7 {
    padding: 2rem !important;
  }

  .p-xl-8 {
    padding: 2.5rem !important;
  }

  .p-xl-9 {
    padding: 3rem !important;
  }

  .p-xl-10 {
    padding: 4rem !important;
  }

  .p-xl-11 {
    padding: 5rem !important;
  }

  .p-xl-12 {
    padding: 6rem !important;
  }

  .p-xl-13 {
    padding: 8rem !important;
  }

  .p-xl-14 {
    padding: 10rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-7 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-9 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-10 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-11 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xl-12 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xl-13 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xl-14 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-7 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-9 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-10 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xl-12 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xl-13 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xl-14 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xl-4 {
    padding-top: 1rem !important;
  }

  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 2rem !important;
  }

  .pt-xl-8 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-9 {
    padding-top: 3rem !important;
  }

  .pt-xl-10 {
    padding-top: 4rem !important;
  }

  .pt-xl-11 {
    padding-top: 5rem !important;
  }

  .pt-xl-12 {
    padding-top: 6rem !important;
  }

  .pt-xl-13 {
    padding-top: 8rem !important;
  }

  .pt-xl-14 {
    padding-top: 10rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xl-4 {
    padding-right: 1rem !important;
  }

  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 2rem !important;
  }

  .pe-xl-8 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-9 {
    padding-right: 3rem !important;
  }

  .pe-xl-10 {
    padding-right: 4rem !important;
  }

  .pe-xl-11 {
    padding-right: 5rem !important;
  }

  .pe-xl-12 {
    padding-right: 6rem !important;
  }

  .pe-xl-13 {
    padding-right: 8rem !important;
  }

  .pe-xl-14 {
    padding-right: 10rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 5rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 6rem !important;
  }

  .pb-xl-13 {
    padding-bottom: 8rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 10rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xl-4 {
    padding-left: 1rem !important;
  }

  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 2rem !important;
  }

  .ps-xl-8 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-9 {
    padding-left: 3rem !important;
  }

  .ps-xl-10 {
    padding-left: 4rem !important;
  }

  .ps-xl-11 {
    padding-left: 5rem !important;
  }

  .ps-xl-12 {
    padding-left: 6rem !important;
  }

  .ps-xl-13 {
    padding-left: 8rem !important;
  }

  .ps-xl-14 {
    padding-left: 10rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 0.75rem !important;
  }

  .gap-xl-4 {
    gap: 1rem !important;
  }

  .gap-xl-5 {
    gap: 1.25rem !important;
  }

  .gap-xl-6 {
    gap: 1.5rem !important;
  }

  .gap-xl-7 {
    gap: 2rem !important;
  }

  .gap-xl-8 {
    gap: 2.5rem !important;
  }

  .gap-xl-9 {
    gap: 3rem !important;
  }

  .gap-xl-10 {
    gap: 4rem !important;
  }

  .gap-xl-11 {
    gap: 5rem !important;
  }

  .gap-xl-12 {
    gap: 6rem !important;
  }

  .gap-xl-13 {
    gap: 8rem !important;
  }

  .gap-xl-14 {
    gap: 10rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-xl-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-7 {
    row-gap: 2rem !important;
  }

  .row-gap-xl-8 {
    row-gap: 2.5rem !important;
  }

  .row-gap-xl-9 {
    row-gap: 3rem !important;
  }

  .row-gap-xl-10 {
    row-gap: 4rem !important;
  }

  .row-gap-xl-11 {
    row-gap: 5rem !important;
  }

  .row-gap-xl-12 {
    row-gap: 6rem !important;
  }

  .row-gap-xl-13 {
    row-gap: 8rem !important;
  }

  .row-gap-xl-14 {
    row-gap: 10rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-xl-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-7 {
    column-gap: 2rem !important;
  }

  .column-gap-xl-8 {
    column-gap: 2.5rem !important;
  }

  .column-gap-xl-9 {
    column-gap: 3rem !important;
  }

  .column-gap-xl-10 {
    column-gap: 4rem !important;
  }

  .column-gap-xl-11 {
    column-gap: 5rem !important;
  }

  .column-gap-xl-12 {
    column-gap: 6rem !important;
  }

  .column-gap-xl-13 {
    column-gap: 8rem !important;
  }

  .column-gap-xl-14 {
    column-gap: 10rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .wpx-xl-25 {
    width: 25px !important;
  }

  .wpx-xl-50 {
    width: 50px !important;
  }

  .wpx-xl-75 {
    width: 75px !important;
  }

  .wpx-xl-100 {
    width: 100px !important;
  }

  .wpx-xl-125 {
    width: 125px !important;
  }

  .wpx-xl-150 {
    width: 150px !important;
  }

  .wpx-xl-175 {
    width: 175px !important;
  }

  .wpx-xl-200 {
    width: 200px !important;
  }

  .wpx-xl-225 {
    width: 225px !important;
  }

  .wpx-xl-250 {
    width: 250px !important;
  }

  .wpx-xl-275 {
    width: 275px !important;
  }

  .wpx-xl-300 {
    width: 300px !important;
  }

  .wpx-xl-325 {
    width: 325px !important;
  }

  .wpx-xl-350 {
    width: 350px !important;
  }

  .wpx-xl-375 {
    width: 375px !important;
  }

  .wpx-xl-400 {
    width: 400px !important;
  }

  .wpx-xl-425 {
    width: 425px !important;
  }

  .wpx-xl-450 {
    width: 450px !important;
  }

  .wpx-xl-475 {
    width: 475px !important;
  }

  .wpx-xl-500 {
    width: 500px !important;
  }

  .wpx-xl-525 {
    width: 525px !important;
  }

  .wpx-xl-550 {
    width: 550px !important;
  }

  .wpx-xl-575 {
    width: 575px !important;
  }

  .wpx-xl-600 {
    width: 600px !important;
  }

  .wpx-xl-625 {
    width: 625px !important;
  }

  .wpx-xl-650 {
    width: 650px !important;
  }

  .wpx-xl-675 {
    width: 675px !important;
  }

  .wpx-xl-700 {
    width: 700px !important;
  }

  .wpx-xl-725 {
    width: 725px !important;
  }

  .wpx-xl-750 {
    width: 750px !important;
  }

  .wpx-xl-775 {
    width: 775px !important;
  }

  .wpx-xl-800 {
    width: 800px !important;
  }

  .wpx-xl-825 {
    width: 825px !important;
  }

  .wpx-xl-850 {
    width: 850px !important;
  }

  .wpx-xl-875 {
    width: 875px !important;
  }

  .wpx-xl-900 {
    width: 900px !important;
  }

  .wpx-xl-925 {
    width: 925px !important;
  }

  .wpx-xl-950 {
    width: 950px !important;
  }

  .wpx-xl-975 {
    width: 975px !important;
  }

  .hpx-xl-25 {
    height: 25px !important;
  }

  .hpx-xl-50 {
    height: 50px !important;
  }

  .hpx-xl-75 {
    height: 75px !important;
  }

  .hpx-xl-100 {
    height: 100px !important;
  }

  .hpx-xl-125 {
    height: 125px !important;
  }

  .hpx-xl-150 {
    height: 150px !important;
  }

  .hpx-xl-175 {
    height: 175px !important;
  }

  .hpx-xl-200 {
    height: 200px !important;
  }

  .hpx-xl-225 {
    height: 225px !important;
  }

  .hpx-xl-250 {
    height: 250px !important;
  }

  .hpx-xl-275 {
    height: 275px !important;
  }

  .hpx-xl-300 {
    height: 300px !important;
  }

  .hpx-xl-325 {
    height: 325px !important;
  }

  .hpx-xl-350 {
    height: 350px !important;
  }

  .hpx-xl-375 {
    height: 375px !important;
  }

  .hpx-xl-400 {
    height: 400px !important;
  }

  .hpx-xl-425 {
    height: 425px !important;
  }

  .hpx-xl-450 {
    height: 450px !important;
  }

  .hpx-xl-475 {
    height: 475px !important;
  }

  .hpx-xl-500 {
    height: 500px !important;
  }

  .hpx-xl-525 {
    height: 525px !important;
  }

  .hpx-xl-550 {
    height: 550px !important;
  }

  .hpx-xl-575 {
    height: 575px !important;
  }

  .hpx-xl-600 {
    height: 600px !important;
  }

  .hpx-xl-625 {
    height: 625px !important;
  }

  .hpx-xl-650 {
    height: 650px !important;
  }

  .hpx-xl-675 {
    height: 675px !important;
  }

  .hpx-xl-700 {
    height: 700px !important;
  }

  .hpx-xl-725 {
    height: 725px !important;
  }

  .hpx-xl-750 {
    height: 750px !important;
  }

  .hpx-xl-775 {
    height: 775px !important;
  }

  .hpx-xl-800 {
    height: 800px !important;
  }

  .hpx-xl-825 {
    height: 825px !important;
  }

  .hpx-xl-850 {
    height: 850px !important;
  }

  .hpx-xl-875 {
    height: 875px !important;
  }

  .hpx-xl-900 {
    height: 900px !important;
  }

  .hpx-xl-925 {
    height: 925px !important;
  }

  .hpx-xl-950 {
    height: 950px !important;
  }

  .hpx-xl-975 {
    height: 975px !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 0.75rem !important;
  }

  .m-xxl-4 {
    margin: 1rem !important;
  }

  .m-xxl-5 {
    margin: 1.25rem !important;
  }

  .m-xxl-6 {
    margin: 1.5rem !important;
  }

  .m-xxl-7 {
    margin: 2rem !important;
  }

  .m-xxl-8 {
    margin: 2.5rem !important;
  }

  .m-xxl-9 {
    margin: 3rem !important;
  }

  .m-xxl-10 {
    margin: 4rem !important;
  }

  .m-xxl-11 {
    margin: 5rem !important;
  }

  .m-xxl-12 {
    margin: 6rem !important;
  }

  .m-xxl-13 {
    margin: 8rem !important;
  }

  .m-xxl-14 {
    margin: 10rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xxl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-9 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-10 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-12 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xxl-13 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xxl-14 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xxl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-9 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-10 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-11 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-12 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xxl-13 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xxl-14 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1rem !important;
  }

  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 2rem !important;
  }

  .mt-xxl-8 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-9 {
    margin-top: 3rem !important;
  }

  .mt-xxl-10 {
    margin-top: 4rem !important;
  }

  .mt-xxl-11 {
    margin-top: 5rem !important;
  }

  .mt-xxl-12 {
    margin-top: 6rem !important;
  }

  .mt-xxl-13 {
    margin-top: 8rem !important;
  }

  .mt-xxl-14 {
    margin-top: 10rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xxl-4 {
    margin-right: 1rem !important;
  }

  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 2rem !important;
  }

  .me-xxl-8 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-9 {
    margin-right: 3rem !important;
  }

  .me-xxl-10 {
    margin-right: 4rem !important;
  }

  .me-xxl-11 {
    margin-right: 5rem !important;
  }

  .me-xxl-12 {
    margin-right: 6rem !important;
  }

  .me-xxl-13 {
    margin-right: 8rem !important;
  }

  .me-xxl-14 {
    margin-right: 10rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 8rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 10rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1rem !important;
  }

  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 2rem !important;
  }

  .ms-xxl-8 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-9 {
    margin-left: 3rem !important;
  }

  .ms-xxl-10 {
    margin-left: 4rem !important;
  }

  .ms-xxl-11 {
    margin-left: 5rem !important;
  }

  .ms-xxl-12 {
    margin-left: 6rem !important;
  }

  .ms-xxl-13 {
    margin-left: 8rem !important;
  }

  .ms-xxl-14 {
    margin-left: 10rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -0.75rem !important;
  }

  .m-xxl-n4 {
    margin: -1rem !important;
  }

  .m-xxl-n5 {
    margin: -1.25rem !important;
  }

  .m-xxl-n6 {
    margin: -1.5rem !important;
  }

  .m-xxl-n7 {
    margin: -2rem !important;
  }

  .m-xxl-n8 {
    margin: -2.5rem !important;
  }

  .m-xxl-n9 {
    margin: -3rem !important;
  }

  .m-xxl-n10 {
    margin: -4rem !important;
  }

  .m-xxl-n11 {
    margin: -5rem !important;
  }

  .m-xxl-n12 {
    margin: -6rem !important;
  }

  .m-xxl-n13 {
    margin: -8rem !important;
  }

  .m-xxl-n14 {
    margin: -10rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xxl-n11 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-xxl-n12 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xxl-n13 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-xxl-n14 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xxl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n9 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n10 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n11 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xxl-n12 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xxl-n13 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xxl-n14 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -5rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -6rem !important;
  }

  .mt-xxl-n13 {
    margin-top: -8rem !important;
  }

  .mt-xxl-n14 {
    margin-top: -10rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1rem !important;
  }

  .me-xxl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xxl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -2rem !important;
  }

  .me-xxl-n8 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n9 {
    margin-right: -3rem !important;
  }

  .me-xxl-n10 {
    margin-right: -4rem !important;
  }

  .me-xxl-n11 {
    margin-right: -5rem !important;
  }

  .me-xxl-n12 {
    margin-right: -6rem !important;
  }

  .me-xxl-n13 {
    margin-right: -8rem !important;
  }

  .me-xxl-n14 {
    margin-right: -10rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -5rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -6rem !important;
  }

  .mb-xxl-n13 {
    margin-bottom: -8rem !important;
  }

  .mb-xxl-n14 {
    margin-bottom: -10rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n11 {
    margin-left: -5rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -6rem !important;
  }

  .ms-xxl-n13 {
    margin-left: -8rem !important;
  }

  .ms-xxl-n14 {
    margin-left: -10rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 0.75rem !important;
  }

  .p-xxl-4 {
    padding: 1rem !important;
  }

  .p-xxl-5 {
    padding: 1.25rem !important;
  }

  .p-xxl-6 {
    padding: 1.5rem !important;
  }

  .p-xxl-7 {
    padding: 2rem !important;
  }

  .p-xxl-8 {
    padding: 2.5rem !important;
  }

  .p-xxl-9 {
    padding: 3rem !important;
  }

  .p-xxl-10 {
    padding: 4rem !important;
  }

  .p-xxl-11 {
    padding: 5rem !important;
  }

  .p-xxl-12 {
    padding: 6rem !important;
  }

  .p-xxl-13 {
    padding: 8rem !important;
  }

  .p-xxl-14 {
    padding: 10rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xxl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xxl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-9 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-10 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-11 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xxl-12 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xxl-13 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xxl-14 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xxl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-9 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-10 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xxl-12 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xxl-13 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xxl-14 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1rem !important;
  }

  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 2rem !important;
  }

  .pt-xxl-8 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-9 {
    padding-top: 3rem !important;
  }

  .pt-xxl-10 {
    padding-top: 4rem !important;
  }

  .pt-xxl-11 {
    padding-top: 5rem !important;
  }

  .pt-xxl-12 {
    padding-top: 6rem !important;
  }

  .pt-xxl-13 {
    padding-top: 8rem !important;
  }

  .pt-xxl-14 {
    padding-top: 10rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1rem !important;
  }

  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 2rem !important;
  }

  .pe-xxl-8 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-9 {
    padding-right: 3rem !important;
  }

  .pe-xxl-10 {
    padding-right: 4rem !important;
  }

  .pe-xxl-11 {
    padding-right: 5rem !important;
  }

  .pe-xxl-12 {
    padding-right: 6rem !important;
  }

  .pe-xxl-13 {
    padding-right: 8rem !important;
  }

  .pe-xxl-14 {
    padding-right: 10rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 5rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 6rem !important;
  }

  .pb-xxl-13 {
    padding-bottom: 8rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 10rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1rem !important;
  }

  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 2rem !important;
  }

  .ps-xxl-8 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-9 {
    padding-left: 3rem !important;
  }

  .ps-xxl-10 {
    padding-left: 4rem !important;
  }

  .ps-xxl-11 {
    padding-left: 5rem !important;
  }

  .ps-xxl-12 {
    padding-left: 6rem !important;
  }

  .ps-xxl-13 {
    padding-left: 8rem !important;
  }

  .ps-xxl-14 {
    padding-left: 10rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 0.75rem !important;
  }

  .gap-xxl-4 {
    gap: 1rem !important;
  }

  .gap-xxl-5 {
    gap: 1.25rem !important;
  }

  .gap-xxl-6 {
    gap: 1.5rem !important;
  }

  .gap-xxl-7 {
    gap: 2rem !important;
  }

  .gap-xxl-8 {
    gap: 2.5rem !important;
  }

  .gap-xxl-9 {
    gap: 3rem !important;
  }

  .gap-xxl-10 {
    gap: 4rem !important;
  }

  .gap-xxl-11 {
    gap: 5rem !important;
  }

  .gap-xxl-12 {
    gap: 6rem !important;
  }

  .gap-xxl-13 {
    gap: 8rem !important;
  }

  .gap-xxl-14 {
    gap: 10rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-xxl-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-7 {
    row-gap: 2rem !important;
  }

  .row-gap-xxl-8 {
    row-gap: 2.5rem !important;
  }

  .row-gap-xxl-9 {
    row-gap: 3rem !important;
  }

  .row-gap-xxl-10 {
    row-gap: 4rem !important;
  }

  .row-gap-xxl-11 {
    row-gap: 5rem !important;
  }

  .row-gap-xxl-12 {
    row-gap: 6rem !important;
  }

  .row-gap-xxl-13 {
    row-gap: 8rem !important;
  }

  .row-gap-xxl-14 {
    row-gap: 10rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-xxl-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-7 {
    column-gap: 2rem !important;
  }

  .column-gap-xxl-8 {
    column-gap: 2.5rem !important;
  }

  .column-gap-xxl-9 {
    column-gap: 3rem !important;
  }

  .column-gap-xxl-10 {
    column-gap: 4rem !important;
  }

  .column-gap-xxl-11 {
    column-gap: 5rem !important;
  }

  .column-gap-xxl-12 {
    column-gap: 6rem !important;
  }

  .column-gap-xxl-13 {
    column-gap: 8rem !important;
  }

  .column-gap-xxl-14 {
    column-gap: 10rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .wpx-xxl-25 {
    width: 25px !important;
  }

  .wpx-xxl-50 {
    width: 50px !important;
  }

  .wpx-xxl-75 {
    width: 75px !important;
  }

  .wpx-xxl-100 {
    width: 100px !important;
  }

  .wpx-xxl-125 {
    width: 125px !important;
  }

  .wpx-xxl-150 {
    width: 150px !important;
  }

  .wpx-xxl-175 {
    width: 175px !important;
  }

  .wpx-xxl-200 {
    width: 200px !important;
  }

  .wpx-xxl-225 {
    width: 225px !important;
  }

  .wpx-xxl-250 {
    width: 250px !important;
  }

  .wpx-xxl-275 {
    width: 275px !important;
  }

  .wpx-xxl-300 {
    width: 300px !important;
  }

  .wpx-xxl-325 {
    width: 325px !important;
  }

  .wpx-xxl-350 {
    width: 350px !important;
  }

  .wpx-xxl-375 {
    width: 375px !important;
  }

  .wpx-xxl-400 {
    width: 400px !important;
  }

  .wpx-xxl-425 {
    width: 425px !important;
  }

  .wpx-xxl-450 {
    width: 450px !important;
  }

  .wpx-xxl-475 {
    width: 475px !important;
  }

  .wpx-xxl-500 {
    width: 500px !important;
  }

  .wpx-xxl-525 {
    width: 525px !important;
  }

  .wpx-xxl-550 {
    width: 550px !important;
  }

  .wpx-xxl-575 {
    width: 575px !important;
  }

  .wpx-xxl-600 {
    width: 600px !important;
  }

  .wpx-xxl-625 {
    width: 625px !important;
  }

  .wpx-xxl-650 {
    width: 650px !important;
  }

  .wpx-xxl-675 {
    width: 675px !important;
  }

  .wpx-xxl-700 {
    width: 700px !important;
  }

  .wpx-xxl-725 {
    width: 725px !important;
  }

  .wpx-xxl-750 {
    width: 750px !important;
  }

  .wpx-xxl-775 {
    width: 775px !important;
  }

  .wpx-xxl-800 {
    width: 800px !important;
  }

  .wpx-xxl-825 {
    width: 825px !important;
  }

  .wpx-xxl-850 {
    width: 850px !important;
  }

  .wpx-xxl-875 {
    width: 875px !important;
  }

  .wpx-xxl-900 {
    width: 900px !important;
  }

  .wpx-xxl-925 {
    width: 925px !important;
  }

  .wpx-xxl-950 {
    width: 950px !important;
  }

  .wpx-xxl-975 {
    width: 975px !important;
  }

  .hpx-xxl-25 {
    height: 25px !important;
  }

  .hpx-xxl-50 {
    height: 50px !important;
  }

  .hpx-xxl-75 {
    height: 75px !important;
  }

  .hpx-xxl-100 {
    height: 100px !important;
  }

  .hpx-xxl-125 {
    height: 125px !important;
  }

  .hpx-xxl-150 {
    height: 150px !important;
  }

  .hpx-xxl-175 {
    height: 175px !important;
  }

  .hpx-xxl-200 {
    height: 200px !important;
  }

  .hpx-xxl-225 {
    height: 225px !important;
  }

  .hpx-xxl-250 {
    height: 250px !important;
  }

  .hpx-xxl-275 {
    height: 275px !important;
  }

  .hpx-xxl-300 {
    height: 300px !important;
  }

  .hpx-xxl-325 {
    height: 325px !important;
  }

  .hpx-xxl-350 {
    height: 350px !important;
  }

  .hpx-xxl-375 {
    height: 375px !important;
  }

  .hpx-xxl-400 {
    height: 400px !important;
  }

  .hpx-xxl-425 {
    height: 425px !important;
  }

  .hpx-xxl-450 {
    height: 450px !important;
  }

  .hpx-xxl-475 {
    height: 475px !important;
  }

  .hpx-xxl-500 {
    height: 500px !important;
  }

  .hpx-xxl-525 {
    height: 525px !important;
  }

  .hpx-xxl-550 {
    height: 550px !important;
  }

  .hpx-xxl-575 {
    height: 575px !important;
  }

  .hpx-xxl-600 {
    height: 600px !important;
  }

  .hpx-xxl-625 {
    height: 625px !important;
  }

  .hpx-xxl-650 {
    height: 650px !important;
  }

  .hpx-xxl-675 {
    height: 675px !important;
  }

  .hpx-xxl-700 {
    height: 700px !important;
  }

  .hpx-xxl-725 {
    height: 725px !important;
  }

  .hpx-xxl-750 {
    height: 750px !important;
  }

  .hpx-xxl-775 {
    height: 775px !important;
  }

  .hpx-xxl-800 {
    height: 800px !important;
  }

  .hpx-xxl-825 {
    height: 825px !important;
  }

  .hpx-xxl-850 {
    height: 850px !important;
  }

  .hpx-xxl-875 {
    height: 875px !important;
  }

  .hpx-xxl-900 {
    height: 900px !important;
  }

  .hpx-xxl-925 {
    height: 925px !important;
  }

  .hpx-xxl-950 {
    height: 950px !important;
  }

  .hpx-xxl-975 {
    height: 975px !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.alert-primary {
  --bs-alert-bg: rgba(var(--bs-primary-rgb), .075);
  --bs-alert-border-color: rgba(var(--bs-primary-rgb), .25);
}

.alert-secondary {
  --bs-alert-bg: rgba(var(--bs-secondary-rgb), .075);
  --bs-alert-border-color: rgba(var(--bs-secondary-rgb), .25);
}

.alert-success {
  --bs-alert-bg: rgba(var(--bs-success-rgb), .075);
  --bs-alert-border-color: rgba(var(--bs-success-rgb), .25);
}

.alert-info {
  --bs-alert-bg: rgba(var(--bs-info-rgb), .075);
  --bs-alert-border-color: rgba(var(--bs-info-rgb), .25);
}

.alert-warning {
  --bs-alert-bg: rgba(var(--bs-warning-rgb), .075);
  --bs-alert-border-color: rgba(var(--bs-warning-rgb), .25);
}

.alert-danger {
  --bs-alert-bg: rgba(var(--bs-danger-rgb), .075);
  --bs-alert-border-color: rgba(var(--bs-danger-rgb), .25);
}

.alert-light {
  --bs-alert-bg: rgba(var(--bs-light-rgb), .075);
  --bs-alert-border-color: rgba(var(--bs-light-rgb), .25);
}

.alert-dark {
  --bs-alert-bg: rgba(var(--bs-dark-rgb), .075);
  --bs-alert-border-color: rgba(var(--bs-dark-rgb), .25);
}

.badge.bg-pink {
  background-color: #EC4899;
  color: #FFF;
}
.badge.bg-pink-light {
  background-color: #fbdaeb;
  color: #8e2b5c;
  border: 1px solid #f9c8e0;
}
.badge.bg-fuchsia {
  background-color: #D946EF;
  color: #FFF;
}
.badge.bg-fuchsia-light {
  background-color: #f7dafc;
  color: #822a8f;
  border: 1px solid #f4c8fa;
}
.badge.bg-purple {
  background-color: #7F56D9;
  color: #FFF;
}
.badge.bg-purple-light {
  background-color: #e5ddf7;
  color: #4c3482;
  border: 1px solid #d9ccf4;
}
.badge.bg-indigo {
  background-color: #6366F1;
  color: #FFF;
}
.badge.bg-indigo-light {
  background-color: #e0e0fc;
  color: #3b3d91;
  border: 1px solid #d0d1fb;
}
.badge.bg-blue {
  background-color: #3B82F6;
  color: #FFF;
}
.badge.bg-blue-light {
  background-color: #d8e6fd;
  color: #234e94;
  border: 1px solid #c4dafc;
}
.badge.bg-cyan {
  background-color: #06B6D4;
  color: #FFF;
}
.badge.bg-cyan-light {
  background-color: #cdf0f6;
  color: #046d7f;
  border: 1px solid #b4e9f2;
}
.badge.bg-teal {
  background-color: #14B8A6;
  color: #FFF;
}
.badge.bg-teal-light {
  background-color: #d0f1ed;
  color: #0c6e64;
  border: 1px solid #b9eae4;
}
.badge.bg-green {
  background-color: #3CBF33;
  color: #FFF;
}
.badge.bg-green-light {
  background-color: #d8f2d6;
  color: #24731f;
  border: 1px solid #c5ecc2;
}
.badge.bg-lime {
  background-color: #84CC16;
  color: #FFF;
}
.badge.bg-lime-light {
  background-color: #e6f5d0;
  color: #4f7a0d;
  border: 1px solid #daf0b9;
}
.badge.bg-yellow {
  background-color: #FACC15;
  color: #FFF;
}
.badge.bg-yellow-light {
  background-color: #fef5d0;
  color: #967a0d;
  border: 1px solid #fef0b9;
}
.badge.bg-orange {
  background-color: #F97316;
  color: #FFF;
}
.badge.bg-orange-light {
  background-color: #fee3d0;
  color: #95450d;
  border: 1px solid #fdd5b9;
}
.badge.bg-red {
  background-color: #dc3545;
  color: #FFF;
}
.badge.bg-red-light {
  background-color: #f8d7da;
  color: #842029;
  border: 1px solid #f5c2c7;
}
.badge.bg-black {
  background-color: #000000;
  color: #FFF;
}
.badge.bg-black-light {
  background-color: #cccccc;
  color: black;
  border: 1px solid #b3b3b3;
}
.badge.bg-white {
  background-color: #FFF;
  color: #FFF;
}
.badge.bg-white-light {
  background-color: white;
  color: #999999;
  border: 1px solid white;
}
.badge.bg-gray {
  background-color: #475467;
  color: #FFF;
}
.badge.bg-gray-light {
  background-color: #dadde1;
  color: #2b323e;
  border: 1px solid #c8ccd1;
}
.badge.bg-gray-dark {
  background-color: #1D2939;
  color: #FFF;
}
.badge.bg-gray-dark-light {
  background-color: #d2d4d7;
  color: #111922;
  border: 1px solid #bbbfc4;
}
.badge.bg-slate {
  background-color: #3E5782;
  color: #FFF;
}
.badge.bg-slate-light {
  background-color: #d8dde6;
  color: #25344e;
  border: 1px solid #c5cdda;
}
.badge.bg-apple {
  background-color: #CBE007;
  color: #FFF;
}
.badge.bg-apple-light {
  background-color: #f5f9cd;
  color: #7a8604;
  border: 1px solid #eff6b5;
}
.badge.bg-amber {
  background-color: #FFB626;
  color: #FFF;
}
.badge.bg-amber-light {
  background-color: #fff0d4;
  color: #996d17;
  border: 1px solid #ffe9be;
}
.badge.bg-ruby {
  background-color: #D4304D;
  color: #FFF;
}
.badge.bg-ruby-light {
  background-color: #f6d6db;
  color: #7f1d2e;
  border: 1px solid #f2c1ca;
}
.badge.bg-coral {
  background-color: #FF6B5B;
  color: #FFF;
}
.badge.bg-coral-light {
  background-color: #ffe1de;
  color: #994037;
  border: 1px solid #ffd3ce;
}
.badge.bg-brown {
  background-color: #B45309;
  color: #FFF;
}
.badge.bg-brown-light {
  background-color: #f0ddce;
  color: #6c3205;
  border: 1px solid #e9cbb5;
}
.badge.bg-coffee {
  background-color: #7C2D12;
  color: #FFF;
}
.badge.bg-coffee-light {
  background-color: #e5d5d0;
  color: #4a1b0b;
  border: 1px solid #d8c0b8;
}
.badge.bg-primary-light {
  background-color: #d8ebfc;
  color: #235c90;
  border: 1px solid #c4e0fb;
}
.badge.bg-secondary-light {
  background-color: #e0e2e7;
  color: #3d4350;
  border: 1px solid #d1d4da;
}
.badge.bg-success-light {
  background-color: #d8f2d6;
  color: #24731f;
  border: 1px solid #c5ecc2;
}
.badge.bg-info-light {
  background-color: #d8e6fd;
  color: #234e94;
  border: 1px solid #c4dafc;
}
.badge.bg-warning-light {
  background-color: #fee3d0;
  color: #95450d;
  border: 1px solid #fdd5b9;
}
.badge.bg-danger-light {
  background-color: #f8d7da;
  color: #842029;
  border: 1px solid #f5c2c7;
}
.badge.bg-light-light {
  background-color: #fcfdfd;
  color: #919294;
  border: 1px solid #fbfcfd;
}
.badge.bg-dark-light {
  background-color: #cfd1d4;
  color: #0a0e18;
  border: 1px solid #b7babf;
}

.breadcrumb a {
  color: #616a7e;
}

.breadcrumb-item a {
  text-decoration: none;
}
.breadcrumb-item a:hover {
  text-decoration: underline;
}
.breadcrumb-item.active {
  flex-basis: 100%;
  padding-left: 0;
  font-weight: 600;
  color: #344054;
  font-size: 1rem;
}
.breadcrumb-item.active:before {
  display: none;
}

.btn-group .btn-primary ~ .btn-primary {
  margin-left: 1px !important;
}

.btn {
  white-space: nowrap;
}

.btn-secondary {
  --bs-btn-color: #344054;
  --bs-btn-bg: #FFF;
  --bs-btn-border-color: #D0D5DD;
  --bs-btn-hover-color: #344054;
  --bs-btn-hover-bg: #F9FAFB;
  --bs-btn-hover-border-color: #D0D5DD;
  --bs-btn-focus-shadow-rgb: 185, 191, 200;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: #d5d9e0;
  --bs-btn-active-shadow: inset 0 0 3px rgba(16, 24, 40, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #FFF;
  --bs-btn-disabled-border-color: #D0D5DD;
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #667085;
}
.btn-secondary.active {
  background-color: #EAECF0;
}

.card-header {
  border-bottom-width: 1px;
}

.card-footer {
  border-top-width: 1px;
}

.bg-pink {
  background-color: rgba(236, 72, 153, var(--bs-bg-opacity, 100)) !important;
}

.bg-pink-light {
  background-color: rgba(251, 218, 235, var(--bs-bg-opacity, 100)) !important;
}

.border-pink {
  --bs-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--bs-border-opacity, 100)) !important;
}

.text-pink {
  --bs-text-opacity: 1;
  color: rgba(236, 72, 153, var(--bs-text-opacity, 100)) !important;
}

.text-pink-emphasis {
  --bs-text-opacity: 1;
  color: rgba(189, 58, 122, var(--bs-text-opacity, 100)) !important;
}

.text-bg-pink {
  color: rgba(189, 58, 122, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(251, 218, 235, var(--bs-bg-opacity, 100)) !important;
}

.bg-pink-50 {
  background-color: #fdedf5 !important;
}

.bg-pink-100 {
  background-color: #fbdaeb !important;
}

.bg-pink-200 {
  background-color: #f7b6d6 !important;
}

.bg-pink-300 {
  background-color: #f491c2 !important;
}

.bg-pink-400 {
  background-color: #f06dad !important;
}

.bg-pink-500 {
  background-color: #EC4899 !important;
}

.bg-pink-600 {
  background-color: #bd3a7a !important;
}

.bg-pink-700 {
  background-color: #8e2b5c !important;
}

.bg-pink-800 {
  background-color: #5e1d3d !important;
}

.bg-pink-900 {
  background-color: #2f0e1f !important;
}

.border-pink-50 {
  border-color: #fdedf5 !important;
}

.border-pink-100 {
  border-color: #fbdaeb !important;
}

.border-pink-200 {
  border-color: #f7b6d6 !important;
}

.border-pink-300 {
  border-color: #f491c2 !important;
}

.border-pink-400 {
  border-color: #f06dad !important;
}

.border-pink-500 {
  border-color: #EC4899 !important;
}

.border-pink-600 {
  border-color: #bd3a7a !important;
}

.border-pink-700 {
  border-color: #8e2b5c !important;
}

.border-pink-800 {
  border-color: #5e1d3d !important;
}

.border-pink-900 {
  border-color: #2f0e1f !important;
}

.text-pink-50 {
  color: #fdedf5 !important;
}

.text-pink-100 {
  color: #fbdaeb !important;
}

.text-pink-200 {
  color: #f7b6d6 !important;
}

.text-pink-300 {
  color: #f491c2 !important;
}

.text-pink-400 {
  color: #f06dad !important;
}

.text-pink-500 {
  color: #EC4899 !important;
}

.text-pink-600 {
  color: #bd3a7a !important;
}

.text-pink-700 {
  color: #8e2b5c !important;
}

.text-pink-800 {
  color: #5e1d3d !important;
}

.text-pink-900 {
  color: #2f0e1f !important;
}

.bg-fuchsia {
  background-color: rgba(217, 70, 239, var(--bs-bg-opacity, 100)) !important;
}

.bg-fuchsia-light {
  background-color: rgba(247, 218, 252, var(--bs-bg-opacity, 100)) !important;
}

.border-fuchsia {
  --bs-border-opacity: 1;
  border-color: rgba(217, 70, 239, var(--bs-border-opacity, 100)) !important;
}

.text-fuchsia {
  --bs-text-opacity: 1;
  color: rgba(217, 70, 239, var(--bs-text-opacity, 100)) !important;
}

.text-fuchsia-emphasis {
  --bs-text-opacity: 1;
  color: rgba(174, 56, 191, var(--bs-text-opacity, 100)) !important;
}

.text-bg-fuchsia {
  color: rgba(174, 56, 191, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(247, 218, 252, var(--bs-bg-opacity, 100)) !important;
}

.bg-fuchsia-50 {
  background-color: #fbedfd !important;
}

.bg-fuchsia-100 {
  background-color: #f7dafc !important;
}

.bg-fuchsia-200 {
  background-color: #f0b5f9 !important;
}

.bg-fuchsia-300 {
  background-color: #e890f5 !important;
}

.bg-fuchsia-400 {
  background-color: #e16bf2 !important;
}

.bg-fuchsia-500 {
  background-color: #D946EF !important;
}

.bg-fuchsia-600 {
  background-color: #ae38bf !important;
}

.bg-fuchsia-700 {
  background-color: #822a8f !important;
}

.bg-fuchsia-800 {
  background-color: #571c60 !important;
}

.bg-fuchsia-900 {
  background-color: #2b0e30 !important;
}

.border-fuchsia-50 {
  border-color: #fbedfd !important;
}

.border-fuchsia-100 {
  border-color: #f7dafc !important;
}

.border-fuchsia-200 {
  border-color: #f0b5f9 !important;
}

.border-fuchsia-300 {
  border-color: #e890f5 !important;
}

.border-fuchsia-400 {
  border-color: #e16bf2 !important;
}

.border-fuchsia-500 {
  border-color: #D946EF !important;
}

.border-fuchsia-600 {
  border-color: #ae38bf !important;
}

.border-fuchsia-700 {
  border-color: #822a8f !important;
}

.border-fuchsia-800 {
  border-color: #571c60 !important;
}

.border-fuchsia-900 {
  border-color: #2b0e30 !important;
}

.text-fuchsia-50 {
  color: #fbedfd !important;
}

.text-fuchsia-100 {
  color: #f7dafc !important;
}

.text-fuchsia-200 {
  color: #f0b5f9 !important;
}

.text-fuchsia-300 {
  color: #e890f5 !important;
}

.text-fuchsia-400 {
  color: #e16bf2 !important;
}

.text-fuchsia-500 {
  color: #D946EF !important;
}

.text-fuchsia-600 {
  color: #ae38bf !important;
}

.text-fuchsia-700 {
  color: #822a8f !important;
}

.text-fuchsia-800 {
  color: #571c60 !important;
}

.text-fuchsia-900 {
  color: #2b0e30 !important;
}

.bg-purple {
  background-color: rgba(127, 86, 217, var(--bs-bg-opacity, 100)) !important;
}

.bg-purple-light {
  background-color: rgba(229, 221, 247, var(--bs-bg-opacity, 100)) !important;
}

.border-purple {
  --bs-border-opacity: 1;
  border-color: rgba(127, 86, 217, var(--bs-border-opacity, 100)) !important;
}

.text-purple {
  --bs-text-opacity: 1;
  color: rgba(127, 86, 217, var(--bs-text-opacity, 100)) !important;
}

.text-purple-emphasis {
  --bs-text-opacity: 1;
  color: rgba(102, 69, 174, var(--bs-text-opacity, 100)) !important;
}

.text-bg-purple {
  color: rgba(102, 69, 174, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(229, 221, 247, var(--bs-bg-opacity, 100)) !important;
}

.bg-purple-50 {
  background-color: #f2eefb !important;
}

.bg-purple-100 {
  background-color: #e5ddf7 !important;
}

.bg-purple-200 {
  background-color: #ccbbf0 !important;
}

.bg-purple-300 {
  background-color: #b29ae8 !important;
}

.bg-purple-400 {
  background-color: #9978e1 !important;
}

.bg-purple-500 {
  background-color: #7F56D9 !important;
}

.bg-purple-600 {
  background-color: #6645ae !important;
}

.bg-purple-700 {
  background-color: #4c3482 !important;
}

.bg-purple-800 {
  background-color: #332257 !important;
}

.bg-purple-900 {
  background-color: #19112b !important;
}

.border-purple-50 {
  border-color: #f2eefb !important;
}

.border-purple-100 {
  border-color: #e5ddf7 !important;
}

.border-purple-200 {
  border-color: #ccbbf0 !important;
}

.border-purple-300 {
  border-color: #b29ae8 !important;
}

.border-purple-400 {
  border-color: #9978e1 !important;
}

.border-purple-500 {
  border-color: #7F56D9 !important;
}

.border-purple-600 {
  border-color: #6645ae !important;
}

.border-purple-700 {
  border-color: #4c3482 !important;
}

.border-purple-800 {
  border-color: #332257 !important;
}

.border-purple-900 {
  border-color: #19112b !important;
}

.text-purple-50 {
  color: #f2eefb !important;
}

.text-purple-100 {
  color: #e5ddf7 !important;
}

.text-purple-200 {
  color: #ccbbf0 !important;
}

.text-purple-300 {
  color: #b29ae8 !important;
}

.text-purple-400 {
  color: #9978e1 !important;
}

.text-purple-500 {
  color: #7F56D9 !important;
}

.text-purple-600 {
  color: #6645ae !important;
}

.text-purple-700 {
  color: #4c3482 !important;
}

.text-purple-800 {
  color: #332257 !important;
}

.text-purple-900 {
  color: #19112b !important;
}

.bg-indigo {
  background-color: rgba(99, 102, 241, var(--bs-bg-opacity, 100)) !important;
}

.bg-indigo-light {
  background-color: rgba(224, 224, 252, var(--bs-bg-opacity, 100)) !important;
}

.border-indigo {
  --bs-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--bs-border-opacity, 100)) !important;
}

.text-indigo {
  --bs-text-opacity: 1;
  color: rgba(99, 102, 241, var(--bs-text-opacity, 100)) !important;
}

.text-indigo-emphasis {
  --bs-text-opacity: 1;
  color: rgba(79, 82, 193, var(--bs-text-opacity, 100)) !important;
}

.text-bg-indigo {
  color: rgba(79, 82, 193, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(224, 224, 252, var(--bs-bg-opacity, 100)) !important;
}

.bg-indigo-50 {
  background-color: #eff0fe !important;
}

.bg-indigo-100 {
  background-color: #e0e0fc !important;
}

.bg-indigo-200 {
  background-color: #c1c2f9 !important;
}

.bg-indigo-300 {
  background-color: #a1a3f7 !important;
}

.bg-indigo-400 {
  background-color: #8285f4 !important;
}

.bg-indigo-500 {
  background-color: #6366F1 !important;
}

.bg-indigo-600 {
  background-color: #4f52c1 !important;
}

.bg-indigo-700 {
  background-color: #3b3d91 !important;
}

.bg-indigo-800 {
  background-color: #282960 !important;
}

.bg-indigo-900 {
  background-color: #141430 !important;
}

.border-indigo-50 {
  border-color: #eff0fe !important;
}

.border-indigo-100 {
  border-color: #e0e0fc !important;
}

.border-indigo-200 {
  border-color: #c1c2f9 !important;
}

.border-indigo-300 {
  border-color: #a1a3f7 !important;
}

.border-indigo-400 {
  border-color: #8285f4 !important;
}

.border-indigo-500 {
  border-color: #6366F1 !important;
}

.border-indigo-600 {
  border-color: #4f52c1 !important;
}

.border-indigo-700 {
  border-color: #3b3d91 !important;
}

.border-indigo-800 {
  border-color: #282960 !important;
}

.border-indigo-900 {
  border-color: #141430 !important;
}

.text-indigo-50 {
  color: #eff0fe !important;
}

.text-indigo-100 {
  color: #e0e0fc !important;
}

.text-indigo-200 {
  color: #c1c2f9 !important;
}

.text-indigo-300 {
  color: #a1a3f7 !important;
}

.text-indigo-400 {
  color: #8285f4 !important;
}

.text-indigo-500 {
  color: #6366F1 !important;
}

.text-indigo-600 {
  color: #4f52c1 !important;
}

.text-indigo-700 {
  color: #3b3d91 !important;
}

.text-indigo-800 {
  color: #282960 !important;
}

.text-indigo-900 {
  color: #141430 !important;
}

.bg-blue {
  background-color: rgba(59, 130, 246, var(--bs-bg-opacity, 100)) !important;
}

.bg-blue-light {
  background-color: rgba(216, 230, 253, var(--bs-bg-opacity, 100)) !important;
}

.border-blue {
  --bs-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--bs-border-opacity, 100)) !important;
}

.text-blue {
  --bs-text-opacity: 1;
  color: rgba(59, 130, 246, var(--bs-text-opacity, 100)) !important;
}

.text-blue-emphasis {
  --bs-text-opacity: 1;
  color: rgba(47, 104, 197, var(--bs-text-opacity, 100)) !important;
}

.text-bg-blue {
  color: rgba(47, 104, 197, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(216, 230, 253, var(--bs-bg-opacity, 100)) !important;
}

.bg-blue-50 {
  background-color: #ebf3fe !important;
}

.bg-blue-100 {
  background-color: #d8e6fd !important;
}

.bg-blue-200 {
  background-color: #b1cdfb !important;
}

.bg-blue-300 {
  background-color: #89b4fa !important;
}

.bg-blue-400 {
  background-color: #629bf8 !important;
}

.bg-blue-500 {
  background-color: #3B82F6 !important;
}

.bg-blue-600 {
  background-color: #2f68c5 !important;
}

.bg-blue-700 {
  background-color: #234e94 !important;
}

.bg-blue-800 {
  background-color: #183462 !important;
}

.bg-blue-900 {
  background-color: #0c1a31 !important;
}

.border-blue-50 {
  border-color: #ebf3fe !important;
}

.border-blue-100 {
  border-color: #d8e6fd !important;
}

.border-blue-200 {
  border-color: #b1cdfb !important;
}

.border-blue-300 {
  border-color: #89b4fa !important;
}

.border-blue-400 {
  border-color: #629bf8 !important;
}

.border-blue-500 {
  border-color: #3B82F6 !important;
}

.border-blue-600 {
  border-color: #2f68c5 !important;
}

.border-blue-700 {
  border-color: #234e94 !important;
}

.border-blue-800 {
  border-color: #183462 !important;
}

.border-blue-900 {
  border-color: #0c1a31 !important;
}

.text-blue-50 {
  color: #ebf3fe !important;
}

.text-blue-100 {
  color: #d8e6fd !important;
}

.text-blue-200 {
  color: #b1cdfb !important;
}

.text-blue-300 {
  color: #89b4fa !important;
}

.text-blue-400 {
  color: #629bf8 !important;
}

.text-blue-500 {
  color: #3B82F6 !important;
}

.text-blue-600 {
  color: #2f68c5 !important;
}

.text-blue-700 {
  color: #234e94 !important;
}

.text-blue-800 {
  color: #183462 !important;
}

.text-blue-900 {
  color: #0c1a31 !important;
}

.bg-cyan {
  background-color: rgba(6, 182, 212, var(--bs-bg-opacity, 100)) !important;
}

.bg-cyan-light {
  background-color: rgba(205, 240, 246, var(--bs-bg-opacity, 100)) !important;
}

.border-cyan {
  --bs-border-opacity: 1;
  border-color: rgba(6, 182, 212, var(--bs-border-opacity, 100)) !important;
}

.text-cyan {
  --bs-text-opacity: 1;
  color: rgba(6, 182, 212, var(--bs-text-opacity, 100)) !important;
}

.text-cyan-emphasis {
  --bs-text-opacity: 1;
  color: rgba(5, 146, 170, var(--bs-text-opacity, 100)) !important;
}

.text-bg-cyan {
  color: rgba(5, 146, 170, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(205, 240, 246, var(--bs-bg-opacity, 100)) !important;
}

.bg-cyan-50 {
  background-color: #e6f8fb !important;
}

.bg-cyan-100 {
  background-color: #cdf0f6 !important;
}

.bg-cyan-200 {
  background-color: #9be2ee !important;
}

.bg-cyan-300 {
  background-color: #6ad3e5 !important;
}

.bg-cyan-400 {
  background-color: #38c5dd !important;
}

.bg-cyan-500 {
  background-color: #06B6D4 !important;
}

.bg-cyan-600 {
  background-color: #0592aa !important;
}

.bg-cyan-700 {
  background-color: #046d7f !important;
}

.bg-cyan-800 {
  background-color: #024955 !important;
}

.bg-cyan-900 {
  background-color: #01242a !important;
}

.border-cyan-50 {
  border-color: #e6f8fb !important;
}

.border-cyan-100 {
  border-color: #cdf0f6 !important;
}

.border-cyan-200 {
  border-color: #9be2ee !important;
}

.border-cyan-300 {
  border-color: #6ad3e5 !important;
}

.border-cyan-400 {
  border-color: #38c5dd !important;
}

.border-cyan-500 {
  border-color: #06B6D4 !important;
}

.border-cyan-600 {
  border-color: #0592aa !important;
}

.border-cyan-700 {
  border-color: #046d7f !important;
}

.border-cyan-800 {
  border-color: #024955 !important;
}

.border-cyan-900 {
  border-color: #01242a !important;
}

.text-cyan-50 {
  color: #e6f8fb !important;
}

.text-cyan-100 {
  color: #cdf0f6 !important;
}

.text-cyan-200 {
  color: #9be2ee !important;
}

.text-cyan-300 {
  color: #6ad3e5 !important;
}

.text-cyan-400 {
  color: #38c5dd !important;
}

.text-cyan-500 {
  color: #06B6D4 !important;
}

.text-cyan-600 {
  color: #0592aa !important;
}

.text-cyan-700 {
  color: #046d7f !important;
}

.text-cyan-800 {
  color: #024955 !important;
}

.text-cyan-900 {
  color: #01242a !important;
}

.bg-teal {
  background-color: rgba(20, 184, 166, var(--bs-bg-opacity, 100)) !important;
}

.bg-teal-light {
  background-color: rgba(208, 241, 237, var(--bs-bg-opacity, 100)) !important;
}

.border-teal {
  --bs-border-opacity: 1;
  border-color: rgba(20, 184, 166, var(--bs-border-opacity, 100)) !important;
}

.text-teal {
  --bs-text-opacity: 1;
  color: rgba(20, 184, 166, var(--bs-text-opacity, 100)) !important;
}

.text-teal-emphasis {
  --bs-text-opacity: 1;
  color: rgba(16, 147, 133, var(--bs-text-opacity, 100)) !important;
}

.text-bg-teal {
  color: rgba(16, 147, 133, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(208, 241, 237, var(--bs-bg-opacity, 100)) !important;
}

.bg-teal-50 {
  background-color: #e8f8f6 !important;
}

.bg-teal-100 {
  background-color: #d0f1ed !important;
}

.bg-teal-200 {
  background-color: #a1e3db !important;
}

.bg-teal-300 {
  background-color: #72d4ca !important;
}

.bg-teal-400 {
  background-color: #43c6b8 !important;
}

.bg-teal-500 {
  background-color: #14B8A6 !important;
}

.bg-teal-600 {
  background-color: #109385 !important;
}

.bg-teal-700 {
  background-color: #0c6e64 !important;
}

.bg-teal-800 {
  background-color: #084a42 !important;
}

.bg-teal-900 {
  background-color: #042521 !important;
}

.border-teal-50 {
  border-color: #e8f8f6 !important;
}

.border-teal-100 {
  border-color: #d0f1ed !important;
}

.border-teal-200 {
  border-color: #a1e3db !important;
}

.border-teal-300 {
  border-color: #72d4ca !important;
}

.border-teal-400 {
  border-color: #43c6b8 !important;
}

.border-teal-500 {
  border-color: #14B8A6 !important;
}

.border-teal-600 {
  border-color: #109385 !important;
}

.border-teal-700 {
  border-color: #0c6e64 !important;
}

.border-teal-800 {
  border-color: #084a42 !important;
}

.border-teal-900 {
  border-color: #042521 !important;
}

.text-teal-50 {
  color: #e8f8f6 !important;
}

.text-teal-100 {
  color: #d0f1ed !important;
}

.text-teal-200 {
  color: #a1e3db !important;
}

.text-teal-300 {
  color: #72d4ca !important;
}

.text-teal-400 {
  color: #43c6b8 !important;
}

.text-teal-500 {
  color: #14B8A6 !important;
}

.text-teal-600 {
  color: #109385 !important;
}

.text-teal-700 {
  color: #0c6e64 !important;
}

.text-teal-800 {
  color: #084a42 !important;
}

.text-teal-900 {
  color: #042521 !important;
}

.bg-green {
  background-color: rgba(60, 191, 51, var(--bs-bg-opacity, 100)) !important;
}

.bg-green-light {
  background-color: rgba(216, 242, 214, var(--bs-bg-opacity, 100)) !important;
}

.border-green {
  --bs-border-opacity: 1;
  border-color: rgba(60, 191, 51, var(--bs-border-opacity, 100)) !important;
}

.text-green {
  --bs-text-opacity: 1;
  color: rgba(60, 191, 51, var(--bs-text-opacity, 100)) !important;
}

.text-green-emphasis {
  --bs-text-opacity: 1;
  color: rgba(48, 153, 41, var(--bs-text-opacity, 100)) !important;
}

.text-bg-green {
  color: rgba(48, 153, 41, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(216, 242, 214, var(--bs-bg-opacity, 100)) !important;
}

.bg-green-50 {
  background-color: #ecf9eb !important;
}

.bg-green-100 {
  background-color: #d8f2d6 !important;
}

.bg-green-200 {
  background-color: #b1e5ad !important;
}

.bg-green-300 {
  background-color: #8ad985 !important;
}

.bg-green-400 {
  background-color: #63cc5c !important;
}

.bg-green-500 {
  background-color: #3CBF33 !important;
}

.bg-green-600 {
  background-color: #309929 !important;
}

.bg-green-700 {
  background-color: #24731f !important;
}

.bg-green-800 {
  background-color: #184c14 !important;
}

.bg-green-900 {
  background-color: #0c260a !important;
}

.border-green-50 {
  border-color: #ecf9eb !important;
}

.border-green-100 {
  border-color: #d8f2d6 !important;
}

.border-green-200 {
  border-color: #b1e5ad !important;
}

.border-green-300 {
  border-color: #8ad985 !important;
}

.border-green-400 {
  border-color: #63cc5c !important;
}

.border-green-500 {
  border-color: #3CBF33 !important;
}

.border-green-600 {
  border-color: #309929 !important;
}

.border-green-700 {
  border-color: #24731f !important;
}

.border-green-800 {
  border-color: #184c14 !important;
}

.border-green-900 {
  border-color: #0c260a !important;
}

.text-green-50 {
  color: #ecf9eb !important;
}

.text-green-100 {
  color: #d8f2d6 !important;
}

.text-green-200 {
  color: #b1e5ad !important;
}

.text-green-300 {
  color: #8ad985 !important;
}

.text-green-400 {
  color: #63cc5c !important;
}

.text-green-500 {
  color: #3CBF33 !important;
}

.text-green-600 {
  color: #309929 !important;
}

.text-green-700 {
  color: #24731f !important;
}

.text-green-800 {
  color: #184c14 !important;
}

.text-green-900 {
  color: #0c260a !important;
}

.bg-lime {
  background-color: rgba(132, 204, 22, var(--bs-bg-opacity, 100)) !important;
}

.bg-lime-light {
  background-color: rgba(230, 245, 208, var(--bs-bg-opacity, 100)) !important;
}

.border-lime {
  --bs-border-opacity: 1;
  border-color: rgba(132, 204, 22, var(--bs-border-opacity, 100)) !important;
}

.text-lime {
  --bs-text-opacity: 1;
  color: rgba(132, 204, 22, var(--bs-text-opacity, 100)) !important;
}

.text-lime-emphasis {
  --bs-text-opacity: 1;
  color: rgba(106, 163, 18, var(--bs-text-opacity, 100)) !important;
}

.text-bg-lime {
  color: rgba(106, 163, 18, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(230, 245, 208, var(--bs-bg-opacity, 100)) !important;
}

.bg-lime-50 {
  background-color: #f3fae8 !important;
}

.bg-lime-100 {
  background-color: #e6f5d0 !important;
}

.bg-lime-200 {
  background-color: #ceeba2 !important;
}

.bg-lime-300 {
  background-color: #b5e073 !important;
}

.bg-lime-400 {
  background-color: #9dd645 !important;
}

.bg-lime-500 {
  background-color: #84CC16 !important;
}

.bg-lime-600 {
  background-color: #6aa312 !important;
}

.bg-lime-700 {
  background-color: #4f7a0d !important;
}

.bg-lime-800 {
  background-color: #355209 !important;
}

.bg-lime-900 {
  background-color: #1a2904 !important;
}

.border-lime-50 {
  border-color: #f3fae8 !important;
}

.border-lime-100 {
  border-color: #e6f5d0 !important;
}

.border-lime-200 {
  border-color: #ceeba2 !important;
}

.border-lime-300 {
  border-color: #b5e073 !important;
}

.border-lime-400 {
  border-color: #9dd645 !important;
}

.border-lime-500 {
  border-color: #84CC16 !important;
}

.border-lime-600 {
  border-color: #6aa312 !important;
}

.border-lime-700 {
  border-color: #4f7a0d !important;
}

.border-lime-800 {
  border-color: #355209 !important;
}

.border-lime-900 {
  border-color: #1a2904 !important;
}

.text-lime-50 {
  color: #f3fae8 !important;
}

.text-lime-100 {
  color: #e6f5d0 !important;
}

.text-lime-200 {
  color: #ceeba2 !important;
}

.text-lime-300 {
  color: #b5e073 !important;
}

.text-lime-400 {
  color: #9dd645 !important;
}

.text-lime-500 {
  color: #84CC16 !important;
}

.text-lime-600 {
  color: #6aa312 !important;
}

.text-lime-700 {
  color: #4f7a0d !important;
}

.text-lime-800 {
  color: #355209 !important;
}

.text-lime-900 {
  color: #1a2904 !important;
}

.bg-yellow {
  background-color: rgba(250, 204, 21, var(--bs-bg-opacity, 100)) !important;
}

.bg-yellow-light {
  background-color: rgba(254, 245, 208, var(--bs-bg-opacity, 100)) !important;
}

.border-yellow {
  --bs-border-opacity: 1;
  border-color: rgba(250, 204, 21, var(--bs-border-opacity, 100)) !important;
}

.text-yellow {
  --bs-text-opacity: 1;
  color: rgba(250, 204, 21, var(--bs-text-opacity, 100)) !important;
}

.text-yellow-emphasis {
  --bs-text-opacity: 1;
  color: rgba(200, 163, 17, var(--bs-text-opacity, 100)) !important;
}

.text-bg-yellow {
  color: rgba(200, 163, 17, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(254, 245, 208, var(--bs-bg-opacity, 100)) !important;
}

.bg-yellow-50 {
  background-color: #fffae8 !important;
}

.bg-yellow-100 {
  background-color: #fef5d0 !important;
}

.bg-yellow-200 {
  background-color: #fdeba1 !important;
}

.bg-yellow-300 {
  background-color: #fce073 !important;
}

.bg-yellow-400 {
  background-color: #fbd644 !important;
}

.bg-yellow-500 {
  background-color: #FACC15 !important;
}

.bg-yellow-600 {
  background-color: #c8a311 !important;
}

.bg-yellow-700 {
  background-color: #967a0d !important;
}

.bg-yellow-800 {
  background-color: #645208 !important;
}

.bg-yellow-900 {
  background-color: #322904 !important;
}

.border-yellow-50 {
  border-color: #fffae8 !important;
}

.border-yellow-100 {
  border-color: #fef5d0 !important;
}

.border-yellow-200 {
  border-color: #fdeba1 !important;
}

.border-yellow-300 {
  border-color: #fce073 !important;
}

.border-yellow-400 {
  border-color: #fbd644 !important;
}

.border-yellow-500 {
  border-color: #FACC15 !important;
}

.border-yellow-600 {
  border-color: #c8a311 !important;
}

.border-yellow-700 {
  border-color: #967a0d !important;
}

.border-yellow-800 {
  border-color: #645208 !important;
}

.border-yellow-900 {
  border-color: #322904 !important;
}

.text-yellow-50 {
  color: #fffae8 !important;
}

.text-yellow-100 {
  color: #fef5d0 !important;
}

.text-yellow-200 {
  color: #fdeba1 !important;
}

.text-yellow-300 {
  color: #fce073 !important;
}

.text-yellow-400 {
  color: #fbd644 !important;
}

.text-yellow-500 {
  color: #FACC15 !important;
}

.text-yellow-600 {
  color: #c8a311 !important;
}

.text-yellow-700 {
  color: #967a0d !important;
}

.text-yellow-800 {
  color: #645208 !important;
}

.text-yellow-900 {
  color: #322904 !important;
}

.bg-orange {
  background-color: rgba(249, 115, 22, var(--bs-bg-opacity, 100)) !important;
}

.bg-orange-light {
  background-color: rgba(254, 227, 208, var(--bs-bg-opacity, 100)) !important;
}

.border-orange {
  --bs-border-opacity: 1;
  border-color: rgba(249, 115, 22, var(--bs-border-opacity, 100)) !important;
}

.text-orange {
  --bs-text-opacity: 1;
  color: rgba(249, 115, 22, var(--bs-text-opacity, 100)) !important;
}

.text-orange-emphasis {
  --bs-text-opacity: 1;
  color: rgba(199, 92, 18, var(--bs-text-opacity, 100)) !important;
}

.text-bg-orange {
  color: rgba(199, 92, 18, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(254, 227, 208, var(--bs-bg-opacity, 100)) !important;
}

.bg-orange-50 {
  background-color: #fef1e8 !important;
}

.bg-orange-100 {
  background-color: #fee3d0 !important;
}

.bg-orange-200 {
  background-color: #fdc7a2 !important;
}

.bg-orange-300 {
  background-color: #fbab73 !important;
}

.bg-orange-400 {
  background-color: #fa8f45 !important;
}

.bg-orange-500 {
  background-color: #F97316 !important;
}

.bg-orange-600 {
  background-color: #c75c12 !important;
}

.bg-orange-700 {
  background-color: #95450d !important;
}

.bg-orange-800 {
  background-color: #642e09 !important;
}

.bg-orange-900 {
  background-color: #321704 !important;
}

.border-orange-50 {
  border-color: #fef1e8 !important;
}

.border-orange-100 {
  border-color: #fee3d0 !important;
}

.border-orange-200 {
  border-color: #fdc7a2 !important;
}

.border-orange-300 {
  border-color: #fbab73 !important;
}

.border-orange-400 {
  border-color: #fa8f45 !important;
}

.border-orange-500 {
  border-color: #F97316 !important;
}

.border-orange-600 {
  border-color: #c75c12 !important;
}

.border-orange-700 {
  border-color: #95450d !important;
}

.border-orange-800 {
  border-color: #642e09 !important;
}

.border-orange-900 {
  border-color: #321704 !important;
}

.text-orange-50 {
  color: #fef1e8 !important;
}

.text-orange-100 {
  color: #fee3d0 !important;
}

.text-orange-200 {
  color: #fdc7a2 !important;
}

.text-orange-300 {
  color: #fbab73 !important;
}

.text-orange-400 {
  color: #fa8f45 !important;
}

.text-orange-500 {
  color: #F97316 !important;
}

.text-orange-600 {
  color: #c75c12 !important;
}

.text-orange-700 {
  color: #95450d !important;
}

.text-orange-800 {
  color: #642e09 !important;
}

.text-orange-900 {
  color: #321704 !important;
}

.bg-red {
  background-color: rgba(220, 53, 69, var(--bs-bg-opacity, 100)) !important;
}

.bg-red-light {
  background-color: rgba(248, 215, 218, var(--bs-bg-opacity, 100)) !important;
}

.border-red {
  --bs-border-opacity: 1;
  border-color: rgba(220, 53, 69, var(--bs-border-opacity, 100)) !important;
}

.text-red {
  --bs-text-opacity: 1;
  color: rgba(220, 53, 69, var(--bs-text-opacity, 100)) !important;
}

.text-red-emphasis {
  --bs-text-opacity: 1;
  color: rgba(176, 42, 55, var(--bs-text-opacity, 100)) !important;
}

.text-bg-red {
  color: rgba(176, 42, 55, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(248, 215, 218, var(--bs-bg-opacity, 100)) !important;
}

.bg-red-50 {
  background-color: #fcebec !important;
}

.bg-red-100 {
  background-color: #f8d7da !important;
}

.bg-red-200 {
  background-color: #f1aeb5 !important;
}

.bg-red-300 {
  background-color: #ea868f !important;
}

.bg-red-400 {
  background-color: #e35d6a !important;
}

.bg-red-500 {
  background-color: #dc3545 !important;
}

.bg-red-600 {
  background-color: #b02a37 !important;
}

.bg-red-700 {
  background-color: #842029 !important;
}

.bg-red-800 {
  background-color: #58151c !important;
}

.bg-red-900 {
  background-color: #2c0b0e !important;
}

.border-red-50 {
  border-color: #fcebec !important;
}

.border-red-100 {
  border-color: #f8d7da !important;
}

.border-red-200 {
  border-color: #f1aeb5 !important;
}

.border-red-300 {
  border-color: #ea868f !important;
}

.border-red-400 {
  border-color: #e35d6a !important;
}

.border-red-500 {
  border-color: #dc3545 !important;
}

.border-red-600 {
  border-color: #b02a37 !important;
}

.border-red-700 {
  border-color: #842029 !important;
}

.border-red-800 {
  border-color: #58151c !important;
}

.border-red-900 {
  border-color: #2c0b0e !important;
}

.text-red-50 {
  color: #fcebec !important;
}

.text-red-100 {
  color: #f8d7da !important;
}

.text-red-200 {
  color: #f1aeb5 !important;
}

.text-red-300 {
  color: #ea868f !important;
}

.text-red-400 {
  color: #e35d6a !important;
}

.text-red-500 {
  color: #dc3545 !important;
}

.text-red-600 {
  color: #b02a37 !important;
}

.text-red-700 {
  color: #842029 !important;
}

.text-red-800 {
  color: #58151c !important;
}

.text-red-900 {
  color: #2c0b0e !important;
}

.bg-black {
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity, 100)) !important;
}

.bg-black-light {
  background-color: rgba(204, 204, 204, var(--bs-bg-opacity, 100)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--bs-border-opacity, 100)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity, 100)) !important;
}

.text-black-emphasis {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity, 100)) !important;
}

.text-bg-black {
  color: rgba(0, 0, 0, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(204, 204, 204, var(--bs-bg-opacity, 100)) !important;
}

.bg-black-50 {
  background-color: #e6e6e6 !important;
}

.bg-black-100 {
  background-color: #cccccc !important;
}

.bg-black-200 {
  background-color: #999999 !important;
}

.bg-black-300 {
  background-color: #666666 !important;
}

.bg-black-400 {
  background-color: #333333 !important;
}

.bg-black-500 {
  background-color: #000000 !important;
}

.bg-black-600 {
  background-color: black !important;
}

.bg-black-700 {
  background-color: black !important;
}

.bg-black-800 {
  background-color: black !important;
}

.bg-black-900 {
  background-color: black !important;
}

.border-black-50 {
  border-color: #e6e6e6 !important;
}

.border-black-100 {
  border-color: #cccccc !important;
}

.border-black-200 {
  border-color: #999999 !important;
}

.border-black-300 {
  border-color: #666666 !important;
}

.border-black-400 {
  border-color: #333333 !important;
}

.border-black-500 {
  border-color: #000000 !important;
}

.border-black-600 {
  border-color: black !important;
}

.border-black-700 {
  border-color: black !important;
}

.border-black-800 {
  border-color: black !important;
}

.border-black-900 {
  border-color: black !important;
}

.text-black-50 {
  color: #e6e6e6 !important;
}

.text-black-100 {
  color: #cccccc !important;
}

.text-black-200 {
  color: #999999 !important;
}

.text-black-300 {
  color: #666666 !important;
}

.text-black-400 {
  color: #333333 !important;
}

.text-black-500 {
  color: #000000 !important;
}

.text-black-600 {
  color: black !important;
}

.text-black-700 {
  color: black !important;
}

.text-black-800 {
  color: black !important;
}

.text-black-900 {
  color: black !important;
}

.bg-white {
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity, 100)) !important;
}

.bg-white-light {
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity, 100)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--bs-border-opacity, 100)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity, 100)) !important;
}

.text-white-emphasis {
  --bs-text-opacity: 1;
  color: rgba(204, 204, 204, var(--bs-text-opacity, 100)) !important;
}

.text-bg-white {
  color: rgba(204, 204, 204, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity, 100)) !important;
}

.bg-white-50 {
  background-color: white !important;
}

.bg-white-100 {
  background-color: white !important;
}

.bg-white-200 {
  background-color: white !important;
}

.bg-white-300 {
  background-color: white !important;
}

.bg-white-400 {
  background-color: white !important;
}

.bg-white-500 {
  background-color: #FFF !important;
}

.bg-white-600 {
  background-color: #cccccc !important;
}

.bg-white-700 {
  background-color: #999999 !important;
}

.bg-white-800 {
  background-color: #666666 !important;
}

.bg-white-900 {
  background-color: #333333 !important;
}

.border-white-50 {
  border-color: white !important;
}

.border-white-100 {
  border-color: white !important;
}

.border-white-200 {
  border-color: white !important;
}

.border-white-300 {
  border-color: white !important;
}

.border-white-400 {
  border-color: white !important;
}

.border-white-500 {
  border-color: #FFF !important;
}

.border-white-600 {
  border-color: #cccccc !important;
}

.border-white-700 {
  border-color: #999999 !important;
}

.border-white-800 {
  border-color: #666666 !important;
}

.border-white-900 {
  border-color: #333333 !important;
}

.text-white-50 {
  color: white !important;
}

.text-white-100 {
  color: white !important;
}

.text-white-200 {
  color: white !important;
}

.text-white-300 {
  color: white !important;
}

.text-white-400 {
  color: white !important;
}

.text-white-500 {
  color: #FFF !important;
}

.text-white-600 {
  color: #cccccc !important;
}

.text-white-700 {
  color: #999999 !important;
}

.text-white-800 {
  color: #666666 !important;
}

.text-white-900 {
  color: #333333 !important;
}

.bg-gray {
  background-color: rgba(71, 84, 103, var(--bs-bg-opacity, 100)) !important;
}

.bg-gray-light {
  background-color: rgba(218, 221, 225, var(--bs-bg-opacity, 100)) !important;
}

.border-gray {
  --bs-border-opacity: 1;
  border-color: rgba(71, 84, 103, var(--bs-border-opacity, 100)) !important;
}

.text-gray {
  --bs-text-opacity: 1;
  color: rgba(71, 84, 103, var(--bs-text-opacity, 100)) !important;
}

.text-gray-emphasis {
  --bs-text-opacity: 1;
  color: rgba(57, 67, 82, var(--bs-text-opacity, 100)) !important;
}

.text-bg-gray {
  color: rgba(57, 67, 82, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(218, 221, 225, var(--bs-bg-opacity, 100)) !important;
}

.bg-gray-50 {
  background-color: #edeef0 !important;
}

.bg-gray-100 {
  background-color: #dadde1 !important;
}

.bg-gray-200 {
  background-color: #b5bbc2 !important;
}

.bg-gray-300 {
  background-color: #9198a4 !important;
}

.bg-gray-400 {
  background-color: #6c7685 !important;
}

.bg-gray-500 {
  background-color: #475467 !important;
}

.bg-gray-600 {
  background-color: #394352 !important;
}

.bg-gray-700 {
  background-color: #2b323e !important;
}

.bg-gray-800 {
  background-color: #1c2229 !important;
}

.bg-gray-900 {
  background-color: #0e1115 !important;
}

.border-gray-50 {
  border-color: #edeef0 !important;
}

.border-gray-100 {
  border-color: #dadde1 !important;
}

.border-gray-200 {
  border-color: #b5bbc2 !important;
}

.border-gray-300 {
  border-color: #9198a4 !important;
}

.border-gray-400 {
  border-color: #6c7685 !important;
}

.border-gray-500 {
  border-color: #475467 !important;
}

.border-gray-600 {
  border-color: #394352 !important;
}

.border-gray-700 {
  border-color: #2b323e !important;
}

.border-gray-800 {
  border-color: #1c2229 !important;
}

.border-gray-900 {
  border-color: #0e1115 !important;
}

.text-gray-50 {
  color: #edeef0 !important;
}

.text-gray-100 {
  color: #dadde1 !important;
}

.text-gray-200 {
  color: #b5bbc2 !important;
}

.text-gray-300 {
  color: #9198a4 !important;
}

.text-gray-400 {
  color: #6c7685 !important;
}

.text-gray-500 {
  color: #475467 !important;
}

.text-gray-600 {
  color: #394352 !important;
}

.text-gray-700 {
  color: #2b323e !important;
}

.text-gray-800 {
  color: #1c2229 !important;
}

.text-gray-900 {
  color: #0e1115 !important;
}

.bg-gray-dark {
  background-color: rgba(29, 41, 57, var(--bs-bg-opacity, 100)) !important;
}

.bg-gray-dark-light {
  background-color: rgba(210, 212, 215, var(--bs-bg-opacity, 100)) !important;
}

.border-gray-dark {
  --bs-border-opacity: 1;
  border-color: rgba(29, 41, 57, var(--bs-border-opacity, 100)) !important;
}

.text-gray-dark {
  --bs-text-opacity: 1;
  color: rgba(29, 41, 57, var(--bs-text-opacity, 100)) !important;
}

.text-gray-dark-emphasis {
  --bs-text-opacity: 1;
  color: rgba(23, 33, 46, var(--bs-text-opacity, 100)) !important;
}

.text-bg-gray-dark {
  color: rgba(23, 33, 46, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(210, 212, 215, var(--bs-bg-opacity, 100)) !important;
}

.bg-gray-dark-50 {
  background-color: #e8eaeb !important;
}

.bg-gray-dark-100 {
  background-color: #d2d4d7 !important;
}

.bg-gray-dark-200 {
  background-color: #a5a9b0 !important;
}

.bg-gray-dark-300 {
  background-color: #777f88 !important;
}

.bg-gray-dark-400 {
  background-color: #4a5461 !important;
}

.bg-gray-dark-500 {
  background-color: #1D2939 !important;
}

.bg-gray-dark-600 {
  background-color: #17212e !important;
}

.bg-gray-dark-700 {
  background-color: #111922 !important;
}

.bg-gray-dark-800 {
  background-color: #0c1017 !important;
}

.bg-gray-dark-900 {
  background-color: #06080b !important;
}

.border-gray-dark-50 {
  border-color: #e8eaeb !important;
}

.border-gray-dark-100 {
  border-color: #d2d4d7 !important;
}

.border-gray-dark-200 {
  border-color: #a5a9b0 !important;
}

.border-gray-dark-300 {
  border-color: #777f88 !important;
}

.border-gray-dark-400 {
  border-color: #4a5461 !important;
}

.border-gray-dark-500 {
  border-color: #1D2939 !important;
}

.border-gray-dark-600 {
  border-color: #17212e !important;
}

.border-gray-dark-700 {
  border-color: #111922 !important;
}

.border-gray-dark-800 {
  border-color: #0c1017 !important;
}

.border-gray-dark-900 {
  border-color: #06080b !important;
}

.text-gray-dark-50 {
  color: #e8eaeb !important;
}

.text-gray-dark-100 {
  color: #d2d4d7 !important;
}

.text-gray-dark-200 {
  color: #a5a9b0 !important;
}

.text-gray-dark-300 {
  color: #777f88 !important;
}

.text-gray-dark-400 {
  color: #4a5461 !important;
}

.text-gray-dark-500 {
  color: #1D2939 !important;
}

.text-gray-dark-600 {
  color: #17212e !important;
}

.text-gray-dark-700 {
  color: #111922 !important;
}

.text-gray-dark-800 {
  color: #0c1017 !important;
}

.text-gray-dark-900 {
  color: #06080b !important;
}

.bg-slate {
  background-color: rgba(62, 87, 130, var(--bs-bg-opacity, 100)) !important;
}

.bg-slate-light {
  background-color: rgba(216, 221, 230, var(--bs-bg-opacity, 100)) !important;
}

.border-slate {
  --bs-border-opacity: 1;
  border-color: rgba(62, 87, 130, var(--bs-border-opacity, 100)) !important;
}

.text-slate {
  --bs-text-opacity: 1;
  color: rgba(62, 87, 130, var(--bs-text-opacity, 100)) !important;
}

.text-slate-emphasis {
  --bs-text-opacity: 1;
  color: rgba(50, 70, 104, var(--bs-text-opacity, 100)) !important;
}

.text-bg-slate {
  color: rgba(50, 70, 104, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(216, 221, 230, var(--bs-bg-opacity, 100)) !important;
}

.bg-slate-50 {
  background-color: #eceef3 !important;
}

.bg-slate-100 {
  background-color: #d8dde6 !important;
}

.bg-slate-200 {
  background-color: #b2bccd !important;
}

.bg-slate-300 {
  background-color: #8b9ab4 !important;
}

.bg-slate-400 {
  background-color: #65799b !important;
}

.bg-slate-500 {
  background-color: #3E5782 !important;
}

.bg-slate-600 {
  background-color: #324668 !important;
}

.bg-slate-700 {
  background-color: #25344e !important;
}

.bg-slate-800 {
  background-color: #192334 !important;
}

.bg-slate-900 {
  background-color: #0c111a !important;
}

.border-slate-50 {
  border-color: #eceef3 !important;
}

.border-slate-100 {
  border-color: #d8dde6 !important;
}

.border-slate-200 {
  border-color: #b2bccd !important;
}

.border-slate-300 {
  border-color: #8b9ab4 !important;
}

.border-slate-400 {
  border-color: #65799b !important;
}

.border-slate-500 {
  border-color: #3E5782 !important;
}

.border-slate-600 {
  border-color: #324668 !important;
}

.border-slate-700 {
  border-color: #25344e !important;
}

.border-slate-800 {
  border-color: #192334 !important;
}

.border-slate-900 {
  border-color: #0c111a !important;
}

.text-slate-50 {
  color: #eceef3 !important;
}

.text-slate-100 {
  color: #d8dde6 !important;
}

.text-slate-200 {
  color: #b2bccd !important;
}

.text-slate-300 {
  color: #8b9ab4 !important;
}

.text-slate-400 {
  color: #65799b !important;
}

.text-slate-500 {
  color: #3E5782 !important;
}

.text-slate-600 {
  color: #324668 !important;
}

.text-slate-700 {
  color: #25344e !important;
}

.text-slate-800 {
  color: #192334 !important;
}

.text-slate-900 {
  color: #0c111a !important;
}

.bg-apple {
  background-color: rgba(203, 224, 7, var(--bs-bg-opacity, 100)) !important;
}

.bg-apple-light {
  background-color: rgba(245, 249, 205, var(--bs-bg-opacity, 100)) !important;
}

.border-apple {
  --bs-border-opacity: 1;
  border-color: rgba(203, 224, 7, var(--bs-border-opacity, 100)) !important;
}

.text-apple {
  --bs-text-opacity: 1;
  color: rgba(203, 224, 7, var(--bs-text-opacity, 100)) !important;
}

.text-apple-emphasis {
  --bs-text-opacity: 1;
  color: rgba(162, 179, 6, var(--bs-text-opacity, 100)) !important;
}

.text-bg-apple {
  color: rgba(162, 179, 6, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(245, 249, 205, var(--bs-bg-opacity, 100)) !important;
}

.bg-apple-50 {
  background-color: #fafce6 !important;
}

.bg-apple-100 {
  background-color: #f5f9cd !important;
}

.bg-apple-200 {
  background-color: #eaf39c !important;
}

.bg-apple-300 {
  background-color: #e0ec6a !important;
}

.bg-apple-400 {
  background-color: #d5e639 !important;
}

.bg-apple-500 {
  background-color: #CBE007 !important;
}

.bg-apple-600 {
  background-color: #a2b306 !important;
}

.bg-apple-700 {
  background-color: #7a8604 !important;
}

.bg-apple-800 {
  background-color: #515a03 !important;
}

.bg-apple-900 {
  background-color: #292d01 !important;
}

.border-apple-50 {
  border-color: #fafce6 !important;
}

.border-apple-100 {
  border-color: #f5f9cd !important;
}

.border-apple-200 {
  border-color: #eaf39c !important;
}

.border-apple-300 {
  border-color: #e0ec6a !important;
}

.border-apple-400 {
  border-color: #d5e639 !important;
}

.border-apple-500 {
  border-color: #CBE007 !important;
}

.border-apple-600 {
  border-color: #a2b306 !important;
}

.border-apple-700 {
  border-color: #7a8604 !important;
}

.border-apple-800 {
  border-color: #515a03 !important;
}

.border-apple-900 {
  border-color: #292d01 !important;
}

.text-apple-50 {
  color: #fafce6 !important;
}

.text-apple-100 {
  color: #f5f9cd !important;
}

.text-apple-200 {
  color: #eaf39c !important;
}

.text-apple-300 {
  color: #e0ec6a !important;
}

.text-apple-400 {
  color: #d5e639 !important;
}

.text-apple-500 {
  color: #CBE007 !important;
}

.text-apple-600 {
  color: #a2b306 !important;
}

.text-apple-700 {
  color: #7a8604 !important;
}

.text-apple-800 {
  color: #515a03 !important;
}

.text-apple-900 {
  color: #292d01 !important;
}

.bg-amber {
  background-color: rgba(255, 182, 38, var(--bs-bg-opacity, 100)) !important;
}

.bg-amber-light {
  background-color: rgba(255, 240, 212, var(--bs-bg-opacity, 100)) !important;
}

.border-amber {
  --bs-border-opacity: 1;
  border-color: rgba(255, 182, 38, var(--bs-border-opacity, 100)) !important;
}

.text-amber {
  --bs-text-opacity: 1;
  color: rgba(255, 182, 38, var(--bs-text-opacity, 100)) !important;
}

.text-amber-emphasis {
  --bs-text-opacity: 1;
  color: rgba(204, 146, 30, var(--bs-text-opacity, 100)) !important;
}

.text-bg-amber {
  color: rgba(204, 146, 30, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(255, 240, 212, var(--bs-bg-opacity, 100)) !important;
}

.bg-amber-50 {
  background-color: #fff8e9 !important;
}

.bg-amber-100 {
  background-color: #fff0d4 !important;
}

.bg-amber-200 {
  background-color: #ffe2a8 !important;
}

.bg-amber-300 {
  background-color: #ffd37d !important;
}

.bg-amber-400 {
  background-color: #ffc551 !important;
}

.bg-amber-500 {
  background-color: #FFB626 !important;
}

.bg-amber-600 {
  background-color: #cc921e !important;
}

.bg-amber-700 {
  background-color: #996d17 !important;
}

.bg-amber-800 {
  background-color: #66490f !important;
}

.bg-amber-900 {
  background-color: #332408 !important;
}

.border-amber-50 {
  border-color: #fff8e9 !important;
}

.border-amber-100 {
  border-color: #fff0d4 !important;
}

.border-amber-200 {
  border-color: #ffe2a8 !important;
}

.border-amber-300 {
  border-color: #ffd37d !important;
}

.border-amber-400 {
  border-color: #ffc551 !important;
}

.border-amber-500 {
  border-color: #FFB626 !important;
}

.border-amber-600 {
  border-color: #cc921e !important;
}

.border-amber-700 {
  border-color: #996d17 !important;
}

.border-amber-800 {
  border-color: #66490f !important;
}

.border-amber-900 {
  border-color: #332408 !important;
}

.text-amber-50 {
  color: #fff8e9 !important;
}

.text-amber-100 {
  color: #fff0d4 !important;
}

.text-amber-200 {
  color: #ffe2a8 !important;
}

.text-amber-300 {
  color: #ffd37d !important;
}

.text-amber-400 {
  color: #ffc551 !important;
}

.text-amber-500 {
  color: #FFB626 !important;
}

.text-amber-600 {
  color: #cc921e !important;
}

.text-amber-700 {
  color: #996d17 !important;
}

.text-amber-800 {
  color: #66490f !important;
}

.text-amber-900 {
  color: #332408 !important;
}

.bg-ruby {
  background-color: rgba(212, 48, 77, var(--bs-bg-opacity, 100)) !important;
}

.bg-ruby-light {
  background-color: rgba(246, 214, 219, var(--bs-bg-opacity, 100)) !important;
}

.border-ruby {
  --bs-border-opacity: 1;
  border-color: rgba(212, 48, 77, var(--bs-border-opacity, 100)) !important;
}

.text-ruby {
  --bs-text-opacity: 1;
  color: rgba(212, 48, 77, var(--bs-text-opacity, 100)) !important;
}

.text-ruby-emphasis {
  --bs-text-opacity: 1;
  color: rgba(170, 38, 62, var(--bs-text-opacity, 100)) !important;
}

.text-bg-ruby {
  color: rgba(170, 38, 62, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(246, 214, 219, var(--bs-bg-opacity, 100)) !important;
}

.bg-ruby-50 {
  background-color: #fbeaed !important;
}

.bg-ruby-100 {
  background-color: #f6d6db !important;
}

.bg-ruby-200 {
  background-color: #eeacb8 !important;
}

.bg-ruby-300 {
  background-color: #e58394 !important;
}

.bg-ruby-400 {
  background-color: #dd5971 !important;
}

.bg-ruby-500 {
  background-color: #D4304D !important;
}

.bg-ruby-600 {
  background-color: #aa263e !important;
}

.bg-ruby-700 {
  background-color: #7f1d2e !important;
}

.bg-ruby-800 {
  background-color: #55131f !important;
}

.bg-ruby-900 {
  background-color: #2a0a0f !important;
}

.border-ruby-50 {
  border-color: #fbeaed !important;
}

.border-ruby-100 {
  border-color: #f6d6db !important;
}

.border-ruby-200 {
  border-color: #eeacb8 !important;
}

.border-ruby-300 {
  border-color: #e58394 !important;
}

.border-ruby-400 {
  border-color: #dd5971 !important;
}

.border-ruby-500 {
  border-color: #D4304D !important;
}

.border-ruby-600 {
  border-color: #aa263e !important;
}

.border-ruby-700 {
  border-color: #7f1d2e !important;
}

.border-ruby-800 {
  border-color: #55131f !important;
}

.border-ruby-900 {
  border-color: #2a0a0f !important;
}

.text-ruby-50 {
  color: #fbeaed !important;
}

.text-ruby-100 {
  color: #f6d6db !important;
}

.text-ruby-200 {
  color: #eeacb8 !important;
}

.text-ruby-300 {
  color: #e58394 !important;
}

.text-ruby-400 {
  color: #dd5971 !important;
}

.text-ruby-500 {
  color: #D4304D !important;
}

.text-ruby-600 {
  color: #aa263e !important;
}

.text-ruby-700 {
  color: #7f1d2e !important;
}

.text-ruby-800 {
  color: #55131f !important;
}

.text-ruby-900 {
  color: #2a0a0f !important;
}

.bg-coral {
  background-color: rgba(255, 107, 91, var(--bs-bg-opacity, 100)) !important;
}

.bg-coral-light {
  background-color: rgba(255, 225, 222, var(--bs-bg-opacity, 100)) !important;
}

.border-coral {
  --bs-border-opacity: 1;
  border-color: rgba(255, 107, 91, var(--bs-border-opacity, 100)) !important;
}

.text-coral {
  --bs-text-opacity: 1;
  color: rgba(255, 107, 91, var(--bs-text-opacity, 100)) !important;
}

.text-coral-emphasis {
  --bs-text-opacity: 1;
  color: rgba(204, 86, 73, var(--bs-text-opacity, 100)) !important;
}

.text-bg-coral {
  color: rgba(204, 86, 73, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(255, 225, 222, var(--bs-bg-opacity, 100)) !important;
}

.bg-coral-50 {
  background-color: #fff0ef !important;
}

.bg-coral-100 {
  background-color: #ffe1de !important;
}

.bg-coral-200 {
  background-color: #ffc4bd !important;
}

.bg-coral-300 {
  background-color: #ffa69d !important;
}

.bg-coral-400 {
  background-color: #ff897c !important;
}

.bg-coral-500 {
  background-color: #FF6B5B !important;
}

.bg-coral-600 {
  background-color: #cc5649 !important;
}

.bg-coral-700 {
  background-color: #994037 !important;
}

.bg-coral-800 {
  background-color: #662b24 !important;
}

.bg-coral-900 {
  background-color: #331512 !important;
}

.border-coral-50 {
  border-color: #fff0ef !important;
}

.border-coral-100 {
  border-color: #ffe1de !important;
}

.border-coral-200 {
  border-color: #ffc4bd !important;
}

.border-coral-300 {
  border-color: #ffa69d !important;
}

.border-coral-400 {
  border-color: #ff897c !important;
}

.border-coral-500 {
  border-color: #FF6B5B !important;
}

.border-coral-600 {
  border-color: #cc5649 !important;
}

.border-coral-700 {
  border-color: #994037 !important;
}

.border-coral-800 {
  border-color: #662b24 !important;
}

.border-coral-900 {
  border-color: #331512 !important;
}

.text-coral-50 {
  color: #fff0ef !important;
}

.text-coral-100 {
  color: #ffe1de !important;
}

.text-coral-200 {
  color: #ffc4bd !important;
}

.text-coral-300 {
  color: #ffa69d !important;
}

.text-coral-400 {
  color: #ff897c !important;
}

.text-coral-500 {
  color: #FF6B5B !important;
}

.text-coral-600 {
  color: #cc5649 !important;
}

.text-coral-700 {
  color: #994037 !important;
}

.text-coral-800 {
  color: #662b24 !important;
}

.text-coral-900 {
  color: #331512 !important;
}

.bg-brown {
  background-color: rgba(180, 83, 9, var(--bs-bg-opacity, 100)) !important;
}

.bg-brown-light {
  background-color: rgba(240, 221, 206, var(--bs-bg-opacity, 100)) !important;
}

.border-brown {
  --bs-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--bs-border-opacity, 100)) !important;
}

.text-brown {
  --bs-text-opacity: 1;
  color: rgba(180, 83, 9, var(--bs-text-opacity, 100)) !important;
}

.text-brown-emphasis {
  --bs-text-opacity: 1;
  color: rgba(144, 66, 7, var(--bs-text-opacity, 100)) !important;
}

.text-bg-brown {
  color: rgba(144, 66, 7, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(240, 221, 206, var(--bs-bg-opacity, 100)) !important;
}

.bg-brown-50 {
  background-color: #f8eee6 !important;
}

.bg-brown-100 {
  background-color: #f0ddce !important;
}

.bg-brown-200 {
  background-color: #e1ba9d !important;
}

.bg-brown-300 {
  background-color: #d2986b !important;
}

.bg-brown-400 {
  background-color: #c3753a !important;
}

.bg-brown-500 {
  background-color: #B45309 !important;
}

.bg-brown-600 {
  background-color: #904207 !important;
}

.bg-brown-700 {
  background-color: #6c3205 !important;
}

.bg-brown-800 {
  background-color: #482104 !important;
}

.bg-brown-900 {
  background-color: #241102 !important;
}

.border-brown-50 {
  border-color: #f8eee6 !important;
}

.border-brown-100 {
  border-color: #f0ddce !important;
}

.border-brown-200 {
  border-color: #e1ba9d !important;
}

.border-brown-300 {
  border-color: #d2986b !important;
}

.border-brown-400 {
  border-color: #c3753a !important;
}

.border-brown-500 {
  border-color: #B45309 !important;
}

.border-brown-600 {
  border-color: #904207 !important;
}

.border-brown-700 {
  border-color: #6c3205 !important;
}

.border-brown-800 {
  border-color: #482104 !important;
}

.border-brown-900 {
  border-color: #241102 !important;
}

.text-brown-50 {
  color: #f8eee6 !important;
}

.text-brown-100 {
  color: #f0ddce !important;
}

.text-brown-200 {
  color: #e1ba9d !important;
}

.text-brown-300 {
  color: #d2986b !important;
}

.text-brown-400 {
  color: #c3753a !important;
}

.text-brown-500 {
  color: #B45309 !important;
}

.text-brown-600 {
  color: #904207 !important;
}

.text-brown-700 {
  color: #6c3205 !important;
}

.text-brown-800 {
  color: #482104 !important;
}

.text-brown-900 {
  color: #241102 !important;
}

.bg-coffee {
  background-color: rgba(124, 45, 18, var(--bs-bg-opacity, 100)) !important;
}

.bg-coffee-light {
  background-color: rgba(229, 213, 208, var(--bs-bg-opacity, 100)) !important;
}

.border-coffee {
  --bs-border-opacity: 1;
  border-color: rgba(124, 45, 18, var(--bs-border-opacity, 100)) !important;
}

.text-coffee {
  --bs-text-opacity: 1;
  color: rgba(124, 45, 18, var(--bs-text-opacity, 100)) !important;
}

.text-coffee-emphasis {
  --bs-text-opacity: 1;
  color: rgba(99, 36, 14, var(--bs-text-opacity, 100)) !important;
}

.text-bg-coffee {
  color: rgba(99, 36, 14, var(--bs-text-opacity, 100)) !important;
  background-color: rgba(229, 213, 208, var(--bs-bg-opacity, 100)) !important;
}

.bg-coffee-50 {
  background-color: #f2eae7 !important;
}

.bg-coffee-100 {
  background-color: #e5d5d0 !important;
}

.bg-coffee-200 {
  background-color: #cbaba0 !important;
}

.bg-coffee-300 {
  background-color: #b08171 !important;
}

.bg-coffee-400 {
  background-color: #965741 !important;
}

.bg-coffee-500 {
  background-color: #7C2D12 !important;
}

.bg-coffee-600 {
  background-color: #63240e !important;
}

.bg-coffee-700 {
  background-color: #4a1b0b !important;
}

.bg-coffee-800 {
  background-color: #321207 !important;
}

.bg-coffee-900 {
  background-color: #190904 !important;
}

.border-coffee-50 {
  border-color: #f2eae7 !important;
}

.border-coffee-100 {
  border-color: #e5d5d0 !important;
}

.border-coffee-200 {
  border-color: #cbaba0 !important;
}

.border-coffee-300 {
  border-color: #b08171 !important;
}

.border-coffee-400 {
  border-color: #965741 !important;
}

.border-coffee-500 {
  border-color: #7C2D12 !important;
}

.border-coffee-600 {
  border-color: #63240e !important;
}

.border-coffee-700 {
  border-color: #4a1b0b !important;
}

.border-coffee-800 {
  border-color: #321207 !important;
}

.border-coffee-900 {
  border-color: #190904 !important;
}

.text-coffee-50 {
  color: #f2eae7 !important;
}

.text-coffee-100 {
  color: #e5d5d0 !important;
}

.text-coffee-200 {
  color: #cbaba0 !important;
}

.text-coffee-300 {
  color: #b08171 !important;
}

.text-coffee-400 {
  color: #965741 !important;
}

.text-coffee-500 {
  color: #7C2D12 !important;
}

.text-coffee-600 {
  color: #63240e !important;
}

.text-coffee-700 {
  color: #4a1b0b !important;
}

.text-coffee-800 {
  color: #321207 !important;
}

.text-coffee-900 {
  color: #190904 !important;
}

.bg-primary-50 {
  background-color: #ebf5fe !important;
}

.bg-primary-100 {
  background-color: #d8ebfc !important;
}

.bg-primary-200 {
  background-color: #b1d6f9 !important;
}

.bg-primary-300 {
  background-color: #89c2f6 !important;
}

.bg-primary-400 {
  background-color: #62adf3 !important;
}

.bg-primary-500 {
  background-color: #3b99f0 !important;
}

.bg-primary-600 {
  background-color: #2f7ac0 !important;
}

.bg-primary-700 {
  background-color: #235c90 !important;
}

.bg-primary-800 {
  background-color: #183d60 !important;
}

.bg-primary-900 {
  background-color: #0c1f30 !important;
}

.border-primary-50 {
  border-color: #ebf5fe !important;
}

.border-primary-100 {
  border-color: #d8ebfc !important;
}

.border-primary-200 {
  border-color: #b1d6f9 !important;
}

.border-primary-300 {
  border-color: #89c2f6 !important;
}

.border-primary-400 {
  border-color: #62adf3 !important;
}

.border-primary-500 {
  border-color: #3b99f0 !important;
}

.border-primary-600 {
  border-color: #2f7ac0 !important;
}

.border-primary-700 {
  border-color: #235c90 !important;
}

.border-primary-800 {
  border-color: #183d60 !important;
}

.border-primary-900 {
  border-color: #0c1f30 !important;
}

.text-primary-50 {
  color: #ebf5fe !important;
}

.text-primary-100 {
  color: #d8ebfc !important;
}

.text-primary-200 {
  color: #b1d6f9 !important;
}

.text-primary-300 {
  color: #89c2f6 !important;
}

.text-primary-400 {
  color: #62adf3 !important;
}

.text-primary-500 {
  color: #3b99f0 !important;
}

.text-primary-600 {
  color: #2f7ac0 !important;
}

.text-primary-700 {
  color: #235c90 !important;
}

.text-primary-800 {
  color: #183d60 !important;
}

.text-primary-900 {
  color: #0c1f30 !important;
}

.dropdown-item {
  font-weight: 500;
}

.modal-footer {
  padding: 0.75rem 1.5rem;
}

.nav-tabs {
  flex-wrap: nowrap;
  overflow: auto hidden;
}
.nav-tabs::-webkit-scrollbar {
  border-radius: 10px;
  height: 7px;
  width: 7px;
}
.nav-tabs::-webkit-scrollbar-thumb {
  background: rgba(16, 24, 40, 0.2);
  border-radius: 10px;
}
.nav-tabs::-webkit-scrollbar-track {
  background-color: transparent;
}
.nav-tabs .nav-item {
  flex-shrink: 0;
}
.nav-tabs .nav-link {
  border-width: 0;
  padding: 0.5rem 0.75rem;
}
.nav-tabs .nav-link.active {
  border-bottom-width: 2px;
}

.nav-pills {
  flex-wrap: nowrap;
  overflow: auto hidden;
}
.nav-pills::-webkit-scrollbar {
  border-radius: 10px;
  height: 7px;
  width: 7px;
}
.nav-pills::-webkit-scrollbar-thumb {
  background: rgba(16, 24, 40, 0.2);
  border-radius: 10px;
}
.nav-pills::-webkit-scrollbar-track {
  background-color: transparent;
}
.nav-pills .nav-item {
  flex-shrink: 0;
}

.offcanvas-header {
  border-bottom: 1px solid #EAECF0;
}

.offcanvas-footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid #EAECF0;
}

ul.pagination {
  margin-bottom: 0;
}

.page-item {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.page-link {
  font-weight: 600;
}

.form-check-label {
  font-weight: 500;
}

/**
 * Spécifique .net
 */
.spinner-awesome {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 5rem;
  margin: 3rem auto;
}
.spinner-awesome:before, .spinner-awesome:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  animation: pulsOut 2.5s ease-in-out infinite;
  filter: drop-shadow(0 0 0.75rem rgba(255, 255, 255, 0.75));
}
.spinner-awesome:before {
  width: 100%;
  padding-bottom: 100%;
  box-shadow: inset 0 0 0 0.75rem #fff;
  animation-name: pulsIn;
}
.spinner-awesome:after {
  width: calc(100% - 1.5rem);
  padding-bottom: calc(100% - 1.5rem);
  box-shadow: 0 0 0 0 #fff;
}

@keyframes pulsIn {
  0% {
    box-shadow: inset 0 0 0 0.75rem #fff;
    opacity: 0.75;
  }
  50%, 100% {
    box-shadow: inset 0 0 0 0 #fff;
    opacity: 0;
  }
}
@keyframes pulsOut {
  0%, 50% {
    box-shadow: 0 0 0 0 #fff;
    opacity: 0;
  }
  100% {
    box-shadow: 0 0 0 0.75rem #fff;
    opacity: 0.75;
  }
}
.table {
  margin-bottom: 0;
}
.table thead {
  --bs-table-bg: var(--bs-gray-50);
}
.table thead th {
  color: #616a7e;
  font-size: 0.875em;
  font-weight: 500;
  padding: 0.75rem 1rem;
}
.table tfoot {
  --bs-table-bg: var(--bs-gray-50);
}
.table.table-sm thead th {
  padding: 0.5rem 0.5rem;
}

.table-flush tbody tr:last-child th, .table-flush tbody tr:last-child td {
  border-bottom: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.h1 {
  font-weight: 600;
}

.vr {
  color: #EAECF0;
}

html {
  overflow-y: scroll;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.body-wrapper {
  min-height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 992px) {
  .body-wrapper.layout-app-aside.app-aside-collapsed {
    padding-left: var(--app-aside-collapsed-width);
  }
  .body-wrapper.layout-app-aside:not(.app-aside-collapsed) {
    padding-left: var(--app-aside-width);
  }
}

@media (min-width: 1200px) {
  .body-wrapper.layout-split-view-vertical {
    max-height: 100vh;
  }
  .body-wrapper.layout-split-view-vertical main {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }
  .body-wrapper.layout-split-view-vertical main > * {
    flex-grow: 1;
    overflow: auto;
  }
  .body-wrapper.layout-split-view-vertical main > *::-webkit-scrollbar {
    border-radius: 10px;
    height: 7px;
    width: 7px;
  }
  .body-wrapper.layout-split-view-vertical main > *::-webkit-scrollbar-thumb {
    background: rgba(16, 24, 40, 0.2);
    border-radius: 10px;
  }
  .body-wrapper.layout-split-view-vertical main > *::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
@media (min-width: 992px) {
  .body-wrapper.layout-split-view-horizontal {
    max-height: 100vh;
  }
  .body-wrapper.layout-split-view-horizontal main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;
    overflow: hidden;
  }
  .body-wrapper.layout-split-view-horizontal main > * {
    flex-grow: 1;
    flex-basis: 0;
    overflow: auto;
  }
  .body-wrapper.layout-split-view-horizontal main > *::-webkit-scrollbar {
    border-radius: 10px;
    height: 7px;
    width: 7px;
  }
  .body-wrapper.layout-split-view-horizontal main > *::-webkit-scrollbar-thumb {
    background: rgba(16, 24, 40, 0.2);
    border-radius: 10px;
  }
  .body-wrapper.layout-split-view-horizontal main > *::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
app-aside,
.app-aside {
  --app-aside-bg: #FFF;
  --app-aside-color: #344054;
  --app-aside-border-width: 1px;
  --app-aside-border-color: #EAECF0;
  --app-aside-font-size: 0.875rem;
  --app-aside-scroll-rgb: 14, 24, 40;
  --app-aside-active-color: #3b99f0;
  --app-aside-lvl1-font-size: calc(var(--app-aside-font-size) * 1.143);
  --app-aside-lvl1-gap: calc(var(--app-aside-font-size) * .857);
  --app-aside-lvl1-padding: calc(var(--app-aside-font-size) * .57) calc(var(--app-aside-font-size) * .857);
  --app-aside-lvl1-radius: 0.375rem;
  --app-aside-lvl1-active-color: var(--app-aside-active-color);
  --app-aside-lvl1-active-bg: #e7f3fd;
  --app-aside-lvl1-icon-font-size: calc(var(--app-aside-font-size) * 1.429);
  --app-aside-lvl1-icon-opacity: .75;
  --app-aside-subnav-padding: calc(var(--app-aside-font-size) * .57) calc(var(--app-aside-font-size) * .143) calc(var(--app-aside-font-size) * 1.143) calc(var(--app-aside-font-size) * 3.143);
  --app-aside-subnav-gap: calc(var(--app-aside-font-size) * .57);
  --app-aside-collapsed-lvl1-icon-font-size: var(--app-aside-lvl1-icon-font-size);
  --app-aside-collapsed-lvl1-padding: calc(var(--app-aside-font-size) * 0.757) calc(var(--app-aside-font-size) * 0.57);
  --app-aside-collapsed-lvl1-hover-color: var(--app-aside-lvl1-active-color);
  --app-aside-collapsed-subnav-width: 250px;
  --app-aside-collapsed-subnav-title-color: var(--app-aside-active-color);
  --app-aside-collapsed-subnav-title-font-size: calc(var(--app-aside-font-size) * 1.125);
  --app-aside-collapsed-subnav-color: var(--app-aside-color);
  --app-aside-collapsed-subnav-active-color: var(--app-aside-active-color);
  --app-aside-collapsed-subnav-bg: var(--app-aside-bg);
  --app-aside-collapsed-subnav-padding: 1.5rem;
  --app-aside-collapsed-subnav-border-width: var(--app-aside-border-width);
  --app-aside-collapsed-subnav-border-color: var(--app-aside-border-color);
  --app-aside-collapsed-subnav-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  --app-aside-collapsed-subnav-radius: 0.375rem;
  background-color: var(--app-aside-bg);
  color: var(--app-aside-color);
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1031;
  display: flex;
  flex-direction: column;
  transform: translateX(-100%);
  font-size: var(--app-aside-font-size);
}
app-aside.app-aside-dark,
.app-aside.app-aside-dark {
  --app-aside-bg: #1D2939;
  --app-aside-scroll-rgb: 255, 255, 255;
  --app-aside-color: #d8ebfc;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #404653;
}
app-aside.app-aside-pink,
.app-aside.app-aside-pink {
  --app-aside-bg: #d4418a;
  --app-aside-color: #fbdaeb;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #f27fb8;
}
app-aside.app-aside-pink-light,
.app-aside.app-aside-pink-light {
  --app-aside-bg: #fef4f9;
  --app-aside-color: #7d2651;
  --app-aside-active-color: #EC4899;
  --app-aside-lvl1-active-bg: rgba(236, 72, 153, 0.1);
  --app-aside-border-color: #fce4f0;
}
app-aside.app-aside-fuchsia,
.app-aside.app-aside-fuchsia {
  --app-aside-bg: #c33fd7;
  --app-aside-color: #f7dafc;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #e47ef4;
}
app-aside.app-aside-fuchsia-light,
.app-aside.app-aside-fuchsia-light {
  --app-aside-bg: #fdf4fe;
  --app-aside-color: #73257f;
  --app-aside-active-color: #D946EF;
  --app-aside-lvl1-active-bg: rgba(217, 70, 239, 0.1);
  --app-aside-border-color: #f9e3fd;
}
app-aside.app-aside-purple,
.app-aside.app-aside-purple {
  --app-aside-bg: #724dc3;
  --app-aside-color: #e5ddf7;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #a589e4;
}
app-aside.app-aside-purple-light,
.app-aside.app-aside-purple-light {
  --app-aside-bg: #f7f5fd;
  --app-aside-color: #432e73;
  --app-aside-active-color: #7F56D9;
  --app-aside-lvl1-active-bg: rgba(127, 86, 217, 0.1);
  --app-aside-border-color: #ece6f9;
}
app-aside.app-aside-indigo,
.app-aside.app-aside-indigo {
  --app-aside-bg: #595cd9;
  --app-aside-color: #e0e0fc;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #9294f5;
}
app-aside.app-aside-indigo-light,
.app-aside.app-aside-indigo-light {
  --app-aside-bg: #f6f6fe;
  --app-aside-color: #343680;
  --app-aside-active-color: #6366F1;
  --app-aside-lvl1-active-bg: rgba(99, 102, 241, 0.1);
  --app-aside-border-color: #e8e8fd;
}
app-aside.app-aside-blue,
.app-aside.app-aside-blue {
  --app-aside-bg: #3575dd;
  --app-aside-color: #d8e6fd;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #76a8f9;
}
app-aside.app-aside-blue-light,
.app-aside.app-aside-blue-light {
  --app-aside-bg: #f3f8fe;
  --app-aside-color: #1f4582;
  --app-aside-active-color: #3B82F6;
  --app-aside-lvl1-active-bg: rgba(59, 130, 246, 0.1);
  --app-aside-border-color: #e2ecfe;
}
app-aside.app-aside-cyan,
.app-aside.app-aside-cyan {
  --app-aside-bg: #05a4bf;
  --app-aside-color: #cdf0f6;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #51cce1;
}
app-aside.app-aside-cyan-light,
.app-aside.app-aside-cyan-light {
  --app-aside-bg: #f0fbfc;
  --app-aside-color: #036070;
  --app-aside-active-color: #06B6D4;
  --app-aside-lvl1-active-bg: rgba(6, 182, 212, 0.1);
  --app-aside-border-color: #daf4f9;
}
app-aside.app-aside-teal,
.app-aside.app-aside-teal {
  --app-aside-bg: #12a695;
  --app-aside-color: #d0f1ed;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #5bcdc1;
}
app-aside.app-aside-teal-light,
.app-aside.app-aside-teal-light {
  --app-aside-bg: #f1fbfa;
  --app-aside-color: #0b6258;
  --app-aside-active-color: #14B8A6;
  --app-aside-lvl1-active-bg: rgba(20, 184, 166, 0.1);
  --app-aside-border-color: #dcf4f2;
}
app-aside.app-aside-green,
.app-aside.app-aside-green {
  --app-aside-bg: #36ac2e;
  --app-aside-color: #d8f2d6;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #77d270;
}
app-aside.app-aside-green-light,
.app-aside.app-aside-green-light {
  --app-aside-bg: #f3fbf3;
  --app-aside-color: #20651b;
  --app-aside-active-color: #3CBF33;
  --app-aside-lvl1-active-bg: rgba(60, 191, 51, 0.1);
  --app-aside-border-color: #e2f5e0;
}
app-aside.app-aside-lime,
.app-aside.app-aside-lime {
  --app-aside-bg: #77b814;
  --app-aside-color: #e6f5d0;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #a9db5c;
}
app-aside.app-aside-lime-light,
.app-aside.app-aside-lime-light {
  --app-aside-bg: #f8fcf1;
  --app-aside-color: #466c0c;
  --app-aside-active-color: #84CC16;
  --app-aside-lvl1-active-bg: rgba(132, 204, 22, 0.1);
  --app-aside-border-color: #edf7dc;
}
app-aside.app-aside-yellow,
.app-aside.app-aside-yellow {
  --app-aside-bg: #e1b813;
  --app-aside-color: #322904;
  --app-aside-active-color: #101828;
  --app-aside-lvl1-active-bg: rgba(16, 24, 40, 0.1);
  --app-aside-border-color: #af8f0f;
}
app-aside.app-aside-yellow-light,
.app-aside.app-aside-yellow-light {
  --app-aside-bg: #fffcf1;
  --app-aside-color: #856c0b;
  --app-aside-active-color: #FACC15;
  --app-aside-lvl1-active-bg: rgba(250, 204, 21, 0.1);
  --app-aside-border-color: #fef7dc;
}
app-aside.app-aside-orange,
.app-aside.app-aside-orange {
  --app-aside-bg: #e06814;
  --app-aside-color: #fee3d0;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #fb9d5c;
}
app-aside.app-aside-orange-light,
.app-aside.app-aside-orange-light {
  --app-aside-bg: #fff7f1;
  --app-aside-color: #843d0c;
  --app-aside-active-color: #F97316;
  --app-aside-lvl1-active-bg: rgba(249, 115, 22, 0.1);
  --app-aside-border-color: #feeadc;
}
app-aside.app-aside-red,
.app-aside.app-aside-red {
  --app-aside-bg: #c6303e;
  --app-aside-color: #f8d7da;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #e7727d;
}
app-aside.app-aside-red-light,
.app-aside.app-aside-red-light {
  --app-aside-bg: #fdf3f4;
  --app-aside-color: #751c25;
  --app-aside-active-color: #dc3545;
  --app-aside-lvl1-active-bg: rgba(220, 53, 69, 0.1);
  --app-aside-border-color: #fae1e3;
}
app-aside.app-aside-black,
.app-aside.app-aside-black {
  --app-aside-bg: black;
  --app-aside-color: #cccccc;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #4d4d4d;
}
app-aside.app-aside-black-light,
.app-aside.app-aside-black-light {
  --app-aside-bg: #f0f0f0;
  --app-aside-color: black;
  --app-aside-active-color: #000000;
  --app-aside-lvl1-active-bg: rgba(0, 0, 0, 0.1);
  --app-aside-border-color: #d9d9d9;
}
app-aside.app-aside-white,
.app-aside.app-aside-white {
  --app-aside-bg: #e6e6e6;
  --app-aside-color: #333333;
  --app-aside-active-color: #101828;
  --app-aside-lvl1-active-bg: rgba(16, 24, 40, 0.1);
  --app-aside-border-color: #b3b3b3;
}
app-aside.app-aside-white-light,
.app-aside.app-aside-white-light {
  --app-aside-bg: white;
  --app-aside-color: #878787;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: white;
}
app-aside.app-aside-gray,
.app-aside.app-aside-gray {
  --app-aside-bg: #404c5d;
  --app-aside-color: #dadde1;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #7e8795;
}
app-aside.app-aside-gray-light,
.app-aside.app-aside-gray-light {
  --app-aside-bg: #f4f5f6;
  --app-aside-color: #262d37;
  --app-aside-active-color: #475467;
  --app-aside-lvl1-active-bg: rgba(71, 84, 103, 0.1);
  --app-aside-border-color: #e3e5e8;
}
app-aside.app-aside-gray-dark,
.app-aside.app-aside-gray-dark {
  --app-aside-bg: #1a2533;
  --app-aside-color: #d2d4d7;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #616974;
}
app-aside.app-aside-gray-dark-light,
.app-aside.app-aside-gray-dark-light {
  --app-aside-bg: #f1f2f3;
  --app-aside-color: #0f161e;
  --app-aside-active-color: #1D2939;
  --app-aside-lvl1-active-bg: rgba(29, 41, 57, 0.1);
  --app-aside-border-color: #dddfe1;
}
app-aside.app-aside-slate,
.app-aside.app-aside-slate {
  --app-aside-bg: #384e75;
  --app-aside-color: #d8dde6;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #7889a8;
}
app-aside.app-aside-slate-light,
.app-aside.app-aside-slate-light {
  --app-aside-bg: #f3f5f8;
  --app-aside-color: #212e45;
  --app-aside-active-color: #3E5782;
  --app-aside-lvl1-active-bg: rgba(62, 87, 130, 0.1);
  --app-aside-border-color: #e2e6ec;
}
app-aside.app-aside-apple,
.app-aside.app-aside-apple {
  --app-aside-bg: #b7ca06;
  --app-aside-color: #292d01;
  --app-aside-active-color: #101828;
  --app-aside-lvl1-active-bg: rgba(16, 24, 40, 0.1);
  --app-aside-border-color: #8e9d05;
}
app-aside.app-aside-apple-light,
.app-aside.app-aside-apple-light {
  --app-aside-bg: #fcfdf0;
  --app-aside-color: #6c7704;
  --app-aside-active-color: #CBE007;
  --app-aside-lvl1-active-bg: rgba(203, 224, 7, 0.1);
  --app-aside-border-color: #f7fada;
}
app-aside.app-aside-amber,
.app-aside.app-aside-amber {
  --app-aside-bg: #e6a422;
  --app-aside-color: #fff0d4;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #ffcc67;
}
app-aside.app-aside-amber-light,
.app-aside.app-aside-amber-light {
  --app-aside-bg: #fffbf2;
  --app-aside-color: #876014;
  --app-aside-active-color: #FFB626;
  --app-aside-lvl1-active-bg: rgba(255, 182, 38, 0.1);
  --app-aside-border-color: #fff4de;
}
app-aside.app-aside-ruby,
.app-aside.app-aside-ruby {
  --app-aside-bg: #bf2b45;
  --app-aside-color: #f6d6db;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #e16e82;
}
app-aside.app-aside-ruby-light,
.app-aside.app-aside-ruby-light {
  --app-aside-bg: #fcf3f4;
  --app-aside-color: #701929;
  --app-aside-active-color: #D4304D;
  --app-aside-lvl1-active-bg: rgba(212, 48, 77, 0.1);
  --app-aside-border-color: #f9e0e4;
}
app-aside.app-aside-coral,
.app-aside.app-aside-coral {
  --app-aside-bg: #e66052;
  --app-aside-color: #ffe1de;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #ff978c;
}
app-aside.app-aside-coral-light,
.app-aside.app-aside-coral-light {
  --app-aside-bg: #fff6f5;
  --app-aside-color: #873930;
  --app-aside-active-color: #FF6B5B;
  --app-aside-lvl1-active-bg: rgba(255, 107, 91, 0.1);
  --app-aside-border-color: #ffe9e6;
}
app-aside.app-aside-brown,
.app-aside.app-aside-brown {
  --app-aside-bg: #a24b08;
  --app-aside-color: #f0ddce;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #cb8753;
}
app-aside.app-aside-brown-light,
.app-aside.app-aside-brown-light {
  --app-aside-bg: #fbf5f0;
  --app-aside-color: #5f2c05;
  --app-aside-active-color: #B45309;
  --app-aside-lvl1-active-bg: rgba(180, 83, 9, 0.1);
  --app-aside-border-color: #f4e5da;
}
app-aside.app-aside-coffee,
.app-aside.app-aside-coffee {
  --app-aside-bg: #702910;
  --app-aside-color: #e5d5d0;
  --app-aside-active-color: #FFF;
  --app-aside-lvl1-active-bg: rgba(255, 255, 255, 0.1);
  --app-aside-border-color: #a36c59;
}
app-aside.app-aside-coffee-light,
.app-aside.app-aside-coffee-light {
  --app-aside-bg: #f7f2f1;
  --app-aside-color: #42180a;
  --app-aside-active-color: #7C2D12;
  --app-aside-lvl1-active-bg: rgba(124, 45, 18, 0.1);
  --app-aside-border-color: #ebe0db;
}
@media (min-width: 576px) {
  app-aside,
.app-aside {
    width: var(--app-aside-width);
    border-right: var(--app-aside-border-width) solid var(--app-aside-border-color);
  }
}
@media (min-width: 992px) {
  app-aside,
.app-aside {
    transform: none;
  }
}
app-aside.opened,
.app-aside.opened {
  transform: none;
}
app-aside .toggle-app-aside,
.app-aside .toggle-app-aside {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: var(--app-aside-color);
  opacity: 0.5;
  font-size: 1.5rem;
}
@media (min-width: 992px) {
  app-aside .toggle-app-aside,
.app-aside .toggle-app-aside {
    display: none;
  }
}
app-aside .app-aside-header,
.app-aside .app-aside-header {
  padding: 1.5rem;
}
app-aside .app-aside-header .brand,
.app-aside .app-aside-header .brand {
  display: block;
  font-weight: 600;
  color: var(--app-aside-accent-color);
  font-size: calc(var(--app-aside-font-size) * 1.45);
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-aside .app-aside-header .brand svg,
.app-aside .app-aside-header .brand svg {
  max-width: 100%;
}
app-aside .app-aside-footer,
.app-aside .app-aside-footer {
  padding: 1.5rem;
}
app-aside .app-aside-collapse-cta,
.app-aside .app-aside-collapse-cta {
  display: none;
}
@media (min-width: 992px) {
  app-aside .app-aside-collapse-cta,
.app-aside .app-aside-collapse-cta {
    display: block;
  }
}
app-aside .app-aside-collapse-cta .btn-collapse,
app-aside .app-aside-collapse-cta .btn-uncollapse,
.app-aside .app-aside-collapse-cta .btn-collapse,
.app-aside .app-aside-collapse-cta .btn-uncollapse {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  opacity: 0.75;
  font-size: var(--app-aside-font-size);
  padding: 0.75rem 0.5rem;
  border-top: 1px solid var(--app-aside-border-color);
  color: var(--app-aside-color);
  width: 100%;
}
app-aside .app-aside-collapse-cta .btn-collapse i,
app-aside .app-aside-collapse-cta .btn-uncollapse i,
.app-aside .app-aside-collapse-cta .btn-collapse i,
.app-aside .app-aside-collapse-cta .btn-uncollapse i {
  font-size: calc(var(--app-aside-font-size) * 1.5);
}
app-aside .app-aside-collapse-cta .btn-uncollapse,
.app-aside .app-aside-collapse-cta .btn-uncollapse {
  display: none;
}
app-aside .app-aside-body,
.app-aside .app-aside-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 0.75rem 0.5rem;
  margin: 0 0.25rem;
}
app-aside .app-aside-body::-webkit-scrollbar,
.app-aside .app-aside-body::-webkit-scrollbar {
  border-radius: 10px;
  height: 5px;
  width: 5px;
}
app-aside .app-aside-body::-webkit-scrollbar-thumb,
.app-aside .app-aside-body::-webkit-scrollbar-thumb {
  background: rgba(var(--app-aside-scroll-rgb), 0);
  border-radius: 10px;
  transition: all ease-out 0.3s;
}
app-aside .app-aside-body::-webkit-scrollbar-track,
.app-aside .app-aside-body::-webkit-scrollbar-track {
  background-color: transparent;
}
app-aside .app-aside-body:hover::-webkit-scrollbar-thumb,
.app-aside .app-aside-body:hover::-webkit-scrollbar-thumb {
  background: rgba(var(--app-aside-scroll-rgb), 0.1);
}
app-aside nav,
.app-aside nav {
  flex-grow: 1;
}
app-aside nav ul,
.app-aside nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
app-aside nav ul li.has-children > a:after,
.app-aside nav ul li.has-children > a:after {
  content: "\eb3a";
  font-family: "unicons-line", sans-serif;
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
}
app-aside nav ul li.has-children > .sub-nav,
.app-aside nav ul li.has-children > .sub-nav {
  display: none;
}
app-aside nav ul li.has-children > .sub-nav > .sub-nav-title,
.app-aside nav ul li.has-children > .sub-nav > .sub-nav-title {
  display: none;
}
app-aside nav ul li.has-children.opened > .sub-nav,
.app-aside nav ul li.has-children.opened > .sub-nav {
  display: flex;
  flex-direction: column;
}
app-aside nav a,
.app-aside nav a {
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-property: color, background-color;
}
app-aside nav > ul,
.app-aside nav > ul {
  display: flex;
  flex-direction: column;
  gap: calc(var(--app-aside-font-size) * 0.285);
}
app-aside nav > ul > li > a,
.app-aside nav > ul > li > a {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--app-aside-lvl1-padding);
  border-radius: var(--app-aside-lvl1-radius);
  color: var(--app-aside-color);
  text-decoration: none;
  gap: var(--app-aside-lvl1-gap);
  font-size: var(--app-aside-lvl1-font-size);
  font-weight: 500;
}
app-aside nav > ul > li > a i,
.app-aside nav > ul > li > a i {
  opacity: var(--app-aside-lvl1-icon-opacity);
  font-size: var(--app-aside-lvl1-icon-font-size);
  line-height: 1;
}
app-aside nav > ul > li > a:hover,
.app-aside nav > ul > li > a:hover {
  color: var(--app-aside-active-color);
}
app-aside nav > ul > li.title,
.app-aside nav > ul > li.title {
  text-transform: uppercase;
  padding: calc(var(--app-aside-font-size) * 0.57) calc(var(--app-aside-font-size) * 0.857);
  font-weight: 500;
  opacity: 0.72;
  font-size: calc(var(--app-aside-font-size) * 0.928);
  margin-top: calc(var(--app-aside-font-size) * 0.285);
  color: var(--app-aside-color);
}
app-aside nav > ul > li.active > a,
.app-aside nav > ul > li.active > a {
  position: relative;
  font-weight: 600;
  color: var(--app-aside-lvl1-active-color);
}
app-aside nav > ul > li.active > a > *,
.app-aside nav > ul > li.active > a > * {
  position: relative;
  z-index: 1;
}
app-aside nav > ul > li.active > a i,
.app-aside nav > ul > li.active > a i {
  opacity: 1;
}
app-aside nav > ul > li.active > a:before,
.app-aside nav > ul > li.active > a:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--app-aside-lvl1-active-bg);
  border-radius: var(--app-aside-lvl1-radius);
  z-index: 0;
}
app-aside nav > ul > li > .sub-nav > ul,
.app-aside nav > ul > li > .sub-nav > ul {
  padding: var(--app-aside-subnav-padding);
  display: flex;
  flex-direction: column;
  gap: var(--app-aside-subnav-gap);
}
app-aside nav > ul > li > .sub-nav > ul a,
.app-aside nav > ul > li > .sub-nav > ul a {
  position: relative;
  display: block;
  font-weight: 500;
  text-decoration: none;
  color: var(--app-aside-color);
}
app-aside nav > ul > li > .sub-nav > ul a:hover,
.app-aside nav > ul > li > .sub-nav > ul a:hover {
  color: var(--app-aside-active-color);
}
app-aside nav > ul > li > .sub-nav > ul li.active > a,
.app-aside nav > ul > li > .sub-nav > ul li.active > a {
  font-weight: 600;
  color: var(--app-aside-active-color);
}
app-aside nav > ul > li > .sub-nav > ul > li > .sub-nav > ul,
.app-aside nav > ul > li > .sub-nav > ul > li > .sub-nav > ul {
  padding: calc(var(--app-aside-font-size) * 0.57) 0 calc(var(--app-aside-font-size) * 0.57) calc(var(--app-aside-font-size) * 0.857);
  display: flex;
  flex-direction: column;
  gap: calc(var(--app-aside-font-size) * 0.285);
}
app-aside nav > ul > li > .sub-nav > ul > li > .sub-nav > ul li.active > a,
.app-aside nav > ul > li > .sub-nav > ul > li > .sub-nav > ul li.active > a {
  font-weight: 600;
  color: var(--app-aside-active-color);
}

@media (min-width: 992px) {
  .app-aside-collapsed app-aside,
.app-aside-collapsed .app-aside {
    width: var(--app-aside-collapsed-width);
  }
  .app-aside-collapsed app-aside .app-aside-header,
.app-aside-collapsed .app-aside .app-aside-header {
    padding: 1.25rem 0.75rem;
  }
  .app-aside-collapsed app-aside .app-aside-header > *:not(.brand),
.app-aside-collapsed .app-aside .app-aside-header > *:not(.brand) {
    display: none;
  }
  .app-aside-collapsed app-aside .app-aside-header .brand,
.app-aside-collapsed .app-aside .app-aside-header .brand {
    text-align: center;
    font-size: calc(var(--app-aside-font-size) * 1.25);
  }
  .app-aside-collapsed app-aside .app-aside-body,
.app-aside-collapsed .app-aside .app-aside-body {
    padding: 1rem 0.5rem;
    margin: 0;
  }
  .app-aside-collapsed app-aside .app-aside-collapse-cta .btn-collapse,
.app-aside-collapsed .app-aside .app-aside-collapse-cta .btn-collapse {
    display: none;
  }
  .app-aside-collapsed app-aside .app-aside-collapse-cta .btn-uncollapse,
.app-aside-collapsed .app-aside .app-aside-collapse-cta .btn-uncollapse {
    display: flex;
  }
  .app-aside-collapsed app-aside .app-aside-footer,
.app-aside-collapsed .app-aside .app-aside-footer {
    display: none;
  }
  .app-aside-collapsed app-aside nav > ul > li.title,
.app-aside-collapsed .app-aside nav > ul > li.title {
    display: none;
  }
  .app-aside-collapsed app-aside nav > ul > li.has-children > a:after,
.app-aside-collapsed .app-aside nav > ul > li.has-children > a:after {
    display: none;
  }
  .app-aside-collapsed app-aside nav > ul > li,
.app-aside-collapsed .app-aside nav > ul > li {
    --sub-top: 0;
    --title-top: 0;
    position: relative;
  }
  .app-aside-collapsed app-aside nav > ul > li > a,
.app-aside-collapsed .app-aside nav > ul > li > a {
    padding: var(--app-aside-collapsed-lvl1-padding);
    justify-content: center;
  }
  .app-aside-collapsed app-aside nav > ul > li > a span,
.app-aside-collapsed .app-aside nav > ul > li > a span {
    display: none;
  }
  .app-aside-collapsed app-aside nav > ul > li > a i,
.app-aside-collapsed .app-aside nav > ul > li > a i {
    font-size: var(--app-aside-collapsed-lvl1-icon-font-size);
  }
  .app-aside-collapsed app-aside nav > ul > li > .sub-nav,
.app-aside-collapsed .app-aside nav > ul > li > .sub-nav {
    position: fixed;
    z-index: 1031;
    left: calc(var(--app-aside-collapsed-width) + 0.5rem);
    top: var(--sub-top);
    width: var(--app-aside-collapsed-subnav-width);
    background-color: var(--app-aside-collapsed-subnav-bg);
    border-radius: var(--app-aside-collapsed-subnav-radius);
    border: var(--app-aside-collapsed-subnav-border-width) solid var(--app-aside-collapsed-subnav-border-color);
    box-shadow: var(--app-aside-collapsed-subnav-shadow);
    padding: var(--app-aside-collapsed-subnav-padding);
    gap: 1rem;
    display: none !important;
    flex-direction: column;
  }
  .app-aside-collapsed app-aside nav > ul > li > .sub-nav > .sub-nav-title,
.app-aside-collapsed .app-aside nav > ul > li > .sub-nav > .sub-nav-title {
    display: block;
    font-weight: 600;
    color: var(--app-aside-collapsed-subnav-title-color);
    font-size: var(--app-aside-collapsed-subnav-title-font-size);
  }
  .app-aside-collapsed app-aside nav > ul > li > .sub-nav > ul,
.app-aside-collapsed .app-aside nav > ul > li > .sub-nav > ul {
    padding: 0;
    gap: 0.5rem;
  }
  .app-aside-collapsed app-aside nav > ul > li > .sub-nav > ul li.has-children > a:after,
.app-aside-collapsed .app-aside nav > ul > li > .sub-nav > ul li.has-children > a:after {
    right: 0;
  }
  .app-aside-collapsed app-aside nav > ul > li > .sub-nav > ul li.active a,
.app-aside-collapsed .app-aside nav > ul > li > .sub-nav > ul li.active a {
    color: var(--app-aside-collapsed-subnav-active-color);
  }
  .app-aside-collapsed app-aside nav > ul > li > .sub-nav > ul a,
.app-aside-collapsed .app-aside nav > ul > li > .sub-nav > ul a {
    color: var(--app-aside-collapsed-subnav-color);
  }
  .app-aside-collapsed app-aside nav > ul > li > .sub-nav > ul a:hover,
.app-aside-collapsed .app-aside nav > ul > li > .sub-nav > ul a:hover {
    color: var(--app-aside-collapsed-subnav-active-color);
  }
  .app-aside-collapsed app-aside nav > ul > li:hover > .sub-nav,
.app-aside-collapsed .app-aside nav > ul > li:hover > .sub-nav {
    display: flex !important;
  }
  .app-aside-collapsed app-aside nav > ul > li:hover > .sub-nav:before,
.app-aside-collapsed .app-aside nav > ul > li:hover > .sub-nav:before {
    display: block;
    content: "";
    background-color: transparent;
    width: 1.5rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }
  .app-aside-collapsed app-aside nav > ul > li:not(.active):hover > a,
.app-aside-collapsed .app-aside nav > ul > li:not(.active):hover > a {
    color: var(--app-aside-collapsed-lvl1-hover-color);
  }
  .app-aside-collapsed app-aside nav > ul > li:not(.has-children):hover > a span,
.app-aside-collapsed .app-aside nav > ul > li:not(.has-children):hover > a span {
    display: block;
    position: fixed;
    z-index: 1031;
    left: calc(var(--app-aside-collapsed-width) + 0.5rem);
    top: var(--title-top);
    background-color: var(--app-aside-collapsed-subnav-bg);
    border: var(--app-aside-collapsed-subnav-border-width) solid var(--app-aside-collapsed-subnav-border-color);
    box-shadow: var(--app-aside-collapsed-subnav-shadow);
    border-radius: var(--app-aside-collapsed-subnav-radius);
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    color: var(--app-aside-collapsed-subnav-color);
  }
}

.avatar {
  --avatar-size: 40px;
  --avatar-bg: #e6f2fd;
  --avatar-color: #358ad8;
  --avatar-border-radius: 50%;
  position: relative;
  width: var(--avatar-size);
  height: var(--avatar-size);
  font-size: calc(var(--avatar-size) / 2.84);
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  text-align: center;
  text-transform: uppercase;
  vertical-align: bottom;
  user-select: none;
  background-color: var(--avatar-bg);
  color: var(--avatar-color);
  border-radius: var(--avatar-border-radius);
  text-decoration: none;
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: var(--avatar-border-radius);
}

.avatar-xs {
  --avatar-size: 24px;
}

.avatar-sm {
  --avatar-size: 32px;
}

.avatar-md {
  --avatar-size: 40px;
}

.avatar-lg {
  --avatar-size: 48px;
}

.avatar-xl {
  --avatar-size: 56px;
}

.avatar-xxl {
  --avatar-size: 64px;
}

.metas-grid {
  --item-min-width: 100px;
  --item-max-width: 120px;
  --gap: 1rem;
  display: grid;
  gap: var(--gap);
  grid-template-columns: repeat(auto-fill, minmax(max(var(--item-min-width), var(--item-max-width)), 1fr));
}

.progress-circle {
  --circle-color: var(--bs-primary);
  --value: 0;
  --size: 5rem;
  --thickness: calc(var(--size) / 10);
  --font-size: calc(var(--size) / 4.5);
  position: relative;
  display: inline-grid;
  flex-shrink: 0;
  height: var(--size);
  width: var(--size);
  min-height: var(--size);
  min-width: var(--size);
  place-content: center;
  border-radius: 9999px;
  background-color: transparent;
  vertical-align: middle;
  box-sizing: content-box;
  font-weight: 500;
  font-size: var(--font-size);
}
.progress-circle:before, .progress-circle:after {
  position: absolute;
  border-radius: 9999px;
  content: "";
}
.progress-circle:before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(farthest-side, var(--circle-color) 98%, #0000) top/var(--thickness) var(--thickness) no-repeat, conic-gradient(var(--circle-color) calc(var(--value) * 1%), #0000 0);
  mask: radial-gradient(farthest-side, #0000 calc(99% - var(--thickness)), #000 calc(100% - var(--thickness)));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--thickness)), #000 calc(100% - var(--thickness)));
}
.progress-circle:after {
  inset: calc(50% - var(--thickness) / 2);
  transform: rotate(calc(var(--value) * 3.6deg - 90deg)) translate(calc(var(--size) / 2 - 50%));
  background-color: var(--circle-color);
}

.progress-circle-primary {
  --circle-color: #3b99f0;
}

.progress-circle-secondary {
  --circle-color: #667085;
}

.progress-circle-success {
  --circle-color: #3CBF33;
}

.progress-circle-info {
  --circle-color: #3B82F6;
}

.progress-circle-warning {
  --circle-color: #F97316;
}

.progress-circle-danger {
  --circle-color: #dc3545;
}

.progress-circle-light {
  --circle-color: #F2F4F7;
}

.progress-circle-dark {
  --circle-color: #101828;
}

.progress-circle-pink {
  --circle-color: #EC4899;
}

.progress-circle-fuchsia {
  --circle-color: #D946EF;
}

.progress-circle-purple {
  --circle-color: #7F56D9;
}

.progress-circle-indigo {
  --circle-color: #6366F1;
}

.progress-circle-blue {
  --circle-color: #3B82F6;
}

.progress-circle-cyan {
  --circle-color: #06B6D4;
}

.progress-circle-teal {
  --circle-color: #14B8A6;
}

.progress-circle-green {
  --circle-color: #3CBF33;
}

.progress-circle-lime {
  --circle-color: #84CC16;
}

.progress-circle-yellow {
  --circle-color: #FACC15;
}

.progress-circle-orange {
  --circle-color: #F97316;
}

.progress-circle-red {
  --circle-color: #dc3545;
}

.progress-circle-black {
  --circle-color: #000000;
}

.progress-circle-white {
  --circle-color: #FFF;
}

.progress-circle-gray {
  --circle-color: #475467;
}

.progress-circle-gray-dark {
  --circle-color: #1D2939;
}

.progress-circle-slate {
  --circle-color: #3E5782;
}

.progress-circle-apple {
  --circle-color: #CBE007;
}

.progress-circle-amber {
  --circle-color: #FFB626;
}

.progress-circle-ruby {
  --circle-color: #D4304D;
}

.progress-circle-coral {
  --circle-color: #FF6B5B;
}

.progress-circle-brown {
  --circle-color: #B45309;
}

.progress-circle-coffee {
  --circle-color: #7C2D12;
}

.table.table-cards {
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}
.table.table-cards th, .table.table-cards td {
  border: 0;
}
.table.table-cards thead td,
.table.table-cards thead th {
  padding-bottom: 0.25rem;
}
.table.table-cards tbody tr {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: var(--bs-border-radius);
  background-color: #FFF;
}
.table.table-cards tbody tr td:first-child,
.table.table-cards tbody tr th:first-child {
  border-radius: var(--bs-border-radius) 0 0 var(--bs-border-radius);
}
.table.table-cards tbody tr td:last-child,
.table.table-cards tbody tr th:last-child {
  border-radius: 0 var(--bs-border-radius) var(--bs-border-radius) 0;
}

ul.timeline {
  --timeline-node-width: 5rem;
  list-style-type: none;
  padding: 0;
}
ul.timeline > li {
  display: flex;
}
ul.timeline > li > .node {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--timeline-node-width);
  flex-shrink: 0;
}
ul.timeline > li > .node:before {
  display: block;
  content: "";
  width: 2px;
  height: 0.5rem;
  background-color: #EAECF0;
  flex-shrink: 0;
}
ul.timeline > li > .node:after {
  display: block;
  content: "";
  width: 2px;
  flex-grow: 1;
  background-color: #EAECF0;
  flex-shrink: 0;
}
ul.timeline > li > .content {
  flex-grow: 1;
  padding-top: 0.25rem;
  padding-bottom: 1rem;
}
ul.timeline > li:first-child > .node:before {
  background-color: transparent;
}
ul.timeline > li:last-child > .node:after {
  background-color: transparent;
}

.wizard {
  --node-size: 2rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.wizard .step {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 0.75rem;
  flex-basis: 100%;
  color: inherit;
  text-decoration: none;
}
.wizard .step .node {
  position: relative;
  width: var(--node-size);
  height: var(--node-size);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 2px solid #EAECF0;
}
.wizard .step .node:before {
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #EAECF0;
  color: #EAECF0;
  font-size: 1.25rem;
}
.wizard .step:not(:last-child):after {
  display: block;
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  top: calc(var(--node-size) / 2);
  left: 50%;
  transform: translateX(calc(var(--node-size) / 2));
  background-color: #EAECF0;
}
.wizard .step.completed .node, .wizard .step.active .node {
  border-color: #3b99f0;
  background-color: #e2f0fd;
}
.wizard .step.completed .node:before, .wizard .step.active .node:before {
  background-color: #3b99f0;
}
.wizard .step.completed:after {
  background-color: #3b99f0;
}
.wizard .step.completed .node:before {
  font-family: unicons-line, sans-serif;
  content: "\e9c3";
  width: auto;
  height: auto;
  border-radius: 0;
  background-color: transparent;
  color: #3b99f0;
}
.wizard .step.completed.error .node {
  border-color: #dc3545;
  background-color: #fae1e3;
}
.wizard .step.completed.error .node:before {
  content: "\ec9d";
  color: #dc3545;
}
.wizard .step.completed.error:after {
  background-color: #dc3545;
}
.wizard .step.completed.success .node {
  border-color: #3CBF33;
  background-color: #e2f5e0;
}
.wizard .step.completed.success .node:before {
  color: #3CBF33;
}
.wizard .step.completed.success:after {
  background-color: #3CBF33;
}
.wizard .step.active {
  color: #3b99f0;
}
.wizard .step.active .node {
  box-shadow: 0 0 0 3px #e2f0fd;
}
@media (min-width: 992px) {
  .wizard.wizard-vertical {
    flex-direction: column;
    gap: 1rem;
  }
  .wizard.wizard-vertical .step {
    flex-direction: row;
    gap: 1rem;
    flex-basis: 100%;
    text-align: left;
  }
  .wizard.wizard-vertical .step:not(:last-child):after {
    height: calc(100% - var(--node-size) / 2);
    width: 2px;
    top: 50%;
    left: calc(var(--node-size) / 2);
    transform: translateX(0) translateY(calc(var(--node-size) / 2));
  }
}

@media (max-width: 767.98px) {
  .wizard .step > *:not(.node) {
    display: none;
  }
}
.toast-title {
  font-weight: bold;
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #ffffff;
}

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/
}

#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#toast-container > div {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999999;
  -webkit-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

#toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000000;
  -webkit-box-shadow: 0 0 12px #000000;
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}

#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}

#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}

#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast {
  background-color: #030303;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
/*@import "~bedrock/scss/plugins/sweetalert2.scss";*/
.swal2-popup.swal2-toast {
  box-sizing: border-box;
  grid-column: 1/4 !important;
  grid-row: 1/4 !important;
  grid-template-columns: 1fr 99fr 1fr;
  padding: 1em;
  overflow-y: hidden;
  background: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.075), 0 1px 2px rgba(0, 0, 0, 0.075), 1px 2px 4px rgba(0, 0, 0, 0.075), 1px 3px 8px rgba(0, 0, 0, 0.075), 2px 4px 16px rgba(0, 0, 0, 0.075);
  pointer-events: all;
}
.swal2-popup.swal2-toast > * {
  grid-column: 2;
}
.swal2-popup.swal2-toast .swal2-title {
  margin: 0.5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial;
}
.swal2-popup.swal2-toast .swal2-loading {
  justify-content: center;
}
.swal2-popup.swal2-toast .swal2-input {
  height: 2em;
  margin: 0.5em;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-validation-message {
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-footer {
  margin: 0.5em 0 0;
  padding: 0.5em 0 0;
  font-size: 0.8em;
}
.swal2-popup.swal2-toast .swal2-close {
  grid-column: 3/3;
  grid-row: 1/99;
  align-self: center;
  width: 0.8em;
  height: 0.8em;
  margin: 0;
  font-size: 2em;
}
.swal2-popup.swal2-toast .swal2-html-container {
  margin: 0.5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial;
}
.swal2-popup.swal2-toast .swal2-html-container:empty {
  padding: 0;
}
.swal2-popup.swal2-toast .swal2-loader {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  height: 2em;
  margin: 0.25em;
}
.swal2-popup.swal2-toast .swal2-icon {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0 0.5em 0 0;
}
.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 1.8em;
  font-weight: bold;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  top: 0.875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-actions {
  justify-content: flex-start;
  height: auto;
  margin: 0;
  margin-top: 0.5em;
  padding: 0 0.5em;
}
.swal2-popup.swal2-toast .swal2-styled {
  margin: 0.25em 0.5em;
  padding: 0.4em 0.6em;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 1.6em;
  height: 3em;
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -0.8em;
  left: -0.5em;
  transform: rotate(-45deg);
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -0.25em;
  left: 0.9375em;
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: 0.4375em;
  width: 0.4375em;
  height: 2.6875em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line] {
  height: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=tip] {
  top: 1.125em;
  left: 0.1875em;
  width: 0.75em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=long] {
  top: 0.9375em;
  right: 0.1875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-toast-animate-success-line-tip 0.75s;
}
.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-toast-animate-success-line-long 0.75s;
}
.swal2-popup.swal2-toast.swal2-show {
  animation: swal2-toast-show 0.5s;
}
.swal2-popup.swal2-toast.swal2-hide {
  animation: swal2-toast-hide 0.1s forwards;
}

.swal2-container {
  display: grid;
  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  grid-template-areas: "top-start     top            top-end" "center-start  center         center-end" "bottom-start  bottom-center  bottom-end";
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto);
  height: 100%;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
}
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
.swal2-container.swal2-backdrop-hide {
  background: transparent !important;
}
.swal2-container.swal2-top-start, .swal2-container.swal2-center-start, .swal2-container.swal2-bottom-start {
  grid-template-columns: minmax(0, 1fr) auto auto;
}
.swal2-container.swal2-top, .swal2-container.swal2-center, .swal2-container.swal2-bottom {
  grid-template-columns: auto minmax(0, 1fr) auto;
}
.swal2-container.swal2-top-end, .swal2-container.swal2-center-end, .swal2-container.swal2-bottom-end {
  grid-template-columns: auto auto minmax(0, 1fr);
}
.swal2-container.swal2-top-start > .swal2-popup {
  align-self: start;
}
.swal2-container.swal2-top > .swal2-popup {
  grid-column: 2;
  align-self: start;
  justify-self: center;
}
.swal2-container.swal2-top-end > .swal2-popup, .swal2-container.swal2-top-right > .swal2-popup {
  grid-column: 3;
  align-self: start;
  justify-self: end;
}
.swal2-container.swal2-center-start > .swal2-popup, .swal2-container.swal2-center-left > .swal2-popup {
  grid-row: 2;
  align-self: center;
}
.swal2-container.swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center;
}
.swal2-container.swal2-center-end > .swal2-popup, .swal2-container.swal2-center-right > .swal2-popup {
  grid-column: 3;
  grid-row: 2;
  align-self: center;
  justify-self: end;
}
.swal2-container.swal2-bottom-start > .swal2-popup, .swal2-container.swal2-bottom-left > .swal2-popup {
  grid-column: 1;
  grid-row: 3;
  align-self: end;
}
.swal2-container.swal2-bottom > .swal2-popup {
  grid-column: 2;
  grid-row: 3;
  justify-self: center;
  align-self: end;
}
.swal2-container.swal2-bottom-end > .swal2-popup, .swal2-container.swal2-bottom-right > .swal2-popup {
  grid-column: 3;
  grid-row: 3;
  align-self: end;
  justify-self: end;
}
.swal2-container.swal2-grow-row > .swal2-popup, .swal2-container.swal2-grow-fullscreen > .swal2-popup {
  grid-column: 1/4;
  width: 100%;
}
.swal2-container.swal2-grow-column > .swal2-popup, .swal2-container.swal2-grow-fullscreen > .swal2-popup {
  grid-row: 1/4;
  align-self: stretch;
}
.swal2-container.swal2-no-transition {
  transition: none !important;
}

.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem;
}
.swal2-popup:focus {
  outline: none;
}
.swal2-popup.swal2-loading {
  overflow-y: hidden;
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0;
  color: inherit;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

.swal2-actions {
  display: flex;
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 1.25em auto 0;
  padding: 0;
}
.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: 0.4;
}
.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.swal2-loader {
  display: none;
  align-items: center;
  justify-content: center;
  width: 2.2em;
  height: 2.2em;
  margin: 0 1.875em;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border-width: 0.25em;
  border-style: solid;
  border-radius: 100%;
  border-color: #2778c4 transparent #2778c4 transparent;
}

.swal2-styled {
  margin: 0.3125em;
  padding: 0.625em 1.1em;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500;
}
.swal2-styled:not([disabled]) {
  cursor: pointer;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #7066e0;
  color: #fff;
  font-size: 1em;
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgba(112, 102, 224, 0.5);
}
.swal2-styled.swal2-deny {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #dc3741;
  color: #fff;
  font-size: 1em;
}
.swal2-styled.swal2-deny:focus {
  box-shadow: 0 0 0 3px rgba(220, 55, 65, 0.5);
}
.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #6e7881;
  color: #fff;
  font-size: 1em;
}
.swal2-styled.swal2-cancel:focus {
  box-shadow: 0 0 0 3px rgba(110, 120, 129, 0.5);
}
.swal2-styled.swal2-default-outline:focus {
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-styled:focus {
  outline: none;
}
.swal2-styled::-moz-focus-inner {
  border: 0;
}

.swal2-footer {
  justify-content: center;
  margin: 1em 0 0;
  padding: 1em 1em 0;
  border-top: 1px solid #eee;
  color: inherit;
  font-size: 1em;
}

.swal2-timer-progress-bar-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  grid-column: auto !important;
  height: 0.25em;
  overflow: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.swal2-timer-progress-bar {
  width: 100%;
  height: 0.25em;
  background: rgba(0, 0, 0, 0.2);
}

.swal2-image {
  max-width: 100%;
  margin: 2em auto 1em;
}

.swal2-close {
  z-index: 2;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: -1.2em;
  padding: 0;
  overflow: hidden;
  transition: color 0.1s, box-shadow 0.1s;
  border: none;
  border-radius: 5px;
  background: transparent;
  color: #ccc;
  font-family: serif;
  font-family: monospace;
  font-size: 2.5em;
  cursor: pointer;
  justify-self: end;
}
.swal2-close:hover {
  transform: none;
  background: transparent;
  color: #f27474;
}
.swal2-close:focus {
  outline: none;
  box-shadow: inset 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-close::-moz-focus-inner {
  border: 0;
}

.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em 0.3em;
  padding: 0;
  overflow: auto;
  color: inherit;
  font-size: 1.125em;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
}

.swal2-input,
.swal2-file,
.swal2-textarea,
.swal2-select,
.swal2-radio,
.swal2-checkbox {
  margin: 1em 2em 0;
}

.swal2-input,
.swal2-file,
.swal2-textarea {
  box-sizing: border-box;
  width: auto;
  transition: border-color 0.1s, box-shadow 0.1s;
  border: 1px solid #d9d9d9;
  border-radius: 0.1875em;
  background: inherit;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px transparent;
  color: inherit;
  font-size: 1.125em;
}
.swal2-input.swal2-inputerror,
.swal2-file.swal2-inputerror,
.swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important;
}
.swal2-input:focus,
.swal2-file:focus,
.swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-input::placeholder,
.swal2-file::placeholder,
.swal2-textarea::placeholder {
  color: #ccc;
}

.swal2-range {
  margin: 1em 2em 0;
  background: #fff;
}
.swal2-range input {
  width: 80%;
}
.swal2-range output {
  width: 20%;
  color: inherit;
  font-weight: 600;
  text-align: center;
}
.swal2-range input,
.swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em;
}

.swal2-input {
  height: 2.625em;
  padding: 0 0.75em;
}

.swal2-file {
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  background: inherit;
  font-size: 1.125em;
}

.swal2-textarea {
  height: 6.75em;
  padding: 0.75em;
}

.swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: 0.375em 0.625em;
  background: inherit;
  color: inherit;
  font-size: 1.125em;
}

.swal2-radio,
.swal2-checkbox {
  align-items: center;
  justify-content: center;
  background: #fff;
  color: inherit;
}
.swal2-radio label,
.swal2-checkbox label {
  margin: 0 0.6em;
  font-size: 1.125em;
}
.swal2-radio input,
.swal2-checkbox input {
  flex-shrink: 0;
  margin: 0 0.4em;
}

.swal2-input-label {
  display: flex;
  justify-content: center;
  margin: 1em auto 0;
}

.swal2-validation-message {
  align-items: center;
  justify-content: center;
  margin: 1em 0 0;
  padding: 0.625em;
  overflow: hidden;
  background: #f0f0f0;
  color: #666666;
  font-size: 1em;
  font-weight: 300;
}
.swal2-validation-message::before {
  content: "!";
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 0.625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
}

.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 2.5em auto 0.6em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
}
.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em;
}
.swal2-icon.swal2-error {
  border-color: #f27474;
  color: #f27474;
}
.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #f27474;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 1.0625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 1em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-error.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  animation: swal2-animate-error-x-mark 0.5s;
}
.swal2-icon.swal2-warning {
  border-color: #facea8;
  color: #f8bb86;
}
.swal2-icon.swal2-warning.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-warning.swal2-icon-show .swal2-icon-content {
  animation: swal2-animate-i-mark 0.5s;
}
.swal2-icon.swal2-info {
  border-color: #9de0f6;
  color: #3fc3ee;
}
.swal2-icon.swal2-info.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-info.swal2-icon-show .swal2-icon-content {
  animation: swal2-animate-i-mark 0.8s;
}
.swal2-icon.swal2-question {
  border-color: #c9dae1;
  color: #87adbd;
}
.swal2-icon.swal2-question.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-question.swal2-icon-show .swal2-icon-content {
  animation: swal2-animate-question-mark 0.8s;
}
.swal2-icon.swal2-success {
  border-color: #a5dc86;
  color: #a5dc86;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -0.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -0.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
}
.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -0.25em;
  left: -0.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%;
}
.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  z-index: 1;
  top: 0.5em;
  left: 1.625em;
  width: 0.4375em;
  height: 5.625em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success [class^=swal2-success-line] {
  display: block;
  position: absolute;
  z-index: 2;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #a5dc86;
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 2.875em;
  left: 0.8125em;
  width: 1.5625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 2.375em;
  right: 0.5em;
  width: 2.9375em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-animate-success-line-tip 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-animate-success-line-long 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  animation: swal2-rotate-success-circular-line 4.25s ease-in;
}

.swal2-progress-steps {
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 1.25em auto;
  padding: 0;
  background: inherit;
  font-weight: 600;
}
.swal2-progress-steps li {
  display: inline-block;
  position: relative;
}
.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #2778c4;
  color: #fff;
  line-height: 2em;
  text-align: center;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #2778c4;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background: #add8e6;
  color: #fff;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #add8e6;
}
.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  flex-shrink: 0;
  width: 2.5em;
  height: 0.4em;
  margin: 0 -1px;
  background: #2778c4;
}

[class^=swal2] {
  -webkit-tap-highlight-color: transparent;
}

.swal2-show {
  animation: swal2-show 0.3s;
}

.swal2-hide {
  animation: swal2-hide 0.15s forwards;
}

.swal2-noanimation {
  transition: none;
}

.swal2-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.swal2-rtl .swal2-close {
  margin-right: initial;
  margin-left: 0;
}
.swal2-rtl .swal2-timer-progress-bar {
  right: 0;
  left: auto;
}

@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg);
  }
  33% {
    transform: translateY(0) rotateZ(-2deg);
  }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg);
  }
  100% {
    transform: translateY(0) rotateZ(0deg);
  }
}
@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0;
  }
}
@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: 0.5625em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 0.125em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 0.625em;
    left: -0.25em;
    width: 1.625em;
  }
  84% {
    top: 1.0625em;
    left: 0.75em;
    width: 0.5em;
  }
  100% {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
  }
}
@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }
  65% {
    top: 1.25em;
    right: 0.9375em;
    width: 0;
  }
  84% {
    top: 0.9375em;
    right: 0;
    width: 1.125em;
  }
  100% {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
  }
}
@keyframes swal2-show {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.8125em;
    width: 1.5625em;
  }
}
@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}
@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -0.375em;
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg);
  }
  100% {
    transform: rotateY(0);
  }
}
@keyframes swal2-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0;
  }
  25% {
    transform: rotateZ(-25deg);
    opacity: 0.4;
  }
  50% {
    transform: rotateZ(15deg);
    opacity: 0.8;
  }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden;
}
body.swal2-height-auto {
  height: auto !important;
}
body.swal2-no-backdrop .swal2-container {
  background-color: transparent !important;
  pointer-events: none;
}
body.swal2-no-backdrop .swal2-container .swal2-popup {
  pointer-events: all;
}
body.swal2-no-backdrop .swal2-container .swal2-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) > [aria-hidden=true] {
    display: none;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) .swal2-container {
    position: static !important;
  }
}
body.swal2-toast-shown .swal2-container {
  box-sizing: border-box;
  width: 360px;
  max-width: 100%;
  background-color: transparent;
  pointer-events: none;
}
body.swal2-toast-shown .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}
body.swal2-toast-shown .swal2-container.swal2-top-start, body.swal2-toast-shown .swal2-container.swal2-top-left {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}
body.swal2-toast-shown .swal2-container.swal2-center-start, body.swal2-toast-shown .swal2-container.swal2-center-left {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);
}
body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-start, body.swal2-toast-shown .swal2-container.swal2-bottom-left {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}
body.swal2-toast-shown .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}

.progress {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: 0.3rem;
}

.list-group-item.active {
  background-color: var(--bs-list-group-active-color);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  /*border-color: var(--bs-btn-border-color);*/
  color: var(--bs-list-group-action-active-color);
  z-index: 2;
  font-weight: bolder;
}

.field-validation-error {
  color: #dc3545;
  display: block;
  font-size: 85.71428571%;
  margin-top: 0.25rem;
  width: 100%;
}

.k-picker-solid {
  background-color: white;
  border-color: #d0d5dd;
  line-height: 30px;
  border-radius: var(--bs-border-radius);
}
.k-picker-solid:hover {
  background-color: #f9fafb;
}

.k-editor-toolbar .k-picker-solid, .k-scheduler-toolbar .k-picker-solid {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #f5f5f5;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
  line-height: 1.42857143;
}
.k-editor-toolbar .k-picker-solid:hover, .k-scheduler-toolbar .k-picker-solid:hover {
  background-color: #ebebeb;
}

.k-multiselect {
  border-radius: var(--bs-border-radius) !important;
  padding: 5px !important;
}

.k-datetimepicker, .k-datepicker {
  line-height: 1.42;
  border-radius: var(--bs-border-radius);
}
.k-datetimepicker input, .k-datepicker input {
  padding: 0.5625rem 0.875rem !important;
}
.k-datetimepicker .k-icon-button, .k-datepicker .k-icon-button {
  padding: 0 22px;
}

.k-numerictextbox {
  line-height: 1.42;
  border-radius: var(--bs-border-radius);
}
.k-numerictextbox input {
  padding: 0.5625rem 0.875rem !important;
}

.k-daterangepicker .k-dateinput {
  width: 100%;
  line-height: 1.42;
  border-radius: var(--bs-border-radius);
}
.k-daterangepicker .k-dateinput input {
  padding: 0.5625rem 0.875rem !important;
}
.k-daterangepicker .k-dateinput .k-icon-button {
  padding: 0 22px;
}

.thumbnail-3 {
  cursor: pointer;
}
.thumbnail-3 img::before {
  content: "X";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  color: lightgray;
  font-size: 50px;
  line-height: 50px;
  padding-top: 20px;
  text-align: center;
  border-radius: 2px;
  display: block;
  overflow: hidden;
}

.logo-grid {
  max-height: 70px;
  max-width: 70px;
}

.pdf {
  margin: 20px;
  font-family: "Open sans";
  color: #3d3d3d;
  font-size: 20px;
  background-color: white;
}
.pdf h3, .pdf .h3 {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 25px;
  color: blue;
}
.pdf h2, .pdf .h2 {
  font-size: 35px;
  color: blue;
}
.pdf a {
  text-decoration: none;
}
.pdf .firstTitle {
  font-size: 90px;
  color: #0f056b;
  align-content: center;
  text-align: center;
}
.pdf .text {
  text-align: justify;
}
.pdf .bottom {
  text-align: right;
  font-size: 25px;
  font-weight: bold;
}
.pdf .page:not(.page36) {
  margin-top: 10px;
  page-break-after: always;
}
.pdf .page36 {
  margin-top: 20px;
}
.pdf .table {
  background-color: white;
  margin-top: 10px;
}
.pdf thead tr td {
  color: black !important;
  background-color: #f4f5f7 !important;
}
.pdf tr.bold-line td {
  font-weight: bold;
}
.pdf tr.segmentation td {
  background-color: #e6fcff !important;
}
.pdf tr.blueHeader td {
  background-color: #e6fcff !important;
}
.pdf tr.pole td {
  background-color: #ffffd4 !important;
}
.pdf tr.autonome td {
  background-color: #57d9a3 !important;
}
.pdf tr.moinsDeDeuxHeures td {
  background-color: #fff0b3 !important;
}
.pdf tr.deuxQuatreHeures td {
  background-color: #e71837 !important;
}
.pdf tr.quatreSixHeures td {
  background-color: #b9132c !important;
}
.pdf tr.plusDeSixHeures td {
  background-color: #b9132c !important;
}
.pdf tr.alite2424 td {
  background-color: #fce903 !important;
}
.pdf .no-background {
  background-color: white !important;
}
.pdf .stade1 {
  background-color: #57d9a3 !important;
}
.pdf .stade2 {
  background-color: #fff0b3 !important;
}
.pdf .stade3 {
  background-color: #ffc400 !important;
}
.pdf .stade4 {
  background-color: #ff8f73 !important;
}
.pdf .riskFaible {
  background-color: #57d9a3 !important;
}
.pdf .riskModere {
  background-color: #fff0b3 !important;
}
.pdf .riskEleve {
  background-color: #ffc400 !important;
}
.pdf .riskTresEleve {
  background-color: #ff8f73 !important;
}

.smallKendoGrid .k-column-title {
  white-space: normal;
  font-size: 10px;
}
.smallKendoGrid .k-table-md .k-table-td, .smallKendoGrid .k-table-md .k-table-th {
  font-size: 12px;
}

.listViewBo .k-listview-content {
  display: flex;
  flex-wrap: wrap;
}

.wrapKendoTitle .k-column-title {
  white-space: normal;
}

.imageKendo {
  cursor: move;
}

#listView {
  padding: 10px 5px;
  margin-bottom: -1px;
  min-height: 600px;
}

.k-listview:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/*
.list-view-item.active {
    background-color: $primaryYWH;
}*/
.k-window.k-dialog.k-confirm .k-window-titlebar {
  background-color: #fafafa;
}
.k-window.k-dialog.k-confirm .k-window-titlebar .k-window-title {
  display: none;
}
.k-window.k-dialog.k-confirm .k-window-titlebar::before {
  content: "Confirmation";
  color: black;
}

.sidebar-inner .logo {
  background-color: #fff !important;
  filter: drop-shadow(2px 2px 5px #000) !important;
}